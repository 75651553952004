import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

const Projects = () => {

    const { register, formState: { errors } } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[special, setSpecial] = useState([]);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');


    const getProjects = async () => {
        const URL = `${process.env.REACT_APP_API_URI}project`;
        const result = await axios.get(URL);
        let sizeData = result.data.length;
        changeDatatableMessages(sizeData);
        setSearch(result.data);
        setData(result.data);
    }

    const obtenerSpecialization = async () =>{
        
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        const result = await axios.get(URL);
        setSpecial(result.data.specializationTypes);
    }

    useEffect(() => {
        getProjects();
        obtenerSpecialization();
    }, []);

    const changeSpe = (e) =>{
        let id = e.target.value;
        console.log(e.target.value);
        if(id == 'alls'){
            getProjects();
        }
        else{

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
                }
            }
            const order = 'ASC';
            const URL = `${process.env.REACT_APP_API_URI}project/search?category=${id}&order=${order}`;
            const response = axios.get(URL,config);
            response.then((res)=>{
                console.log(res.data);
                let sizeData = res.data.length;
                changeDatatableMessages(sizeData);
                setSearch(res.data);
                setData(res.data);
            });
            response.catch((error)=>{
                console.log("ERROR RESPONSE ", error.response.data);
                console.log('ERROR '+error.message);
                console.log('ERROR REQUEST '+error.request);
            })
            console.log(response.data);
        }
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.project_name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.knowledges_tags.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.publication_date.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
            console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    const deleteItem = (id) => {
        Swal.fire({
            title: 'Borrando proyecto',
            text: "¿Esta seguro de querer borrar este proyecto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro',
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    deletProject(id);
                    Swal.fire({
                        title: 'Borrado exitoso',
                        icon: 'success',
                        allowOutsideClick: false,
                        confirmButtonText: 'OK'
                    })
                        .then((res) => {
                            if (res.isConfirmed) {
                                window.location = '/proyectos';
                            }
                        });
                }
            });
    };

    const deletProject = (id) => {
        console.log(id);
        const URL = `${process.env.REACT_APP_API_URI}project/lay-off/${id}`;
        console.log(URL);
        const response = axios.post(URL);

        response.then((res) => {
            console.log(res.data);
        })
        response.catch((error) => {
            console.log("ERROR RESPONSE ", error.response.data);
            console.log("ERROR ", error.message);
            console.log("ERROR REQUEST ", error.request);
        })
    }

    const stateProject = (ste) =>{
        switch (ste) {
            case 1:
                return 'Publicado'
                break;
            case 2:
                return 'Por publicar'
                break;
            case 3:
                return 'Bloqueado'
                break;
            default:
                break;
        }
    }

    const columns = [
        {
            name: '#',
            width: "40px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Projecto',
            width: "350px",
            cell: (row) => <div className="group">
                <span>{`${(row.project_name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Conocimientos',
            width: "250px",
            cell: (row) => <div className="group">
                <span>{`${(row.knowledges_tags)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Publicado',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.publication_date)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Estado',
            width: "150px",
            cell: (row) => <div className="group">
                <span>
                {/* {stateProject(row.publicationStatus)} */}
                {stateProject(row.allowPublish)}
                    {/* {`${(row.publicationStatus)}`} */}
                </span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Ver',
            width: '120px',
            cell: (row) => <div>
                <Link to={'/detalle-de-proyecto/' + row.id}>
                    <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                        <FontAwesomeIcon icon="fa-solid fa-eye" />
                    </button>
                </Link>
            </div>
        },
        {
            name: 'Borrar',
            cell: (row) => <div>
                <button className={`${row.publicationStatus == 3 ? "bg-gray-800" : "bg-error hover:bg-red-700"}`+' px-4 py-3 rounded-md text-base text-white transition-colors'}
                    onClick={() => deleteItem(row.id)} disabled={row.publicationStatus == 3 ? true : false}
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center items-center">
                <h1 className="text-3xl sm:text-4xl font-bold mx-4">
                    Proyectos
                </h1>
                {/* <Link to={'/ingresar-usuario'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </button>
                    </Link> */}
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="type" className="form-label">Especialización</label>
                                <select
                                    id="type"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("type", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione una opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeSpe}
                                >
                                    <option selected value="alls">Todos</option>
                                    {special.map((spe, i) => (
                                        <option value={spe.id} key={i}>{spe.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default Projects;