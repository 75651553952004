import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

import exportFromJSON from 'export-from-json';

const ReportsFreelancersSpecialization = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[nameFile, setNameFile] = useState('todos');

    const[special, setSpecial] = useState([]);

    const[knowledge, setknowledge] = useState([]);

    const[optionKnowledges, setOptionKnowledges] = useState(true);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');

    useEffect(() => {
        getFreelancersAlls();
        getSpecialization();
    }, []);

    const getKnowledges = async (id) =>{
        console.log(id);
        let defaultValue = {
            knowledges: 1
        }
        reset(defaultValue);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
            }
        }
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${id}`;
        const response = axios.get(URL,config);
        // console.log(response.data.data);
        response.then((res)=>{
            console.log(res.data.data);
            setknowledge(res.data.data);
        });
        response.catch((error)=>{
            console.log("ERROR RESPONSE ", error.response.data);
            console.log('ERROR '+error.message);
            console.log('ERROR REQUEST '+error.request);
        })
        // console.log(response.data);
        setOptionKnowledges(false);
    }

    const getSpecialization = async () =>{
        
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        console.log(URL);
        const result = await axios.get(URL);
        console.log(result.data.specializationTypes);
        setSpecial(result.data.specializationTypes);
    }

    const getFreelancersAlls = async () => {
        const URL = `${process.env.REACT_APP_API_URI}report/freelancers-filter`;
        const result = await axios.get(URL);
        console.log(result.data.records);
        let sizeData = result.data.records.length;
        changeDatatableMessages(sizeData);
        setDataArray(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    const setDataArray = (arr) =>{
        let ii = 1;
        for (var i = 0; i < arr.length; i++) {
            arr[i].id = ii;
            if(arr[i].phone == null){
                arr[i].phone = 'Sin definir';
            }
            if(arr[i].gender == null){
                arr[i].gender = 'Sin definir';
            }
            else{
                console.log(arr[i].gender)
                arr[i].gender = stateNullGender(arr[i].gender);
            }
            ii++;
        }
        return arr
    }

    const changeknowledges = (e) =>{
    let id = e.target.value;
    let contentOption = e.target[e.target.selectedIndex].text;
    setNameFile(contentOption);
    // console.log(e.target[e.target.selectedIndex].text);
    console.log(e.target.value);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
            }
        }
        const order = 'ASC';
        const URL = `${process.env.REACT_APP_API_URI}report/freelancers-filter?knowledge=${id}`;
        const response = axios.get(URL,config);
        response.then((res)=>{
            console.log(res.data.records);
            let sizeData = res.data.records.length;
            changeDatatableMessages(sizeData);
            setDataArray(res.data.records);
            setSearch(res.data.records);
            setData(res.data.records);
        });
        response.catch((error)=>{
            console.log("ERROR RESPONSE ", error.response.data);
            console.log('ERROR '+error.message);
            console.log('ERROR REQUEST '+error.request);
        })
        console.log(response.data);
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.names.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.lastnames.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.email.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.city.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.state.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.phone.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
            console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }
    var date = new Date();
    const generateFileExcel = () =>{
        
        // console.log(date);
        if  (Array.isArray(search)){
            let info = [];

            for (let i = 0; i < search.length; i++) {
                info.push({
                    "No": search[i].id,
                    "Nombre": search[i].names,
                    "Apellido": search[i].lastnames,
                    "Email": search[i].email,
                    "Teléfono": search[i].phone,
                    "Ciudad": search[i].city,
                    "Estado": search[i].state,
                    "Genero": search[i].gender
                });
            }

            console.log(info);

            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-freelancers-${nameFile}`;
            const exportType = exportFromJSON.types.xls;

            exportFromJSON({data: info, fileName: fileName, exportType: exportType});
        }
        else{
            console.log("No es array");
        }
    };

    const stateNullGender = (ste) =>{
        switch (ste) {
            case null:
                return 'Sin definir';
                break;
            case 1:
                return 'Mujer';
                break;
            case 2:
                return 'Hombre';
                break;
            case 3:
                return 'Otro';
                break;
            default:
                return 'Sin definir'
                break;
        }
    }

    const changeSpe = (e) =>{
        let id = e.target.value;
        console.log(e.target.value);
        setknowledge([]);
        setOptionKnowledges(true);
        if(id == 'alls'){
            getFreelancersAlls();
            setNameFile('todos');
        }
        else{
            getKnowledges(id);
        }
    }

    const columns = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.names)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Apellido',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.lastnames)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Email',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.email)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Teléfono',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.phone)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Ciudad',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.city)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Estado',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.state)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Genero',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{row.gender}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center md:justify-start items-center">
                <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                    <Link to={'/reportes/general'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        </button>
                    </Link>
                    <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-3xl font-bold text-dark">Reportes freelancers por conocimiento</h3>
                    </div>
                </div>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap items-end'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="specialization" className="form-label">Especialización</label>
                                <select
                                    id="specialization"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("specialization", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione una opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeSpe}
                                >
                                    <option selected value="alls">Todos</option>
                                    {special.map((spe, i) => (
                                        <option value={spe.id} key={i}>{spe.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="knowledges" className="form-label">Conocimientos</label>
                                <select
                                    id="knowledges"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("knowledges", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione una opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeknowledges}
                                >
                                    {optionKnowledges
                                        ? <option selected hidden>Selecciona una especialidad</option>
                                        : <option selected hidden>Selecciona un conocimiento</option>
                                    }
                                    {knowledge.map((knw, i) => (
                                        <option value={knw.id} key={i}>{knw.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <button onClick={generateFileExcel} className="flex items-center text-white bg-green-800 border-0 h-12 py-2 my-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                <FontAwesomeIcon icon="fa-solid fa-file-export" className='mr-2' />
                                <span className="text-xs text-white">Exportar Excel</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default ReportsFreelancersSpecialization;