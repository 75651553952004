import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

const PlanAssignment = () => {

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const [countrys, setCountrys] = useState([]);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');

    const getCompany = async () => {
        const URL = `${process.env.REACT_APP_API_URI}company`;
        const result = await axios.get(URL);
        
        let sizeData = result.data.length;
        
        changeDatatableMessages(sizeData);

        
        //console.log('data company:', result.data);
        
        const dataCompanyFilterProfile = result.data.filter(profile => profile.complete_profile_status == 3);
        
        //console.log('dataCompanyFilterProfile', dataCompanyFilterProfile);
        setSearch(dataCompanyFilterProfile);
        setData(dataCompanyFilterProfile);
        
    }
    
    useEffect(() => {
        getCompany();
    }, []);


    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        // console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        // console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.company_name.toString().toLowerCase().includes(termSearch.toString().toLowerCase()) ){
                return item;
            }
            // console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    const deletState = (id) =>{
        console.log(id);
        const action = 2;
        const URL = `${process.env.REACT_APP_API_URI}state/${id}`;
        console.log(URL);
        const response = axios.delete(URL);
        
        response.then((res)=>{
            console.log(res.data);
        })
        response.catch((error)=>{
            console.log("ERROR RESPONSE ", error.response.data);
            console.log("ERROR ", error.message);
            console.log("ERROR REQUEST ", error.request);
        })
    } 

    const deleteItem = (id) => {
        Swal.fire({
            title: 'Borrando Conocimiento...',
            text: "¿Esta seguro de querer borrar este conocimiento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                deletState(id)
                Swal.fire({
                    title: 'Borrado exitoso',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                })
                .then((res)=>{
                    if(res.isConfirmed){
                        window.location='/estados';
                    }
                });
            }
        });
    };

    const columns = [
        {
            name: '#',
            width: "100px",
            cell: (row) => <b>{row.user_id}</b>
        },
        {
            name: 'Empresa',
            width: "300px",
            cell: (row) => <div className="group">
                <span>{`${(row.company_name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Plan',
            width: "300px",
            cell: (row) => <div className="group">
                <span>
                {row.plan_id == null ? (
                    <div>Sin Plan</div>
                ) : (
                    <div>{`${(row.plan_name)}`}</div>
                )}
                </span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.lastname}</span> */}
                </div>
            </div>
        },
        {
            name: 'Asignar plan',
            width: '200px',
            cell: (row) => <div>
                <Link to={'/asignacion-planes/' + row.user_id}>
                    <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                        <FontAwesomeIcon icon="fa-rocket" />
                    </button>
                </Link>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center items-center">
                <h1 className="text-3xl sm:text-4xl font-bold mx-4">
                    Asignación de planes a empresas
                </h1>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default PlanAssignment;