import { useEffect, useState } from "react";
import OtherStudies from "./content-tabs/OtherStudies";
import Projects from "./content-tabs/Projects";
import Specialization from "./content-tabs/Specialization";
import Studies from "./content-tabs/Studies";
import WorkExperience from "./content-tabs/WorkExperience";
import Portfolio from "./content-tabs/Portfolio";
import axios from "axios";


const TabsInfo = ({idFreelancer}) => {

   const [showTabs, setShowTabs] = useState(1);

   const [activeTabs, setActiveTabs] = useState(1);

   const HandlerButtonsTabs = (ID) => {
      setShowTabs(Number(ID));
      setActiveTabs(Number(ID));
   };

   return (
      <>
         {/* Buttons */}
         <div className="mb-4 mt-5 w-full">
            <ul className="flex flex-wrap justify-center -mb-px text-xs font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
               <div className="flex align-center justify-center">
                  <div className="inline-grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1">

                     {/* {(EspecialidadesTotal != 0) && ( */}
                        <li className={`${(activeTabs == 1) ? 'border-b-orange-500 text-orange-500' : 'text-black hover:text-black hover:border-orange-500'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("1")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           {/* <button className={`${(activeTabs == 1) ? 'border-b-orange-500 text-orange-500' :'text-black' } h-18 inline-block p-4 rounded-t-lg border-transparent hover:text-gray-600 hover:border-orange-500 dark:hover:text-gray-300 dark:border-transparent  dark:text-gray-400 border-gray-100 dark:border-gray-700`} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false" onClick={() => HandlerButtonsTabs("1")} >Especialización profesional</button> */}
                           <h6>Especializaciones</h6>
                        </li>
                     {/* )} */}

                     {/* {(WorkExperienceState != 0) && ( */}
                        <li className={`${(activeTabs == 3) ? 'border-b-orange-500 text-orange-500' : 'text-black hover:text-black hover:border-orange-500'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("3")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Experiencia laboral</h6>
                        </li>
                     {/* )} */}

                     {/* {(PortafolioFreelancer != 0) && ( */}
                        <li className={`${(activeTabs == 4) ? 'border-b-orange-500 text-orange-500' : 'text-black hover:text-black hover:border-orange-500'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("4")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Portafolio</h6>
                        </li>
                     {/* )} */}

                     {/* {(StudiesSate != 0) && ( */}
                        <li className={`${(activeTabs == 5) ? 'border-b-orange-500 text-orange-500' : 'text-black hover:text-black hover:border-orange-500'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("5")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Formación académica</h6>
                        </li>
                     {/* )} */}

                     {/* {(OtherStudiesState != 0) && ( */}

                        <li className={`${(activeTabs == 6) ? 'border-b-orange-500 text-orange-500' : 'text-black hover:text-black hover:border-orange-500'} border-b-2 h-14 text-xs inline-block p-4 rounded-t-lg border-transparent cursor-pointer`} onClick={() => HandlerButtonsTabs("6")} id="btnEspecializacionProfesional" data-tabs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false">
                           <h6>Otros estudios</h6>
                        </li>
                     {/* )} */}

                  </div>
               </div>
            </ul>
         </div>

         {/* Tabs */}

         {/* Container */}
         <div className="flex flex-wrap w-full">
            <div id="myTabContent" className="mb-5 w-full">

               {/* {(EspecialidadesTotal != 0) && ( */}
                  {(showTabs === 1) && 
                  <Specialization 
                     // animation={activeTab} 
                     idFreelancer={idFreelancer} 
                     // state={state} 
                  />}
               {/* )} */}

               {/* {(WorkExperienceState != 0) && ( */}
                  {(showTabs === 3) && 
                  <WorkExperience 
                     // animation={activeTab} 
                     idFreelancer={idFreelancer} 
                     // state={state} 
                  />}
               {/* )} */}

               {/* {(PortafolioFreelancer != 0) && ( */}
                  {(showTabs === 4) && 
                  <Portfolio 
                     // animation={activeTab} 
                     idFreelancer={idFreelancer} 
                     // state={state} 
                  />}
               {/* )} */}

               {/* {(StudiesSate != 0) && ( */}
                  {(showTabs === 5) && 
                  <Studies 
                     // animation={activeTab} 
                     idFreelancer={idFreelancer} 
                     // state={state} 
                  />}
               {/* )} */}

               {/* {(OtherStudiesState != 0) && ( */}
                  {(showTabs === 6) && 
                  <OtherStudies 
                     // animation={activeTab} 
                     idFreelancer={idFreelancer} 
                     // state={state} 
                  />}
               {/* )} */}

            </div>
         </div>

      </>
   )
};

export default TabsInfo;