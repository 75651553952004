import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { faPenToSquare, faFolderPlus, faFilePen, faMinus } from "@fortawesome/free-solid-svg-icons";

const initialStateStudies = {
   code: "",
   country_name: "",
   emision_year: "",
   institution: "",
   state_name: "",
   study_degree: "",
   external_origin: "",
   study_level: "",
   id: ""
}

const OtherStudies = ({ idFreelancer }) => {

   const [Studies, setStudies] = useState([initialStateStudies]);


   const idFreelancerI = idFreelancer;

   const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

   function getDate(date) {

      const formatDate = date;
      var partesFecha = formatDate.split("-");
      // console.log(partesFecha);

      const monthR = (Number(partesFecha[1]) - 1);
      const dateWorkExperiences = monthNames[monthR] + ' ' + partesFecha[0];

      return dateWorkExperiences;

   };

   async function fetchData(id) {

      let urlCountry = `${process.env.REACT_APP_API_URI}freelancer/get/studies/${id}?area=Profesional`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      };

      try {

         setStudies([initialStateStudies])

         const resp = await axios.get(urlCountry, config);

         console.log("Otros estudios ==>", resp.data.data);
         setStudies(resp.data.data);


      } catch (err) {
         // Handle Error Here
         console.error(err);

      };
   };

   useEffect(() => {

      fetchData(idFreelancerI);

   }, []);
   return (
      <>
         <div className={`p-4 text-white`} id="contenedorFormacionAcademica" role="tabpanel" aria-labelledby="contacts-tab">

            <div className="flex flex-wrap">
               {(Studies[0].country_name != ""
                  ?
                  Studies.map((studied, key) => {
                     return (
                        <>
                           <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                              <div className="bg-Light-purple p-6 rounded-md shadow-md h-full">
                                 <div className="flex flex-wrap justify-between items-center mb-4 sm:mb-0">
                                    <div className="flex flex-wrap justify-center md:justify-start items-center w-full md:w-2/3 order-2 md:order-1 mt-5 md:mt-0">
                                       <div className="text-lg font-semibold text-white w-full pr-4">
                                          {studied.institution}
                                       </div>
                                       <div className="text-sm text-white w-full pr-4">
                                          {studied.institution}
                                       </div>
                                       <div className="text-sm text-white w-full pr-4">
                                          {studied.study_degree}
                                       </div>
                                       <div className="text-xs text-orange-500 w-full pr-4 text-start my-2">
                                          Fecha de emisión: {studied.emision_year}
                                          <br />
                                          {studied.state_name}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </>
                     )
                  })
                  : <p className="text-center w-full">No se encontraron otros estudios registrados</p>
               )
               }
            </div>

         </div>
      </>
   )
}

export default OtherStudies;

