
import React from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AddSpecialties(){

   const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
   const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

   const [isActive, setIsActive] = useState(false);

   const activeCheckbox = () => {
       setIsActive(!isActive);
   };

   const sendData = async (data) => {
          
      const newData = {
        name: data.Name,
        icon: data.icon[0]
      }

       const urlRegisterSpecialty = `${process.env.REACT_APP_API_URI}specialization-type`;

       let config = {
          headers: {
            //   'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json',
              }
          };

      try {
         const resp = await axios.post(urlRegisterSpecialty, newData, config);
         console.log('resp:' ,resp)
        //  Swal.fire(resp.data.message, '', 'success')
         
      } catch (err) {
         // Handle Error Here
         console.error(err);
        //  Swal.fire('as', '', 'error')
     }

   };


  return (
   <div id="Forms">
            <div className="pt-5 flex items-center justify-center mb-6">
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Agregar Especialidad</h3>
                </div>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="basic-form" onSubmit={ handleSubmit(sendData) }>
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">
                                Los campos con&nbsp;<span className="text-error font-bold">*</span>&nbsp;son requeridos.
                            </h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="Name" className="form-label">Nombre&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="Name" className="form-input" autoComplete="nope"
                                        {...register("Name", {
                                            required: "Nombre requerido"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="Name"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="icon" className="form-label">Icono&nbsp;<span className="text-error">*</span></label>
                                    <input type="file" id="icon" className="form-label pt-1" autoComplete="nope"
                                        {...register("icon", {
                                            required: "Icono requerido"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="icon"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Ingresar</button>
                        </div>
                    </form>          
                </div>
            </section>
        </div>
  )
}

export default AddSpecialties