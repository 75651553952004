import { useParams, Link } from 'react-router-dom';
import axios, { HttpStatusCode } from "axios";
import { useEffect, useState } from 'react';

import Swal from "sweetalert2";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlanAssignmentCompany = () => {
    const { id } = useParams();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();


    const [plans, setPlans] = useState([]);

    const getPlan = async () => {
        const urlCountry = `${process.env.REACT_APP_API_URI}admin-plans/list`;

        let config = {
           headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               }
           };
       try {

          const resp = await axios.get(urlCountry, config);
          console.log(resp.data.data, 'planes');
          setPlans(resp.data.data);

       } catch (err) {
          // Handle Error Here
          console.error(err);
      }


    }


    const getCompany = async () => {

        const URL = `${process.env.REACT_APP_API_URI}company/profile/${id}`;
        const response = await axios.get(URL);
        console.log(response.data);

        let defaultValues = {
            id: response.data.user_id,
            name: response.data.company_name,
            planes_id:response.data.planes_id
            // country_id: response.data.country.id
        };
        // console.log(defaultValues, 'defaultValues');
        reset(defaultValues);
    }

    useEffect(() => {
        getPlan();
        getCompany();
    }, []);

    const sendData = async (data) => {
        // console.log(data);

        const newData = {company_id: data.id};
    
           const urlPlanID = `${process.env.REACT_APP_API_URI}company/plan/free/${data.planes_id}`;
    
           console.log("Data:",newData);
           let config = {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Accept': 'application/json',
                  }
              };
    
          try {
             const resp = await axios.post(urlPlanID, newData, config);
             console.log('response plan:',resp)
             Swal.fire(resp.data.Message, "", "success");

          } catch (err) {
             console.error(err.response.data[1]);
             Swal.fire(err.response.data[1], "", "error");
         }
    }

    return (
        <>
            <div id="PlanAssignmentCompany">
                <div className="text-center pt-5">
                    <div id="Forms">
                        <div className="pt-5 flex items-center justify-center md:justify-start mb-6">
                            <Link to={'/asignacion-planes'} className="">
                                <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                </button>
                            </Link>
                            <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-3xl font-bold text-dark">Asignar plan a empresa</h3>
                            </div>
                        </div>
                        <section>
                            <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                                <form id="basic-form" onSubmit={handleSubmit(sendData)}>
                                    <div className="w-full mb-4">
                                        <h2 className="text-base font-normal">
                                            Los campos con (&nbsp;<span className="text-error font-bold">*</span>&nbsp;) son requeridos.
                                        </h2>
                                    </div>
                                    <div className="flex flex-wrap -m-2">
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="names" className="form-label">Nombre&nbsp;<span className="text-error"></span></label>
                                                <input type="text"
                                                    className="form-input"
                                                    autoComplete="nope"
                                                    disabled
                                                    {...register("name", {
                                                        required: "El nombre es requerido"
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="name"
                                                    render={({ message }) => <span className="error-message">{message}</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="planes_id" className="form-label">Planes&nbsp;<span className="text-error">*</span></label>
                                                <select
                                                    id="planes_id"
                                                    name='planes_id'
                                                    className="form-select"
                                                    defaultValue={"null"}
                                                    {...register("planes_id", {
                                                        required: "Seleccione una opcion",
                                                        validate: (value) => {
                                                            if (value === "null") {
                                                                return "Seleccione una opcion";
                                                            }
                                                        },
                                                    })}
                                                >
                                                    <option hidden disabled selected value="null">Seleccione un plan</option>
                                                    {plans.filter(data => data.billingPeriod == 0).map((plan, i) => (
                                                        <option value={plan.id} key={i}>{plan.name}</option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="country_id"
                                                    render={({ message }) => <span className="error-message">{message}</span>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full pt-8 flex justify-end">
                                        <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Asignar</button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlanAssignmentCompany;