import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { paginationProps } from "../../utils/utils";
import { getStates } from "../../services/api/state";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTableExtensions from "react-data-table-component-extensions";
import { getCities, getCityState, deleteCity} from "../../services/api/cities";

export default function Cities() {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [estados, setEstados] = useState(null);
  const { register, handleSubmit, formState: { errors }, getValues, } = useForm();

  const columns = [
    {
      name: "#",
      width: "65px",
      cell: (row) => <b>{row.id}</b>,
    },
    {
      name: "Nombre",
      width: "250px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.name.slice(0, 20)}`}
            {row.name.length > 20 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.name}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Estado",
      width: "250px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.state.name.slice(0, 20)}`}
            {row.state.name.length > 20 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.state.name}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Editar",
      cell: (row) => (
        <div>
          <Link
            to={`/city/${row.id}`}
            className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue"
          >
            <FontAwesomeIcon icon="fa-solid fa-pen" />
          </Link>
        </div>
      ),
    },
    {
      name: "Borrar",
      cell: (row) => (
        <div>
          <button
            className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700"
            onClick={() => deleteItem(row.name, row.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCities().then((res) => {
      setData(res);
    });

    getStates().then((datos) => {
      setEstados(datos);
    });
  }, [update]);

  const sendData = () => {
    const data = getValues();
    getCityState(data.state_id).then((responde) => {
      setData(responde.data);
    });
  };

  const deleteItem = (name, id) => {
    Swal.fire({
      title: `Borrando ${name}...`,
      text: `¿Está seguro de querer borrar este país?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCity(id).then((response) => {
          setUpdate(!update);
          if (response.status === 200) {
            setUpdate(!update);
            Swal.fire({
              title: "Borrado exitoso",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "OK",
            });
          }
          else{
            Swal.fire({
              title: "Error al borrar",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "OK",
            });
          }
        });
      }
    });
  };

  return (
    <>
      <div id="AttentionSchedules">
        <div className="text-center pt-5 flex mb-1 justify-center">
          <h1 className="text-4xl font-bold mr-5">Ciudades</h1>
        </div>
        <section className="table-results">
          <div className="xl:w-1/2 w-full">
            <div className="relative mb-2">
              <form id="basic-form" onSubmit={handleSubmit(sendData)}>
                <label htmlFor="state_id" className="form-label">
                  Estados
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <select
                    id="state_id"
                    className="form-select"
                    {...register("state_id", {
                      required: "Seleccione una opcion",
                      validate: (value) => {
                        if (value === "null") {
                          return "Seleccione una opcion";
                        }
                      },
                    })}
                  >
                    {estados?.map((estado) => (
                      <option key={estado.id} value={estado.id}>
                        {estado.name}
                      </option>
                    ))}
                  </select>
                  <div className="">
                    <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">
                      Filtrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="table-auto">
            <DataTableExtensions
              columns={columns}
              data={data}
              filterPlaceholder="Buscar..."
              print={false}
              export={false}
            >
              <DataTable
                pagination
                columns={columns}
                data={data}
                dense
                striped
                responsive
                paginationComponentOptions={paginationProps}
              />
            </DataTableExtensions>
          </div>
        </section>
      </div>
    </>
  );
}
