import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { paginationProps } from '../../utils/utils';

const Tables = () => {
    const [data, setData] = useState([
        {
            name: "Victor José",
            lastname: "López Rivera",
            city: "Ilopango",
            id: 1
        },
        {
            name: "Edwin Alberto",
            lastname: "Orellana Rosales",
            city: "Ilopango",
            id: 2
        },
        {
            name: "Emerson Adonay",
            lastname: "López Maldonado",
            city: "Ilopango",
            id: 3
        }
    ]);

    const deleteItem = () => {
        Swal.fire({
            title: 'Borrando ITEM...',
            text: "¿Esta seguro de querer borrar este item?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Borrado exitoso',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                });
            }
        });
    };

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Nombre',
            width: "250px",
            cell: (row) => <div className="group">
                <span>{ `${(row.name).slice(0, 20)}` }{ row.name.length > 20 ? "..." : "" }</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span>
                </div>
            </div>
        },
        {
            name: 'Apellido',
            width: "250px",
            cell: (row) => <div className="group">
                <span>{ `${(row.lastname).slice(0, 20)}` }{ row.lastname.length > 20 ? "..." : "" }</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.lastname }</span>
                </div>
            </div>
        },
        {
            name: 'Municipio',
            width: "200px",
            cell: (row) => <span>{ row.city }</span>
        },
        {
            name: 'Ver / Editar',
            cell: (row) => <div>
                <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                    <FontAwesomeIcon icon="fa-solid fa-pen" />
                </button>
            </div>
        },
        {
            name: 'Borrar',
            cell: (row) => <div>
                <button className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700" 
                    onClick={ deleteItem } 
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                    Tabla
                </h1>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <DataTableExtensions
                        columns={ columns }
                        data={ data }
                        filterPlaceholder="Buscar..."
                        print={ false }
                        export={ false }
                    >
                        <DataTable
                            pagination
                            columns={ columns }
                            data={ data }
                            dense
                            striped
                            responsive
                            paginationComponentOptions={ paginationProps }
                        />
                    </DataTableExtensions>
                </div>
            </section>
        </div>
    );
};

export default Tables;
