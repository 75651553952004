import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { addCountry, updateCountry } from '../../services/api/country';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import BackIcon from "../BackIcon";

export default function FormCountry({ country }) {

  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();

  useEffect(() => {
    if (country) {
      const initialCountry = {
        name: country.name,
        code: country.code,
      };
      reset(initialCountry);
    }
  }, [country, reset]);

  const sendData = () => {
    const data = getValues();
    if (country) {
      actualizarCountry(country.id, data);
    }
    else {
      createCountry(data);
    }
  };

  const createCountry = (data) => {
    addCountry(data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "País agregado exitosamente",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
          navigate(-1);
        } else {
          Swal.fire({
            title: "Error al agregar el País",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${error.response.data.message}`,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      });
  };

  const actualizarCountry = (id, data) => {
    updateCountry(id, data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Actualizado exitoso",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
          navigate(-1);
        } else {
          Swal.fire({
            title: "Error al actualizar",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${error.response.data[1]}`,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div id="Forms">
      <div className="pt-5 mb-6">
        <div>
          <BackIcon />
        </div>
        <div className="sm:mt-0 ml-4">
          <h3 className="text-center text-3xl font-bold text-dark">País</h3>
        </div>
      </div>
      <section>
        <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
          <form id="basic-form" onSubmit={handleSubmit(sendData)}>
            <div className="w-full mb-4">
              <h2 className="text-base font-normal">
                Los campos con&nbsp;<span className="text-error font-bold">(*)</span>&nbsp;son requeridos.
              </h2>
            </div>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="name" className="form-label">Nombre&nbsp;<span className="text-error">*</span></label>
                  <input type="text" id="name" className="form-input" autoComplete="nope"
                    {...register("name", {
                      required: "El nombre es requeridos"
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <span className="error-message">{message}</span>}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="code" className="form-label">Codigo&nbsp;<span className="text-error">*</span></label>
                  <input type="text" id="code" className="form-input" autoComplete="nope"
                    {...register("code", {
                      required: "El codigo es requeridos"
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="code"
                    render={({ message }) => <span className="error-message">{message}</span>}
                  />
                </div>
              </div>
            </div>
            <div className="w-full pt-8 flex justify-end">
              <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Guardar</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};


