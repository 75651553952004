import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

import exportFromJSON from 'export-from-json';

const ReportsCompanysActEco = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[nameFile, setNameFile] = useState('todos');

    const[nameFile2, setNameFile2] = useState('');

    const[activity, setActivity] = useState([]);

    const[idCountry, setIdCountry] = useState('');

    const [countrys, setCountrys] = useState([]);
    
    const[optionActivity, setOptionActivity] = useState(true);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');
    
    useEffect(() => {
        getCompanysAlls();
        getCountrys();
    }, []);

    const getCompanysAlls = async () => {
        const URL = `${process.env.REACT_APP_API_URI}report/companies-filter`;
        const result = await axios.get(URL);
        console.log(result.data.records);
        let sizeData = result.data.records.length;
        changeDatatableMessages(sizeData);
        setDataArray(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    const getCountrys = async () => {
        const URL = `${process.env.REACT_APP_API_URI}country`;
        console.log(URL);
        const response = await axios.get(URL);
        console.log(response.data);
        setCountrys(response.data);
    }

    const getActivityEconomic = async () => {
        const URL = `${process.env.REACT_APP_API_URI}economic-activity`;
        console.log(URL);
        const response = await axios.get(URL);
        console.log(response.data);
        setActivity(response.data);
        setOptionActivity(false);
    }
    
    const getCompanysCountryAct = async (id) =>{
        console.log(id);
        console.log(idCountry);

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
            }
        }
        const URL = `${process.env.REACT_APP_API_URI}report/companies-filter?country=${idCountry}&activity=${id}`;
        const response = axios.get(URL,config);
        // console.log(response.data);
        response.then((res)=>{
            console.log(res.data.records);
            let sizeData = res.data.records.length;
            changeDatatableMessages(sizeData);
            setSearch(res.data.records);
        });
        response.catch((error)=>{
            console.log("ERROR RESPONSE ", error.response.data);
            console.log('ERROR '+error.message);
            console.log('ERROR REQUEST '+error.request);
        })
        // console.log(response.data);
        setOptionActivity(false);
    }

    const setDataArray = (arr) =>{
        let ii = 1;
        for (var i = 0; i < arr.length; i++) {
            arr[i].id = ii;
            if(arr[i].direction == null){
                arr[i].direction = 'Sin definir';
            }
            if(arr[i].secondary_phone == null){
                arr[i].secondary_phone = 'Sin definir';
            }
            ii++;
        }
        return arr
    }

    const changeCountry = (e) => {
        let id = e.target.value;
        console.log(e.target.value);
        setIdCountry(id);
        setActivity([]);
        setOptionActivity(true);
        if(id == 'alls'){
            getCompanysAlls();
        }
        else{
            let contentOption = '-'+e.target[e.target.selectedIndex].text;
            setNameFile2(contentOption);
            let defaultValue = {
                activity_economic: 1
            }
            reset(defaultValue);
            getActivityEconomic();
        }
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.company.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.activity.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.email.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.country.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.secondary_phone.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.phone.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
            console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    var date = new Date();
    const generateFileExcel = () =>{
        
        // console.log(date);
        if  (Array.isArray(search)){
            let info = [];

            for (let i = 0; i < search.length; i++) {
                info.push({
                    "No": search[i].id,
                    "Nombre": search[i].company,
                    "Actividad económica": search[i].activity,
                    "Email": search[i].email,
                    "Teléfono": search[i].phone,
                    "Páis": search[i].country,
                    "Dirección": search[i].direction,
                    "Teléfono secundario": search[i].secondary_phone
                });
            }

            console.log(info);

            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-empresas-${nameFile}${nameFile2}`;
            const exportType = exportFromJSON.types.xls;

            exportFromJSON({data: info, fileName: fileName, exportType: exportType});
        }
        else{
            console.log("No es array");
        }
    };

    const changeActivity = (e) =>{
        let id = e.target.value;
        console.log(e.target.value);
        if(id == 'alls'){
            getCompanysAlls();
            setOptionActivity(true);
            setNameFile('todos');
            // setknowledge([]);
        }
        else{
            let contentOption = e.target[e.target.selectedIndex].text;
            setNameFile(contentOption);
            getCompanysCountryAct(id);
        }
    }

    const columns = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.company)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Actividad',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.activity)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Email',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.email)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Teléfono',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.phone)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Páis',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.country)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Dirección',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.direction)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Teléfono 2',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.secondary_phone)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center md:justify-start items-center">
                <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                    <Link to={'/reportes/general'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        </button>
                    </Link>
                    <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-3xl font-bold text-dark">Reportes empresas por actividad económica</h3>
                    </div>
                </div>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap items-end'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="country_id" className="form-label">País</label>
                                <select
                                    id="country_id"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("country_id", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione un opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeCountry}
                                >
                                    <option selected value="alls">Todos</option>
                                    {countrys.map((coun, i) => (
                                        <option value={coun.id} key={i}>{coun.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="country_id"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="activity_economic" className="form-label">Actividad Económica</label>
                                <select
                                    id="activity_economic"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("activity_economic", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione una opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeActivity}
                                >
                                    {optionActivity
                                        ? <option selected hidden>Selecciona un páis</option>
                                        : <option selected hidden>Selecciona una actividad</option>
                                    }
                                    {activity.map((knw, i) => (
                                        <option value={knw.id} key={i}>{knw.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <button onClick={generateFileExcel} className="flex items-center text-white bg-green-800 border-0 h-12 py-2 my-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                <FontAwesomeIcon icon="fa-solid fa-file-export" className='mr-2' />
                                <span className="text-xs text-white">Exportar Excel</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default ReportsCompanysActEco;