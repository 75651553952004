import { Link } from "react-router-dom";
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState } from "react";
// import Cookies from "universal-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faDownload, faEllipsis, faEllipsisH, faEllipsisVertical, faFilePen, faFolderPlus, faLink, faMinus, faPenClip, faPenToSquare, faX } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

const initialStateExperienceInformal = {
   actual_work: 0,
   cargo: null,
   created_at: '',
   descripcion_puesto: '',
   experience_file_path: '',
   experience_titular: '',
   fecha_fin: '',
   fecha_inicio: '',
   freelance_experience_description: '',
   id: 0,
   nombre_empresa: '',
   updated_at: '',
   url_repo: '',
   user_id: 0,
   work_experience_type: 0
};

const Portfolio = ({idFreelancer}) => {


   const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

   function getDate(date) {

      const formatDate = date;
      var partesFecha = formatDate.split("-");
      // console.log(partesFecha);

      const monthR = (Number(partesFecha[1]) - 1);
      const dateWorkExperiences = monthNames[monthR] + ' ' + partesFecha[0];

      return dateWorkExperiences;

   };
   const [IsLoading, setIsLoading] = useState(true);

   // const MySwal = withReactContent(Swal);

   const [message, setMessage] = useState(false);

   const [showOptions, setShowOptions] = useState(false);


   const [experinceInformal, setExperinceInformal] = useState([initialStateExperienceInformal]);


   const idFreelancerI = idFreelancer;
   
   useEffect(() => {
      
      async function getInfoUserSession(){
      
         let urlExperienceInformal = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFreelancerI}`;
   
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
            }
         };
   
         try {
   
            setExperinceInformal([initialStateExperienceInformal])
            const resp = await axios.get(urlExperienceInformal, config);
            // console.log("resp ==>", resp);
            const dataExperienceInformal = resp.data.data;
   
            // console.log("dataExperienceInformal ==>", dataExperienceInformal);
   
            const experienceInformal = dataExperienceInformal.filter((element) => {
               return element.work_experience_type == 2;
            });
   
            // console.log("experienceInformal ==>", experienceInformal);
   
            if (experienceInformal.length != 0) {
               setMessage(false);
               setExperinceInformal(experienceInformal);
               setTimeout(() => {
                  setIsLoading(false);
               }, 1500);
   
   
            } else {
               setMessage(true);
            }
   
            setIsLoading(false);
   
         } catch (err) {
            // Handle Error Here
            setMessage(true);
            setIsLoading(false)
            console.log(err);
         }
      
      };


      getInfoUserSession();

   }, []);

   return (
      <>
         <div className={`text-white`} id="contenedorProyectosConChambaticon" role="tabpanel" aria-labelledby="dashboard-tab">
            <div className="flex flex-wrap">
            {experinceInformal[0].experience_titular != ""
                     ?
                     experinceInformal.map((experience, key) => {
                        return (
                           <>
                              <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                                 <div className="bg-Light-purple p-6 rounded-md shadow-md h-full relative">
                                    <div className="flex flex-wrap justify-between items-center mb-4">
                                       <div className="flex flex-wrap justify-center md:justify-start items-center w-2/3 order-1 md:order-1 mt-5 md:mt-0">
                                          <div className="text-lg font-semibold text-white w-full">{experience.experience_titular}</div>
                                       </div>



                                    </div>
                                    <div className="flex flex-wrap justify-between items-center mb-4">
                                       <div className={showOptions ? "w-4/5" : "w-full"}>
                                          <div className={`w-full`}>
                                             {experience.actual_work === 2 ?
                                                <h5 className="text-orange-500 text-start text-xs">Desde: {getDate(experience.fecha_inicio)} / {getDate(experience.fecha_fin)} </h5>
                                                : experience.actual_work === 1 &&
                                                <div>
                                                   <h5 className="text-orange-500 text-start text-xs">{getDate(experience.fecha_inicio)} / actualidad </h5>
                                                </div>
                                             }
                                          </div>
                                          <p className={`text-white text-sm text-left h-16 overflow-hidden w-full ${showOptions && "pr-3"}`}>
                                             {experience.freelance_experience_description.length > 120 ? experience.freelance_experience_description.substring(0, 120) + "..." : experience.freelance_experience_description}
                                          </p>
                                          <p className={`text-gray-600 my-2 w-full`}>
                                             {
                                                (experience.url_repo == '' || experience.url_repo == null) ? ''
                                                   :
                                                   <a className="text-white hover:text-Yellow text-xs" href={experience.url_repo} target="_blank">
                                                      <FontAwesomeIcon icon={faLink} /> Enlace de referencia
                                                   </a>
                                             }
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </>
                        )
                     })
                     :
                     <p className="text-center w-full">No se encontraron Registros del Portafolio</p>
               }
            </div>
         </div>
      </>
   )
}

export default Portfolio;