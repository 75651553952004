import {useParams, Link} from 'react-router-dom';
import axios, { HttpStatusCode } from "axios";
import {useEffect, useState} from 'react';

import Swal from "sweetalert2";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InsertUser = () =>{
    const{ id } = useParams();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const lenu = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [isActive, setIsActive] = useState(false);

    const sendData = (data) => {
        data.type = (data.type === "0") ? "2" : data.type;
        data.status = (data.status) ? "1" : "2";
        console.log(data);
        
        // let config = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }
        // }
        
        let uri = `${process.env.REACT_APP_API_URI}user`;
        const response = axios.post(uri, data);

        response.then((res)=>{
            console.log(res.data.message);
            Swal.fire(res.data.message, "", "success");
            reset();
        })

        response.catch((error)=>{
            Swal.fire(error.response.data.message, "", "error");
            console.log("ALERTMSG ", error.request.response);
            console.log("ERROR RESPONSE ", error.response.data.message);
            console.log('ERROR REQUEST', error.request);
            console.log('ERROR: ', error.message);
        });

        console.log(response.data);
    }

    return(
        <>
            <div id="InsertUser">
                <div className="text-center pt-5">
                    <div id="Forms">
                        <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                            <Link to={'/usuarios'} className="">
                                <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                </button>
                            </Link>
                            <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-3xl font-bold text-dark">Registrar usuario</h3>
                            </div>
                        </div>
                        <section>
                            <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                                <form id="basic-form" onSubmit={ handleSubmit(sendData) }>
                                    <div className="w-full mb-4">
                                        <h2 className="text-base font-normal">
                                            Los campos con (&nbsp;<span className="text-error font-bold">*</span>&nbsp;) son requeridos.
                                        </h2>
                                    </div>
                                    <div className="flex flex-wrap -m-2">
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="names" className="form-label">Nombres&nbsp;<span className="text-error">*</span></label>
                                                <input type="text" 
                                                    id="names" 
                                                    className="form-input" 
                                                    autoComplete="nope" 
                                                    {...register("names", {
                                                        required: "Los nombres son requeridos"
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="names"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="lastnames" className="form-label">Apellidos&nbsp;<span className="text-error">*</span></label>
                                                <input type="text" 
                                                    id="lastnames" 
                                                    className="form-input" 
                                                    autoComplete="nope" 
                                                    {...register("lastnames", {
                                                        required: "Los apellidos son requeridos"
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="lastnames"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="email" className="form-label">Correo electrónico&nbsp;<span className="text-error">*</span></label>
                                                <input 
                                                    type="email" 
                                                    id="email" 
                                                    className="form-input" 
                                                    autoComplete="nope" 
                                                    {...register("email", {
                                                        required: "El correo electrónico es requerido",
                                                        pattern: {
                                                            value: pattern,
                                                            message: "El correo electrónico es inválido"
                                                        }
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="email"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="password" className="form-label">Contraseña&nbsp;<span className="text-error">*</span></label>
                                                <input type="password" id="password" className="form-input" autoComplete="nope"
                                                    {...register("password", {
                                                        required: "Su contraseña es requerida",
                                                        minLength: {
                                                            value: 8,
                                                            message: "Ingrese minimo 8 caracteres"
                                                        },
                                                        maxLength: {
                                                            value: 15,
                                                            message: "Ingrese maximo 15 caracteres"
                                                        },
                                                        pattern: {
                                                            value: lenu,
                                                            message: "Debe contener: numeros, letras(mayusculas y minusculas) y caracteres especiales"
                                                        }
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="password"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="password_confirmation" className="form-label">Confirmar contraseña&nbsp;<span className="text-error">*</span></label>
                                                <input type="password" id="password_confirmation" className="form-input" autoComplete="nope"
                                                    {...register("password_confirmation", {
                                                        validate: (value) => {
                                                            if (value !== getValues('password')) {
                                                                return "Las contraseñas no coinciden";
                                                            }
                                                        },
                                                        required: "La confirmación de su contraseña es requerida"
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="password_confirmation"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full">
                                            <div className="relative mb-2 text-center">
                                                <label htmlFor="is_admin" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nivel de usuario&nbsp;<span className="text-error">*</span></label>
                                            </div>
                                            <div className="flex flex-wrap mt-4 max-w-md mx-auto">
                                                <div className="w-full sm:w-1/2">
                                                    <div className="relative m-2">
                                                       <input 
                                                            className="sr-only peer" 
                                                            type="radio" 
                                                            id="is_admin" 
                                                            value={ 1 }
                                                            {...register("type", { 
                                                                required: "Seleccione una opción"
                                                            })}
                                                        /> 
                                                        <label className="form-radio" htmlFor="is_admin">Administrador</label>
                                                        <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                            <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/2">
                                                    <div className="relative m-2">
                                                        <input 
                                                            className="sr-only peer" 
                                                            type="radio" 
                                                            id="not_admin" 
                                                            value={ 0 }
                                                            {...register("type", { 
                                                                required: "Seleccione una opción"
                                                            })}
                                                        />
                                                        <label className="form-radio" htmlFor="not_admin">Editor</label>
                                                        <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                            <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mb-3">
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="type"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="p-2 xl:w-1/3 w-full">
                                            <div className="relative mb-2">
                                                <label htmlFor="status" className="form-label">Estado&nbsp;<span className="text-error">*</span></label>
                                                <select 
                                                    id="status" 
                                                    className="form-select"
                                                    {...register("status", {
                                                        required: "Seleccione una opcion",
                                                        validate: (value) => {
                                                            if (value === "null") {
                                                                return "Seleccione una opcion";
                                                            }
                                                        },
                                                    })}
                                                >
                                                    <option hidden disabled selected value="null">[Seleccione una opción]</option>    
                                                    <option value={1} selected>Activo</option>
                                                    <option value={2}>Inactivo</option>   
                                                </select>
                                                <ErrorMessage
                                                    errors={ errors }
                                                    name="status"
                                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative mb-2">
                                            <div className="flex justify-center">
                                                <label htmlFor="status" className="flex items-center cursor-pointer relative">
                                                    <input type="checkbox" 
                                                        id="status" 
                                                        onClick={ () => {
                                                            setIsActive(!isActive);
                                                        } }
                                                        className="sr-only"
                                                        {...register("status")}
                                                    />
                                                    <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                                    <span className="ml-3 text-dark text-base font-medium">
                                                        { (isActive === true) && (
                                                            <>Activo</>
                                                        ) }
                                                        { (isActive === false) && (
                                                            <>Inactivo</>
                                                        ) }
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full pt-8 flex justify-end">
                                        <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Ingresar</button>
                                    </div>
                                </form>          
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InsertUser;