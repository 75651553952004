import { getLanguages } from "../../services/api/languages";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { paginationProps } from "../../utils/utils";
import { Link } from "react-router-dom";
import { set } from "react-hook-form";

export default function Languages() {
  const [search, setSearch] = useState([]);
  const [messageDatatable, setMessagesDatatable] = useState("Cargando...");
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "#",
      width: "65px",
      cell: (row) => <b>{row.id}</b>,
    },
    {
      name: "Nombre",
      width: "500px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.name.slice(0, 20)}`}
            {row.name.length > 20 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.name}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Editar",
      cell: (row) => (
        <div>
          <Link
            to={`/language/${row.id}`}
            className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue"
          >
            <FontAwesomeIcon icon="fa-solid fa-pen" />
          </Link>
        </div>
      ),
    },
    {
      name: "Estado",
      cell: (row) => (
        <div className="group">
          <span>{row.status === 1 ? "Activo" : "Inactivo"}</span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.status === 1 ? "Activo" : "Inactivo"}
            </span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getLanguages().then((response) => {
      setData(response);
      setSearch(response);
    });
  }, []);

  const changeSearch = (e) => {
    filterData(e.target.value);
  };

  const filterData = async (termSearch) => {
    var srch = data.filter((item) => {
      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(termSearch.toString().toLowerCase())
      ) {
        return item;
      }
    });
    let sizeData = srch.length;
    changeDatatableMessages(sizeData);
    await setSearch(srch);
  };

  const changeDatatableMessages = (sizeData) => {
    if (sizeData < 1) {
      setMessagesDatatable("No se encontraron registros");
    }
  };

  return (
    <>
      <div id="AttentionSchedules">
        <div className="text-center pt-5 flex flex-col md:flex-row mb-1 justify-center">
          <h1 className="text-4xl font-bold mr-5">Idiomas</h1>
          <Link
            to={`/newLanguage`}
            className="bg-theme px-4 py-3 md:mr-4 md:mb-0 my-5 md:my-0 rounded-lg font-bold shadow-md text-base text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full"
          >
            Nuevo Idioma
          </Link>
        </div>
        <section className="table-results">
          <div className="table-auto">
            {/* Custom Filter */}
            <div className="flex flex-wrap mb-5">
              <div className="w-full md:w-1/3">
                <div className="relative mb-2">
                  <label htmlFor="search" className="form-label">
                    Búsqueda por nombre
                  </label>
                  <input
                    type="text"
                    id="search"
                    className="form-input py-1"
                    autoComplete="none"
                    placeholder="Buscar..."
                    onChange={changeSearch}
                  />
                </div>
              </div>
            </div>
            <DataTable
              pagination
              columns={columns}
              data={search}
              dense
              striped
              responsive
              paginationComponentOptions={paginationProps}
              noDataComponent={<span className="p-4">{messageDatatable}</span>}
            />
          </div>
        </section>
      </div>
    </>
  );
}
