import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { set, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

const Knowledges = () => {

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const[special, setSpecial] = useState([]);

    const[data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[filtro, setFiltro] = useState(true);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');

        
    const changeFiltro = () =>{
        setFiltro(!filtro);
        if(!filtro){
            getKnowledges();
        }
    }
    const obtenerSpecialization = async () =>{
        
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        // console.log(URL);
        const result = await axios.get(URL);
        // console.log(result.data.specializationTypes);
        setSpecial(result.data.specializationTypes);
    }
    
    const getKnowledges = async () =>{
        const reg = 10000;
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/all-knowledge?numberOfItems=${reg}`;
        // console.log(URL);
        const result = await axios.get(URL);
        // console.log(result.data.data);
        let sizeData = result.data.data.length;
        changeDatatableMessages(sizeData);
        setData(result.data.data);
        setSearch(result.data.data);
    }

    useEffect(()=>{
        obtenerSpecialization();
        getKnowledges();
    },[]);

    const changeSpe = (e) =>{
        let id = e.target.value;
        // console.log(e.target.value);
        if(id == 'alls'){
            getKnowledges();
        }
        else{

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
                }
            }
            const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/Knowledge/${id}`;
            const response = axios.get(URL,config);
            response.then((res)=>{
                console.log(res.data.data);
                let sizeData = res.data.data.length;
                changeDatatableMessages(sizeData);
                setSearch(res.data.data);
                setData(res.data.data);
            });
            response.catch((error)=>{
                console.log("ERROR RESPONSE ", error.response.data);
                console.log('ERROR '+error.message);
                console.log('ERROR REQUEST '+error.request);
            })
            console.log(response.data);
        }
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.name.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
            console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }
    
    const deleteItem = (id) => {
        Swal.fire({
            title: 'Borrando Conocimiento...',
            text: "¿Esta seguro de querer borrar este conocimiento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                deletKnowledge(id)
                Swal.fire({
                    title: 'Borrado exitoso',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                })
                .then((res)=>{
                    if(res.isConfirmed){
                        window.location='/conocimientos';
                    }
                });
            }
        });
    };

    const deletKnowledge = (id) =>{
        console.log(id);
        const action = 2;
        const URL = `${process.env.REACT_APP_API_URI}Knowledge-specialization/activate-deactivate/knowledge/${id}?action=${action}`;
        console.log(URL);
        const response = axios.post(URL);
        
        response.then((res)=>{
            console.log(res.data);
        })
        response.catch((error)=>{
            console.log("ERROR RESPONSE ", error.response.data);
            console.log("ERROR ", error.message);
            console.log("ERROR REQUEST ", error.request);
        })
    } 

    const columns = [
        {
            name: '#',
            width: "10%",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombres',
            width: "60%",
            cell: (row) => <div className="group text-start">
                <span className='text-start'>{`${(row.name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-start whitespace-no-wrap bg-dark shadow-lg">{row.name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Ver / Editar',
            width: '15%',
            cell: (row) => <div>
                <Link to={'/actualizar-conocimiento/' + row.id}>
                    <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                        <FontAwesomeIcon icon="fa-solid fa-pen" />
                    </button>
                </Link>
            </div>
        },
        {
            name: 'Borrar',
            width: '15%',
            cell: (row) => <div>
                <button className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700"
                    onClick={() => deleteItem(row.id)}
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
            </div>
        }
    ];

    var nm = '#';
    console.log(encodeURI(nm).replace);
    function downloadCSV(array) {
        console.log(array);
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        console.log(csv);
        if (csv == null) return;

        const filename = 'concimientos.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
            console.log(csv);
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }  

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(search[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    
        return (
            <div id="AttentionSchedules">
                <div className="text-center pt-5 flex justify-center items-center">
                    <h1 className="text-3xl sm:text-4xl font-bold mx-4">Conocimientos</h1>
                    <Link to={'/ingresar-conocimiento'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </button>
                    </Link>
                </div>
                <section className="table-results">
                    <div className="table-auto">
                        {/* <div className="flex flex-wrap justify-center overflow-hidden text-theme">
                            <label className="px-1 m-2 font-medium text-center hover:text-indigo-900" for="collapse" onClick={changeFiltro}>
                                { filtro
                                    ? <span>Filtro</span>
                                    : <span>Quitar</span>
                                }
                            </label>
                            <input className="peer mx-4 my-3 h-0 w-0 hidden appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse" id="collapse" />
                            <div className="-transparent w-full absolute -translate-y-full scale-75 scale-y-0 px-4 pb-3 opacity-0 transition-all duration-100 delay-0 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:opacity-100">
                                //Content 
                            </div>
                        </div> */}
                        <div className='flex flex-wrap'>
                            <div className="p-2 w-full lg:w-1/3">
                                <div className="relative mb-2">
                                    <label htmlFor="search" className="form-label">Búsqueda</label>
                                    <input type="text" 
                                        id="search" 
                                        className="form-input py-1" 
                                        autoComplete="nope"
                                        placeholder='Buscar...'
                                        onChange={changeSearch}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full lg:w-1/3">
                                <div className="relative mb-2">
                                    <label htmlFor="type" className="form-label">Especialización</label>
                                    <select
                                        id="type"
                                        className="form-select py-2.5"
                                        defaultValue={"null"}
                                        {...register("type", {
                                            validate: (value) => {
                                                if (value === "null") {
                                                    return "Todos";
                                                }
                                            },
                                        })}
                                        onChange={changeSpe}
                                    >
                                        <option selected value="alls">Todos</option>
                                        {special.map((spe, i) => (
                                            <option value={spe.id} key={i}>{spe.name}</option>
                                        ))}
                                    </select>
                                    <ErrorMessage
                                        errors={errors}
                                        name="type"
                                        render={({ message }) => <span className="error-message">{message}</span>}
                                    />
                                </div>
                            </div>
                            {/* <div className="p-2 w-full lg:w-1/3">
                                <button><a href="#" onClick={()=>downloadCSV(search)}>Dowload CSV</a></button>
                            </div> */}
                        </div>
                            <DataTable
                                pagination
                                columns={columns}
                                data={search}
                                dense
                                striped
                                responsive
                                paginationComponentOptions={paginationProps}
                                noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                            />
                    </div>
                    
                </section>
            </div>
        );
}

export default Knowledges;