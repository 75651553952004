import { useParams, Link } from 'react-router-dom';
import axios, { HttpStatusCode } from "axios";
import { useEffect, useState } from 'react';

import Swal from "sweetalert2";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { data } from 'autoprefixer';

const DatailProject = () => {
    const { id } = useParams();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [isActive, setIsActive] = useState(false);

    const [data, setData] = useState([]);

    const [knowledges, setKnowledges] = useState([]);

    const [budget, setBudget] = useState(null);

    const [buttonApprove, setButtonApprove] = useState(false);

    const getDetailProject = async () => {
        console.log(id);
        let uri = `${process.env.REACT_APP_API_URI}project/get/${id}`;
        console.log(uri);
        const response = await axios.get(uri);
        console.log(response.data);
        setData(response.data);
        if(response.data.allowPublish != 1){
            setButtonApprove(true);
        }
        setKnowledges(response.data.knowledges_tags);
        setBudget(response.data.budget.range);
        // let type = (response.data.type_id === 1) ? 1 : 0 ; 

        // let status = (response.data.status === 1) ? true : false ; 

        // let defaultValues = {
        //     id: response.data.id,
        //     names: response.data.names,
        //     lastnames: response.data.lastnames,
        //     email: response.data.email,
        //     username: response.data.username,
        //     type: type.toString(),
        //     status: status
        // };

        // setIsActive(status);
        // reset(defaultValues);
    }

    useEffect(() => {
        getDetailProject();
    }, []);

    // const sendData = (data) => {
    //     data.type = (data.type === "0") ? "2" : data.type;
    //     data.status = (data.status) ? "1" : "2";
    //     console.log(data);
    //     let id = data.id;

    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         }
    //     }

    //     let uri = `${process.env.REACT_APP_API_URI}user/${id}`;
    //     const response = axios.put(uri, data, config);

    //     response.then((res)=>{
    //         console.log(res.data.message);
    //         Swal.fire(res.data.message, "", "success");
    //     })

    //     response.catch((error)=>{
    //         console.log('ERROR REQUEST', error.request);
    //         console.log('ERROR: ', error.messages);
    //     });

    //     console.log(response.data);
    // }

    const approveProject = async () =>{
        let urlApprovePorject = `${process.env.REACT_APP_API_URI}project/grant/${id}`;
        console.log(urlApprovePorject);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        try {
            const resp = await axios.post(urlApprovePorject,config);
            Swal.fire(resp.data.message, "", "success");

        } catch (err) {
            // console.error(err.response.data[1]);
            Swal.fire('error!', "", "error");
        }

        
    } 

    return (
        <>
            <div id="DatailProject">
                <div className="text-center pt-5">
                    <div id="Forms">
                        <div className="pt-5 flex items-center justify-center md:justify-start mb-6">
                            <Link to={'/proyectos'} className="">
                                <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                </button>
                            </Link>
                            <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-3xl font-bold text-dark">Detalle del proyecto</h3>
                            </div>
                        </div>
                        <section>
                            <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                                <div>
                                    <div className='text-xl text-start'>
                                        <div className='flex flex-wrap border-b-2'>
                                            <div className='flex flex-wrap py-4 w-full md:w-1/2 lg:w-3/5'>
                                                <div className='font-bold w-full lg:w-1/4'>Nombre: </div>
                                                <div className='w-3/4'>{data.project_name}</div>
                                            </div>
                                            <div className='flex flex-wrap py-4 w-full md:w-1/2 lg:w-2/5'>
                                                <div className='font-bold w-full lg:w-2/5'>Empresa: </div>
                                                <div className='w-3/5'>{data.company_name}</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap my-4'>
                                            <div className='flex-wrap w-full md:w-1/2 lg:w-1/4'>
                                                <div className='font-bold text-start'>Presupuesto: </div>
                                                <div className='text-start'>{budget}</div>
                                            </div>
                                            <div className='flex-wrap w-full md:w-1/2 lg:w-1/4'>
                                                <div className='font-bold text-start'>Publicado: </div>
                                                <div className='text-start'>{data.publication_date}</div>
                                            </div>
                                            <div className='flex-wrap w-full md:w-1/2 lg:w-1/4'>
                                                <div className='font-bold text-start'>Fecha de entrega: </div>
                                                <div className='text-start'>{data.delivery_date}</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <div className='flex-wrap w-full md:w-1/2 px-1'>
                                                <div className='font-bold'>Especialización: </div>
                                                <div className=''>{data.specialization_Name}</div>
                                            </div>
                                            <div className='flex-wrap w-full md:w-1/2 px-1'>
                                                <div className='font-bold'>Propuestas: </div>
                                                <div className=''>
                                                    {data.accept_proposals_files == 1 ? 'Acepta propuesta' : 'No acepta propuestas'}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-wrap my-6'>
                                            <div className='font-bold'>Conocimientos: </div>
                                            <div className='flex flex-wrap'>
                                                {   knowledges.map((knowledge, i)=>(
                                                        <div className=''>
                                                            <div key={i} className="mr-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-theme text-white rounded-full">
                                                                {knowledge.name}
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='flex-wrap'>
                                            <div className='font-bold'>Descripción: </div>
                                            <div className=''>{data.description}</div>
                                        </div>
                                        {buttonApprove &&
                                            <div className='py-4'>
                                            <button 
                                                className='bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full'
                                                onClick={approveProject}
                                            >
                                                Aprobar
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DatailProject;