import Swal from "sweetalert2";
import BackIcon from "../BackIcon";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { addBusinessPlan } from "../../services/api/plan";

export default function AddFormPlan() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const navigate = useNavigate();

  const estados = [
    { id: 1, name: "Publicación directa" },
    { id: 2, name: "Paso por administración" },
  ];

  const sendData = () => {
    const { imageRoute, ...rest } = getValues();
    const img = getValues("planImage");
    const data = {
      ...rest,
      planImage: img[0],
    };
    createBusinessPlan(data);
  };

  const createBusinessPlan = (data) => {
    addBusinessPlan(data)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Plan agregado exitosamente",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
          navigate(-1);
        } else {
          Swal.fire({
            title: "Error al agregar el plan",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${error.response.data.message}`,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div id="Forms">
      <div className="pt-5 mb-6">
        <div>
          <BackIcon />
        </div>
        <div className="sm:mt-0 ml-4">
          <h3 className="text-center text-3xl font-bold text-dark">
            Plan empresarial
          </h3>
        </div>
      </div>
      <section>
        <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
          <form id="basic-form" onSubmit={handleSubmit(sendData)}>
            <div className="w-full mb-4">
              <h2 className="text-base font-normal">
                Los campos con&nbsp;
                <span className="text-error font-bold">(*)</span>&nbsp;son
                requeridos.
              </h2>
            </div>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="name" className="form-label">
                    Nombre&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-input"
                    autoComplete="nope"
                    {...register("name", {
                      required: "El nombre es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="planDescription" className="form-label">
                    Descripción&nbsp;<span className="text-error">*</span>
                  </label>
                  <textarea
                    id="planDescription"
                    className="form-input"
                    autoComplete="nope"
                    {...register("planDescription", {
                      required: "La descripción es requerida",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="planDescription"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="normalPrice" className="form-label">
                    Precio Normal&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="text"
                    id="normalPrice"
                    className="form-input"
                    autoComplete="nope"
                    pattern="^[0-9]+([.][0-9]{0,2})?$"
                    {...register("normalPrice", {
                      required: "El precio es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="normalPrice"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="salePrice" className="form-label">
                    Precio de oferta&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="text"
                    id="salePrice"
                    className="form-input"
                    autoComplete="nope"
                    pattern="^[0-9]+([.][0-9]{0,2})?$"
                    {...register("salePrice", {
                      required: "El precio en oferta es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="salePrice"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="projectsPerMonth" className="form-label">
                    Proyectos a publicar por mes&nbsp;
                    <span className="text-error">*</span>
                  </label>
                  <input
                    type="number"
                    id="projectsPerMonth"
                    className="form-input"
                    autoComplete="nope"
                    pattern="[0-9]*"
                    {...register("projectsPerMonth", {
                      required: "Este campo es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="projectsPerMonth"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label
                    htmlFor="activeProjectsPerMonth"
                    className="form-label"
                  >
                    Proyectos activos por mes&nbsp;
                    <span className="text-error">*</span>
                  </label>
                  <input
                    type="number"
                    id="activeProjectsPerMonth"
                    className="form-input"
                    autoComplete="nope"
                    pattern="[0-9]*"
                    {...register("activeProjectsPerMonth", {
                      required: "Este campo es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="activeProjectsPerMonth"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="directPost" className="form-label">
                    Posteo directo&nbsp;<span className="text-error">*</span>
                  </label>
                  <select
                    id="directPost"
                    className="form-select"
                    {...register("directPost", {
                      required: "Seleccione una opcion",
                      validate: (value) => {
                        if (value === "null") {
                          return "Seleccione una opcion";
                        }
                      },
                    })}
                  >
                    {estados?.map((estado) => (
                      <option key={estado.id} value={estado.id}>
                        {estado.name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name="directPost"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="commission" className="form-label">
                    Comisión&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="number"
                    id="commission"
                    className="form-input"
                    autoComplete="nope"
                    pattern="[0-9]*"
                    {...register("commission", {
                      required: "El precio en oferta es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="commission"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="billingPeriod" className="form-label">
                    Periodo de cobro en dias&nbsp;
                    <span className="text-error">*</span>
                  </label>
                  <input
                    type="number"
                    id="billingPeriod"
                    className="form-input"
                    autoComplete="nope"
                    pattern="[0-9]*"
                    {...register("billingPeriod", {
                      required: "El periodo de cobro es requeridos",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="billingPeriod"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="planImage" className="form-label">
                    Imagen&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="file"
                    id="planImage"
                    className="form-input"
                    accept="image/*"
                    {...register("planImage", {
                      required: "La imagen es requerida",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="planImage"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-full pt-8 flex justify-end">
              <button
                type="submit"
                className="btn bg-lightblue hover:bg-dark-lightblue"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
