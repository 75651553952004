const API =process.env.REACT_APP_API_URI;

const endPoints={
    countries:{
        getCountry: (id)=>`${API}country/${id}`,
        getCountries: `${API}country`,
        createCountry: `${API}country`,
        updateCountry: (id)=>`${API}country/${id}`,
        deleteCountry: (id)=>`${API}country/${id}`
    },
    cities:{
        getCity: (id)=>`${API}city/specificCity/${id}`,
        getAllCities: `${API}city/allCities`,
        getCityState: (id)=>`${API}city/${id}`,
        updateCity: (id)=>`${API}city/updateCity/${id}`,
        deleteCity: (id)=>`${API}city/${id}`,
    },
    states:{
        getStates: (id)=>`${API}state/${id}`,
    },
    languages:{
        getLanguage: (id)=>`${API}language/${id}`,
        getLanguages: `${API}language`,
        createLanguage: `${API}language`,
        updateLanguage: (id)=>`${API}language/${id}`,
    },
    economicActivities:{
        getEconomicActivity: (id)=>`${API}economic-activity/${id}`,
        getEconomicActivities: `${API}economic-activity`,
        createEconomicActivity: `${API}economic-activity`,
        updateEconomicActivity: (id)=>`${API}economic-activity/${id}`,
        deleteEconomicActivity: (id)=>`${API}economic-activity/${id}`
    },
    businessPlans:{
        getBusinessPlan: (id)=>`${API}admin-plans/find/${id}`,
        getBusinessPlans: `${API}admin-plans/list`,
        createBusinessPlan: `${API}admin-plans/new`,
        updateBusinessPlan: (id)=>`${API}admin-plans/update/${id}`,
        deleteBusinessPlan: (id)=>`${API}admin-plans/disable/${id}`
    },
    payments:{	
        getPayment: (id)=>`${API}admin-payments/details/${id}`,
        getPayments: `${API}admin-payments/`,
        cancelPayment: (id)=>`${API}admin-payments/mark-as-done/${id}`,
    },
}

export default endPoints;