import React from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";

import DataTableExtensions from 'react-data-table-component-extensions';

import { paginationProps } from '../../utils/utils';

const Specialties = () =>{

  const [data, setData] = useState([{
        name: "...",
        icon: "...",
        id: '...',
        status:""
  }]);

const deleteItem = (id) => {

    console.log("ID :", id);

    Swal.fire({
        // title: 'Borrando ITEM...',
        text: "¿Está seguro de querer borrar este registro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, estoy seguro!',
        cancelButtonText: 'Cancelar'
    })
    .then(async (result) => {
        if (result.isConfirmed) {

            console.log('Enviar peticion')

            const urlCountry = `${process.env.REACT_APP_API_URI}specialization-type/${id}`;
            let config = {
               headers: {
                   'Content-Type': 'application/json',
                   'Accept': 'application/json',
                   }
               };
           
            try {
              const resp = await axios.delete(urlCountry, config);
        
                Swal.fire({
                    title: 'Borrado exitoso',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'OK'
                });

                getEconomicActivity();

           } catch (err) {
              // Handle Error Here
              console.error(err);
          }  
        }
    });
};

const columns = [
    {
        name: '#',
        width: "65px",
        cell: (row) => <b>{ (row.id) }</b>
    },
    {
        name: 'Nombre',
        width: "250px",
        cell: (row) => <div className="group">{row.name}</div>
    },
    {
        name: 'Estado',
        width: "250px",
        cell: (row) => <div className="group">
            {(row.status == 1) ? (
                <>Activo</>
            ) : (
                <>Innactivo</> 
            )}
        </div>
    },
    {
        name: 'Editar',
        cell: (row) => <div className="p-2">

            <Link to={`/editar-especialidad/${row.id}`} className=" bg-lightblue px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold shadow-md text-base text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
              <FontAwesomeIcon icon="fa-solid fa-pen" />
          </Link>
        </div>
    },
    {
        name: 'Borra',
        cell: (row) => <div className="p-2">

        <button className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700" onClick={() => deleteItem(row.id) }>
            <FontAwesomeIcon icon="fa-solid fa-trash" />
        </button>
        
        </div>
    }
];

    async function getEconomicActivity(){
        const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
        let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            }
        };
        
        try {
            const resp = await axios.get(urlCountry, config);
            console.log(resp.data.specializationTypes)
            setData(resp.data.specializationTypes);
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }

    };

    useEffect(() => {      
    getEconomicActivity();
    }, []);

  return (
    <div id="AttentionSchedules">
   <div className="text-center pt-5 flex mb-1 justify-center">
          <h1 className="text-4xl font-bold mr-5">Especialidades</h1>
          <Link to={`/agregar-especialidad`} className=" bg-lightblue px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold shadow-md text-base text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </Link>
        </div>
        <section className="table-results">
            <div className="table-auto">
                <DataTableExtensions
                    columns={ columns }
                    data={ data }
                    filterPlaceholder="Buscar..."
                    print={ false }
                    export={ false }
                >
                    <DataTable
                        pagination
                        columns={ columns }
                        data={ data }
                        dense
                        striped
                        responsive
                        paginationComponentOptions={ paginationProps }
                    />
                </DataTableExtensions>
            </div>
        </section>
    </div>
  )
}

export default Specialties;