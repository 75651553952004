import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { paginationProps } from "../../utils/utils";
import { getPayments } from "../../services/api/payment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import DataTableExtensions from "react-data-table-component-extensions";

export default function Payments() {
  const [data, setData] = useState([]);
  const [messageDatatable, setMessagesDatatable] = useState("Cargando...");
  
  const columns = [
    {
      name: "#",
      width: "65px",
      cell: (row) => <b>{row.id}</b>,
    },
    {
      name: "Nombre del freelancer",
      width: "250px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.names} ${row.lastnames}`}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {`${row.names} ${row.lastnames}`}
            </span>
          </div>
        </div>
      ),
    },    
    {
      name: "Empresa",
      width: "200px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.company_name.slice(0, 20)}`}
            {row.company_name.length > 20 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.company_name}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Teléfono de empresa",
      width: "200px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.company_phone.slice(0, 20)}`}
            {row.company_phone.length > 20 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.company_phone}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Detalles del pago",
      cell: (row) => (
        <div>
          <Link
            to={`/payment/${row.id}`}
            className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue"
          >
            <FontAwesomeIcon icon="info-circle" />
          </Link>
        </div>
      ),
    },
  ];
  
  useEffect(() => {
    let isMounted = true;

    const fetchCountries = async () => {
      try {
        const response = await getPayments();
        if (isMounted && response) {
          setData(response);
          changeDatatableMessages(response.length);
        }
      } catch (error) {
        setMessagesDatatable("Error al cargar los datos");
        // Maneja aquí el error de la llamada a getPayments()
      }
    };

    fetchCountries();

    return () => {
      isMounted = false;
    };
  }, []);

  const changeDatatableMessages = (sizeData) => {
    if (sizeData < 1) {
      setMessagesDatatable("No se encontraron registros");
    }
  };

  return (
    <div id="AttentionSchedules">
      <div className="text-center pt-5 flex mb-1 justify-center">
        <h1 className="text-4xl font-bold mr-5">Pagos pendientes</h1>
      </div>
      <section className="table-results">
        <div className="table-auto">
          <DataTableExtensions
            columns={columns}
            data={data}
            filterPlaceholder="Buscar..."
            print={false}
            export={false}
          >
            <DataTable
              pagination
              columns={columns}
              data={data[0]}
              dense
              striped
              responsive
              paginationComponentOptions={paginationProps}
              noDataComponent={<span className="p-4">{messageDatatable}</span>}
            />
          </DataTableExtensions>
        </div>
      </section>
    </div>
  );
}
