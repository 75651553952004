/* 
    En este archivo manejo los servicios de la API de las ciudades,
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
    }
}

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const getCities = async () => {
    const response = await axios.get(endPoints.cities.getAllCities,config);
    return response.data;
};

const getCity = async (id) => {
    const response = await axios.get(endPoints.cities.getCity(id),config);
    return response;
};

const getCityState = async (id) => {
    const response = await axios.get(endPoints.cities.getCityState(id),config);
    return response;
};

const updateCity = async (id, city) => {
    const response = await axios.post(endPoints.cities.updateCity(id), city,config);
    return response;
};

const deleteCity = async (id) => {
    const response = await axios.delete(endPoints.cities.deleteCity(id),config);
    return response;
};


export {getCities,getCity,getCityState,updateCity,deleteCity};
