import FormCity from "../../components/Formularios/FormCity"
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCity } from "../../services/api/cities";
import { useNavigate } from "react-router-dom";

export default function City() {
  const { id } = useParams();
  const [city, setCity] = useState(null);
  const navigate = useNavigate();
  // Use the useEffect hook to fetch the country data when the component is first rendered
  useEffect(() => {
    getCity(id)
      // If the request is successful, update the country state variable with the response data
      .then((response) => {
        setCity(response.data);
      })
      // If an error occurs or if the country with the given id is not found, navigate to the "not-found" page
      .catch(() => {
        navigate("/not-found");
      });
  }, [id, navigate]);

  return (
    <>
      {city && (<FormCity city={city} />)}
    </>
  )
}

