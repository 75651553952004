import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/useWindowDimensions';

import Icon from '../assets/images/icon-white.png';

import Menu from '../data/menu.json';

const IconToggle = styled.button`
    background: rgb(147, 67, 156);
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before,
    &::after {
        content: "";
        background-color: #fff;
        height: 3px;
        width: 1.5rem;
        position: absolute;
        transition: all 0.3s ease;
    }
    &::before {
        top: ${(props) => (props.clicked ? "1.5" : "1rem")};
        transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }
    &::after {
        top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
        transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
`;


const generateAlert = (data) => {
    Swal.fire({
        position: 'center',
        icon: data.type,
        title: data.message,
        showConfirmButton: false,
        timer: 1000
      })
};

const Sidebar = () => {
    const [open, setOpen] = useState(false);
    const { width } = useWindowDimensions();

    const navigate = useNavigate();

    const closeSession = () => {

        const url = `${process.env.REACT_APP_API_URI}auth/logout`;
  
        let config = {
           headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`,
           }
        }
  
        axios.get(url, config).then(response => {

            // delete cookies
            Cookies.remove('Bearer');
            
            const result = {
                type: 'success',
                message: response.data.message
            };
            generateAlert(result)

            setTimeout(() => {
            // redirect on logout
                window.location.replace('/');
            }, 2000);
        
        }).catch(err => {
              // console.log(err);
              Cookies.remove('Bearer');
              window.location.replace('/');
  
        })   
     }

    const logOut = () => {
        Swal.fire({
            title: 'Cerrando sesión...',
            text: "¿Esta seguro de querer salir?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, quiero salir!',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                closeSession()
            }
        });
    };

    function showTooltip(event) {
        const id = event.target.getAttribute('title');
        const tooltip = document.getElementById(`tooltip-${id}`);
        tooltip.style.display = "block";
      }
      
      function hideTooltip(event) {
        const id = event.target.getAttribute('title');
        const tooltip = document.getElementById(`tooltip-${id}`);
        tooltip.style.display = "none";
      }
      
    return (
        <>
            { width <= 640 && (
                <div className="fixed z-20">
                    <div className="flex justify-center p-3">
                        <IconToggle clicked={ open } onClick={() => setOpen(!open)} />
                    </div>
                </div>
            ) }
            <div
                className={ `${ open ? "w-64" : "sm:w-20 w-0" } bg-theme h-screen fixed duration-300 z-30 overflow-scroll scrollbar-hide` }
            >
                <div className={ `${ open ? "" : "sm:visible invisible" } flex justify-center py-3` } >
                    <IconToggle clicked={ open } onClick={() => setOpen(!open)} />
                </div>
                <div className="flex gap-x-4 items-center px-5 pt-5 pb-3">
                    <img
                        src={ Icon }
                        alt=""
                        className={`rounded-md duration-500 ${ open && "rotate-[360deg]" }`}
                    />
                    <h1
                        className={ `text-orange-500 origin-left font-bold text-xl duration-200 ${ !open && "scale-0" }` }
                    >
                        Administración
                    </h1>
                </div>
                <ul className="pt-6 px-3">
                    { Menu.map((menu, index) => {
                        return (
                            <li key={ index } >
                                <Link to={ `/${menu.slug}` } className="item-sidebar"  onClick={() => setOpen(false)} title={menu.title}>
                                    <FontAwesomeIcon 
                                        className={ open ? "" : "sm:inline hidden" }
                                        icon={ `fa-solid ${menu.icon}` }
                                    />
                                    <span className={`${ !open && "scale-0" } origin-left duration-200 whitespace-nowrap `}>
                                        { menu.title }
                                    </span>
                                </Link>
                            </li>
                        );
                    }) }
                    <li className="item-sidebar logout-item" onClick={ logOut } >
                        <FontAwesomeIcon
                            className={ open ? "" : "sm:inline hidden" }
                            icon={ `fas-solid fa-power-off` }
                        />
                        <span className={ `${!open && "scale-0"} origin-left duration-200 whitespace-nowrap ` }>Cerrar sesión</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
