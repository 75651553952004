import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const General = () => {
    return (
        <div className='contenido'>
            <div className="text-center pt-5 flex justify-center items-center">
                <h1 className="text-3xl sm:text-4xl font-bold mx-4">
                    Reportes
                </h1>
            </div>
            <section className="table-results p-8">
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <section class="text-gray-600 body-font">
                        <div class="container px-5 py-8 mx-auto">
                            <div class="flex flex-wrap -m-4">
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-freelancers'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-users" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Freelancers</h2>
                                            <p class="leading-relaxed text-base">por meses</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-freelancers-por-especializacion'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-users" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Freelancers</h2>
                                            <p class="leading-relaxed text-base">por conocimiento</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-empresas'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-building" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Empresas</h2>
                                            <p class="leading-relaxed text-base">por meses</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-empresas-por-actividad-economica'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-building" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Empresas</h2>
                                            <p class="leading-relaxed text-base">por actividad económica</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-proyectos-por-empresa'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-business-time" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Proyectos</h2>
                                            <p class="leading-relaxed text-base">por empresas</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-departamentos-con-mas-freelancers'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-business-time" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Estados</h2>
                                            <p class="leading-relaxed text-base">con más freelancers</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-paises-con-mas-empresas'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Países</h2>
                                            <p class="leading-relaxed text-base">con más empresas</p>
                                        </div>
                                    </Link>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <Link to={'/reportes/reportes-actividad-economica-con-mas-empresas'}>
                                        <div class="border border-gray-200 hover:border-indigo-500 hover:bg-gray-100 cursor-pointer p-6 rounded-lg">
                                            <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                                <FontAwesomeIcon icon="fa-solid fa-money-bill-trend-up" />
                                            </div>
                                            <h2 class="text-lg text-gray-900 font-medium title-font mb-2">Actividad Economica</h2>
                                            <p class="leading-relaxed text-base">con más empresas.</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}

export default General;