
export const paginationProps = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
};

export const getCurrentYear = () => {
    let date = new Date();
    let year = date.getFullYear();

    return year;
};


