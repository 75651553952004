import axios from 'axios';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';

import { paginationProps } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { getCountry } from '../../services/api/country';
import { useNavigate } from 'react-router-dom';


const initialStateEconomicActivityDefault = {
    id: 0,
    name: ""
 }

 const initialStateInfoCompany = {
    activity:{
        id:"",
        name:""
    },
    activity_id:"",
    company_description:"",
    company_name:"",
    complete_profile_status:"",
    country_id:"",
    direction:"",
    email:"",
    id:"",
    lastnames:"",
    names:"",
    phone:"",
    photo:"",
    secondary_phone:"",
    social_media_links:{
        facebook_link:"",
        instagram_link:"",
        linkedin__link:"",
        twitter__link:""
    },
    status:"",
    type_id:"",
    user_id:"",
    username:"",
};



const Empresa = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const valuesInputs = {
        sector:null,
        orden:null
    }

    const [data, setData] = useState([]);

    // console.log("data lista empresa ===> ", data);
    
    const [initialStateEconomicActivity, setInitialStateEconomicActivity] = useState([initialStateEconomicActivityDefault]);

    const [infoCompany, setInfoCompany] = useState(initialStateInfoCompany);

    const [imgCompany, setImgCompany] = useState("");

    const [showModal, setShowModal] = useState(false);

    const informationInModel = () =>{
        showModal ? setShowModal(false) : setShowModal(true); 
    };

    const statusCompany = (Status) =>{

        let status = "";

        if(Status == 1){
            status = "Completado";
            
        }else if(Status == 2){
            status = "Pendiente de completar";  
            
        }else if(Status == 3){
            status = "Verificado";   
            
        }else if(Status == 4){
            status = "Rechazado";  
        }
        return status;
    };

    
    const InfoCountry = (id) =>{
        const [country, setCountry] = useState("");

        const url = `${process.env.REACT_APP_API_URI}country/${id}`;
        let config = {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        };
        axios.get(url, data ,config)

        .then(response =>{    
        //   console.log(response);
          setCountry(response.data.name);
        }).catch(err => {
            console.log(err);
        })

        return country;

    };

    const columns = [
        {
            name: '#',
            width: "100px",
            cell: (row) => <b>{ row.id }</b>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <span>{ row.name }</span>
        },
        {
            name: 'Sector',
            width: "200px",
            cell: (row) => <span>{ row.sector }</span>
        },
        {
            name: 'Telefono',
            width: "200px",
            cell: (row) => <span>{ row.phone }</span>
        },
        {
            name: 'Correo Electrónico',
            width: "200px",
            cell: (row) => <span>{ row.email }</span>
        },
         {
          name: 'Estado',
          width: "200px",
          cell: (row) => statusCompany(Number(row.state))
         },
         {
          name: 'Habilitar perfil',
          width: "200px",
          cell: (row) => <div>
              <button onClick={() => statusProfile(row.id_empresa)} className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                <FontAwesomeIcon icon="fa-solid fa-building-circle-check" />
              </button>
             </div>
          },
         {
          name: 'Ver Información',
          width: "200px",
          cell: (row) => <div>
              <button  onClick={() => viewProfileInformation(row.id_empresa)} className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" type="button" data-modal-toggle="defaultModal" >
                <FontAwesomeIcon icon="fa-solid fa-eye" />
              </button>
             </div>
          }
         
    ];

   
 
    const sendData = () =>{

        let url = "";

        const sector = getValues().sector ;
        const orden = getValues().orden;

        if(sector == "null" && orden == "null"){

            url = `${process.env.REACT_APP_API_URI}company`;

        }else if(sector != "null" && orden == "null"){

            url = `${process.env.REACT_APP_API_URI}company?activity=${sector}`;

        }else if(sector == "null" && orden != "null"){
            
            url = `${process.env.REACT_APP_API_URI}company?status=${orden}`;

        }else{
            url = `${process.env.REACT_APP_API_URI}company?activity=${sector}&status=${orden}`;
        };


        console.log("==>", url);

        let config = {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        };

        axios.get(url, data ,config)
        .then(response =>{
            
            console.log("response ==>", response);

            if(!response.data.hasOwnProperty('message')){
                
                // console.log('response.data', response.data)
                const infoCompany = response.data.map((company, index) =>{

                    return {
                            name: company.company_name,
                            sector: company.activity,
                            phone: company.phone,
                            email: company.email,
                            state: company.complete_profile_status,
                            id: (index +1),
                            id_empresa: company.user_id
                        }
                });
                
                setData(infoCompany);

            }else{
                
                Swal.fire('No se encontraron resultado', '', 'warning');

                setData([]);


                return;
               
            };
            

        }).catch(err => {
            console.log(err);
        }

        )
    };

    const statusProfile = (Id) =>{
    
        if(Id != ""){
            Swal.fire({
                title: `Verificar o Rechazar perfil de Empresa`,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Activar',
                denyButtonText: `Rechazar`,
                cancelButtonText: 'Cancelar',
              }).then((result) => {

                if (result.isConfirmed) {

                    enableProfile(Id);
                    // sendData();

                } else if (result.isDenied) {

                    disableProfile(Id);
                    // sendData();

    
                }
              })
        }
    
        return;
    
     }


    const enableProfile = async (id) =>{
        const urlCountry = `${process.env.REACT_APP_API_URI}auth/profile-active/${id}`;
        let config = {
           headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               }
           };
       try {

        //  console.log('url', urlCountry);
          const resp = await axios.get(urlCountry, config);
        //   console.log(resp.data);
          Swal.fire(resp.data.message, '', 'success')

       } catch (err) {
          // Handle Error Here
          console.error(err);
      }
    }


    const disableProfile = async(id) =>{

        const urlCountry = `${process.env.REACT_APP_API_URI}auth/profile-deny/${id}`;
        let config = {
           headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               }
           };
       try {

        //  console.log('url', urlCountry);
          const resp = await axios.get(urlCountry, config);
        
        //   console.log(resp.data);
          Swal.fire(resp.data.message, '', 'success')

       } catch (err) {
          // Handle Error Here
          console.error(err);
      }
    }

    const navigate = useNavigate();

    const viewProfileInformation = async(id) =>{

        console.log(id, "View informacion company");

        navigate(`/informacion-empresa/${id}`);
    //     const urlCountry = `${process.env.REACT_APP_API_URI}company/profile/${id}`;

    //     let config = {
    //        headers: {
    //            'Content-Type': 'application/json',
    //            'Accept': 'application/json',
    //            }
    //        };
    //    try {

    //       const resp = await axios.get(urlCountry, config);

    //       console.log(resp.data, 'data company');
          
    //       let imgCompany = "";
    //       if(resp.data.photo !=null){
    //             imgCompany  = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
    //         }else{
    //             imgCompany  = "";
    //         };

    //         setImgCompany(imgCompany);
    //         setInfoCompany(resp.data);
    //       informationInModel();
    //     //   console.log(resp.data);
    //     //   Swal.fire(resp.data.message, '', 'success')

    //    } catch (err) {
    //       // Handle Error Here
    //       console.error(err);
    //   }
    }

   
    // const [countrys, setCountrys] = useState([]);

    // const getCountrys = async (idCountry) => {
    //     const URL = `${process.env.REACT_APP_API_URI}country/`;
    //     const response = await axios.get(URL);

    //     const country = response.data.filter( item => item.id == idCountry);

    //     console.log(country.name);
    // }

   


    useEffect(() => {
        
        async function getEconomicActivity() {
            const urlCountry = `${process.env.REACT_APP_API_URI}economic-activity`;
            let config = {
               headers: {
                   'Content-Type': 'application/json',
                   'Accept': 'application/json',
                   }
               };
           try {
              const resp = await axios.get(urlCountry, config);
            //   console.log('Economic Activity',resp.data)
              setInitialStateEconomicActivity(resp.data)
           } catch (err) {
              // Handle Error Here
              console.error(err);
          }
        };
        getEconomicActivity();

        sendData();

    }, [])
    

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold"> Empresas </h1>
            </div>
            <section className="table-results">
            
            <form id="basic-form" onSubmit={ handleSubmit(sendData) }>
                <div className='flex'>

                <div class="grid lg:grid-cols-3 gap-2 md:grid-cols-1">
                
                    <div> 
                        <div className="relative mb-2">
                            <label htmlFor="type" className="form-label">Actividad económica</label>
                                <select 
                                        id="sector" 
                                        className="form-select"
                                        defaultValue={ valuesInputs.sector }
                                        {...register("sector")}
                                        >
                                    
                                    <option hidden disabled selected value="null">[Seleccione una opción]</option>    
                                        {initialStateEconomicActivity.map((item) => {
                                                return <option value={item.id}>{item.name}</option>
                                            })
                                        }
                                </select>
                                <ErrorMessage
                                    errors={ errors }
                                    name="sector"
                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                />
                            
                        </div>
                    </div>

                    <div>
                    <div className="relative mb-2">
                            <label htmlFor="type" className="form-label">Estado</label>
                            <select 
                                    id="orden" 
                                    className="form-select"
                                    defaultValue={ valuesInputs.orden }
                                    {...register("orden")}
                            >
                                    <option hidden disabled selected value="null">[Seleccione una opción]</option>    
                                    <option value="1">Perfil Completado</option>    
                                    <option value="2">Perfil Incompleto</option>    
                                    <option value="3">Perfil Verificado </option>    
                                    <option value="4">Perfil Rechazado </option>    
                            </select>
                            <ErrorMessage
                                    errors={ errors }
                                    name="orden"
                                    render={({ message }) => <span className="error-message">{ message }</span>}
                                />
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue mt-7 mb-5 mx-2">Filtrar</button>
                        {/* <button className="btn bg-lightblue hover:bg-dark-lightblue mt-7 mb-5 mx-2">Limpiar filtro</button> */}
                    </div>

                </div>
                

                </div>

            </form>

                <div className="table-auto w-full">
                    {/* <DataTableExtensions
                        columns={ columns }
                        data={ data }
                        filterPlaceholder="Buscar..."
                        print={ false }
                        export={ false }
                    >
                        
                    </DataTableExtensions> */}
                        
                        <DataTable
                            pagination
                            columns={ columns }
                            className='w-full'
                            data={ data }
                            dense
                            striped
                            responsive
                            paginationComponentOptions={ paginationProps }
                        />

                </div>
            </section>

            
            <div id="defaultModal" tabindex="-1" aria-hidden="true" 
            className={`${showModal ? 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex justify-center' : 'hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full'}`}>
                <div className="relative p-4 mt-20 w-full max-w-5xl h-full md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                       
                        <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600 text-center">
                            <h1 className="text-2xl font-bold text-center w-full"> Información del perfil </h1>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal" onClick={informationInModel}>
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                      
                        <div className="p-6">

                        <section className="text-gray-600 body-font">

                            <div className="container mx-auto">

                                <div class="grid lg:grid-cols-2 gap-2 md:grid-cols-1">

                                    <div>
                                        <div className="flex flex-col text-center w-full mb-2">
                                            <div className="bg-full flex justify-center p-2">
                                            <img alt="team" className="bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4 w-28 h-28" src={imgCompany} />
                                            </div>
                                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{infoCompany.names}</h1>
                                            <h1 className="mx-auto leading-relaxed text-base text-justify mb-2">{infoCompany.email}</h1>
                                            <h1 className="mx-auto leading-relaxed text-base text-justify mb-2">{infoCompany.activity.name}</h1>
                                            <p className="mx-auto leading-relaxed text-base text-justify">{infoCompany.company_description}</p>
                                        </div>
                                    </div>

                                    <div>

                                    <div className="flex flex-wrap mt-5">

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                            
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">Estado actual</h2>
                                                {statusCompany(infoCompany.complete_profile_status)}
                                            </div>
                                            </div>
                                        </div>
                                    
                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                        
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">Teléfono Principal</h2>
                                                <p className="text-gray-500">{infoCompany.phone}</p>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                            
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">Teléfono secundario</h2>
                                                <p className="text-gray-500">{infoCompany.secondary_phone}</p>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                            
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">Persona de contacto</h2>
                                                <p className="text-gray-500">{infoCompany.company_contact_name}</p>
                                            </div>
                                            </div>
                                        </div>
                                    
                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                        
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">País</h2>
                                                <p className="text-gray-500"> {InfoCountry(infoCompany.country_id)} </p>
                                            </div>
                                            </div>
                                        </div>
                                        
                                    
                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                            
                                            <div className="flex-grow">
                                                <h2 className="text-gray-900 title-font font-medium">Dirección</h2>
                                                <p className="text-gray-500">{infoCompany.direction}</p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                            
                                            <div className="flex-grow">
                                            {(infoCompany.social_media_links.facebook_link != null&&
                                                <a href={infoCompany.social_media_links.facebook_link} target="_black" className='p-2'>
                                                Facebook
                                                <br></br>
                                                </a> 
                                            )}
                                            
                                            {(infoCompany.social_media_links.twitter_link != null && 
                                                <a href={infoCompany.social_media_links.twitter_link} target="_black" className='p-2'>
                                               Twitter
                                               <br></br>
                                                </a>  
                                            )}

                                            
                                            {(infoCompany.social_media_links.instagram_link != null && 
                                                <a href={infoCompany.social_media_links.instagram_link} target="_black" className='p-2'>
                                                Instagram
                                                <br></br>
                                                </a>  
                                            )}


                                            {(infoCompany.social_media_links.linkedin_link != null &&
                                                <a href={infoCompany.social_media_links.linkedin_link} target="_black" className='p-2'>
                                                    Linkedin
                                                    <br></br>
                                                </a>
                                            )}
                                            </div>
                                            </div>
                                        </div>

                                    </div>

                                    </div>

                                </div>
                                
                            </div>
                            </section>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

        </div>
        
    );
};

export default Empresa;