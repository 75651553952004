import {
  getEconomicActivities,
  deleteEconomicActivity,
} from "../../services/api/economicActivities";
import DataTableExtensions from "react-data-table-component-extensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { paginationProps } from "../../utils/utils";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function EconomicActivities() {
  const [search, setSearch] = useState([]);
  const [messageDatatable, setMessagesDatatable] = useState("Cargando...");
  const [data, setData] = useState([]);
  //Update is a boolean that will be used to trigger a useEffect when the user delete a item
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    getEconomicActivities().then((res) => {
      setData(res);
      setSearch(res);
    });
  }, [update]);

  const deleteItem = (name, id) => {
    Swal.fire({
      title: `Borrando ${name}...`,
      text: `¿Está seguro de querer borrar esta Actividad?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEconomicActivity(id).then((response) => {
          setUpdate(!update);
          if (response.status === 200) {
            setUpdate(!update);
            Swal.fire({
              title: "Borrado exitoso",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Error al borrar",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "OK",
            });
          }
        });
      }
    });
  };

  const columns = [
    {
      name: "#",
      width: "65px",
      cell: (row) => <b>{row.id}</b>,
    },
    {
      name: "Nombre",
      width: "400px",
      cell: (row) => (
        <div className="group">
          <span>
            {`${row.name.slice(0, 30)}`}
            {row.name.length > 30 ? "..." : ""}
          </span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">
              {row.name}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Editar",
      cell: (row) => (
        <div>
          <Link
            to={`/economicActivity/${row.id}`}
            className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue"
          >
            <FontAwesomeIcon icon="fa-solid fa-pen" />
          </Link>
        </div>
      ),
    },
    {
      name: "Borrar",
      cell: (row) => (
        <div>
          <button
            className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700"
            onClick={() => deleteItem(row.name, row.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />
          </button>
        </div>
      ),
    },
  ];

  const changeSearch = (e) => {
    filterData(e.target.value);
  };

  const filterData = async (termSearch) => {
    var srch = data.filter((item) => {
      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(termSearch.toString().toLowerCase())
      ) {
        return item;
      }
    });
    let sizeData = srch.length;
    changeDatatableMessages(sizeData);
    await setSearch(srch);
  };

  const changeDatatableMessages = (sizeData) => {
    if (sizeData < 1) {
      setMessagesDatatable("No se encontraron registros");
    }
  };

  return (
    <>
      <div id="AttentionSchedules">
        <div className="text-center pt-5 flex flex-col md:flex-row mb-1 justify-center">
          <h1 className="text-4xl font-bold mr-5">Actividad Económica</h1>
          <Link
            to={`/newActivity`}
            className="bg-theme px-4 py-3 md:mr-4 md:mb-0 my-5 md:my-0 rounded-lg font-bold shadow-md text-base text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full"
          >
            Agregar Actividad
          </Link>
        </div>
        <section className="table-results">
          <div className="table-auto">
            {/* Custom Filter */}
            <div className="flex flex-wrap mb-5">
              <div className="w-full md:w-1/3">
                <div className="relative mb-2">
                  <label htmlFor="search" className="form-label">
                    Búsqueda por nombre
                  </label>
                  <input
                    type="text"
                    id="search"
                    className="form-input py-1"
                    autoComplete="none"
                    placeholder="Buscar..."
                    onChange={changeSearch}
                  />
                </div>
              </div>
            </div>
            <DataTable
              pagination
              columns={columns}
              data={search}
              dense
              striped
              responsive
              paginationComponentOptions={paginationProps}
              noDataComponent={<span className='p-4'>{messageDatatable}</span>}
            />
          </div>
        </section>
      </div>
    </>
  );
}
