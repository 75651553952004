/* 
    En este archivo manejo los servicios de la API de los estados,
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
    }
}

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const getStates = async () => {
    const response = await axios.get(endPoints.states.getStates(3),config);
    return response.data;
};

export {getStates};