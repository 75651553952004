/* 
    En este archivo manejo los servicios de la API de los países.
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const addCountry = async (country) => {
    const response = await axios.post(endPoints.countries.createCountry, country,config);
    return response;
};

const deleteCountry = async (id) => {
  const response = await axios.delete(endPoints.countries.deleteCountry(id),config);
  return response;
};

const getCountries = async () => {
    const response = await axios.get(endPoints.countries.getCountries,config);
    return response.data;
};

const getCountry = async (id) => {
    const response = await axios.get(endPoints.countries.getCountry(id),config);
    return response;
};

const updateCountry = async (id, country) => {
    const response = await axios.put(endPoints.countries.updateCountry(id), country,config);
    return response;
};


export { addCountry,deleteCountry,getCountries,getCountry,updateCountry};
