
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import imgDefault from "../../assets/imgDefault.png";
import FlechaAtras from "../../assets/FlechaAtras.png";


function InformacionEmpresa() {

    const initialStateInfo = {
        activity: { id: "", name: '' },
        activity_id: "",
        company_contact_name: "",
        company_description: "",
        company_name: "",
        complete_profile_status: "",
        country_id: "",
        direction: "",
        email: "",
        id: "",
        lastnames: "",
        latitude: "",
        longitude: "",
        names: "",
        phone: "",
        photo: "",
        plan: "",
        planes_id: "",
        secondary_phone: "",
        social_media_links: {
            facebook_link: "",
            instagram_link: "",
            linkedin_link: "",
            twitter_link: ""
        },
        status: "",
        type_id: "",
        user_id: "",
        username: "",
    };

    const initialStateCountry = {
        code: 0,
        id: 0,
        name: ""
    };

    let params = useParams();

    const navigate = useNavigate();


    const [infoEmpresa, setInfoEmpresa] = useState(initialStateInfo);

    const [MostrarImg, setMostrarImg] = useState("second");



    useEffect(() => {

        async function getInfoEmpresa() {
            const urlInfoEmpresa = `${process.env.REACT_APP_API_URI}company/profile/${params.id}`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };
            try {

                const resp = await axios.get(urlInfoEmpresa, config);

                let imgCompany = "";

                if (resp.data.photo == null || resp.data.photo == "") {
                    imgCompany = imgDefault;
                } else {
                    imgCompany = `${process.env.REACT_APP_PHOTO_URI}company-image/${resp.data.photo}`;
                }

                setMostrarImg(imgCompany);

                console.log("informacion empresa ==>", resp);

                setInfoEmpresa(resp.data);

            } catch (err) {
                console.error(err);
            }
        }
        getInfoEmpresa();


    }, [])

    const statusCompany = (Status) =>{

        let status = "";

        if(Status == 1){
            status = "Completado";
            
        }else if(Status == 2){
            status = "Pendiente de completar";  
            
        }else if(Status == 3){
            status = "Verificado";   
            
        }else if(Status == 4){
            status = "Rechazado";  
        }
        return status;
    };

    return (
        <div className="bg-gray-100">
            <div className="container mx-auto py-8">
            <div class="w-full sm:w-15%">
            <h1 className="text-4xl font-bold text-center"> Información empresa </h1>
                <div className='flex items-center py-6 cursor-pointer' onClick={() =>  navigate("/empresa")}>
                    <img src={FlechaAtras} class="mx-4 imgFlecha" width={"10px"} alt="" /> 
                        <span className='px-2'>Volver</span>
                </div>
                </div>
                <div className="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
                    <div className="col-span-4 sm:col-span-3">
                        <div className="bg-white shadow rounded-lg p-6">
                            <div className="flex flex-col items-center">

                                <div class="pb-4">
                                    
                                    {/* <h5 class="text-Yellow text-center">Estado del perfil</h5> */}
                                    {/* <p class="text-gray-700 text-center">{statusCompany(infoEmpresa.complete_profile_status)}</p> */}
                                    
                                    <h2 className="text-xl font-bold text-center">Estado de perfil</h2>

                                    <span className="inline-block py-2 px-4 rounded bg-[#241145] text-white text-xs font-medium tracking-widest">{statusCompany(infoEmpresa.complete_profile_status)}</span>

                                    {/* <p className="text-gray-700 text-center"></p> */}
                                </div>

                                <img src={MostrarImg} className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0" />
                                {/* <div class="mt-6 flex flex-wrap gap-4 justify-center">
                                    <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Activar </button>

                                    <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Rechazar</button>
                                </div> */}
                            </div>
                            <div className="my-6 border-t border-gray-300">
                                <div className="flex justify-center items-center gap-6 my-6">


                                    {infoEmpresa.social_media_links.facebook_link != null ? (
                                        <a className="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds Facebook" href=""
                                            target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-6">
                                                <path fill="currentColor"
                                                    d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                                </path>
                                            </svg>
                                        </a>
                                    ) : (<></>)}

                                    {infoEmpresa.social_media_links.instagram_link != null ? (
                                        <a className="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds Instagram" href=""
                                            target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-6">
                                                <path fill="currentColor"
                                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                                </path>
                                            </svg>
                                        </a>
                                    ) : (<></>)}

                                    {infoEmpresa.social_media_links.twitter_link != null ? (
                                        <a className="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds Twitter" href=""
                                            target="_blank">
                                            <svg className="h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                                                </path>
                                            </svg>
                                        </a>
                                    ) : (<></>)}

                                    {infoEmpresa.social_media_links.linkedin_link != null ? (
                                        <a className="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds LinkedIn" href=""
                                            target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-6">
                                                <path fill="currentColor"
                                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                                </path>
                                            </svg>
                                        </a>
                                    ) : (<></>)}







                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 sm:col-span-9">

                        <div className="bg-white shadow rounded-lg p-6 mb-4">
                            <h2 className="text-xl font-bold mb-4">Nombre de la empresa</h2>
                            <p className="text-gray-700">{infoEmpresa.company_name}</p>
                        </div>

                        <div className="bg-white shadow rounded-lg p-6 my-4">
                            <h2 className="text-xl font-bold mb-4">Actividad económica</h2>
                            <p className="text-gray-700">{infoEmpresa.activity.name}</p>
                        </div>

                        <div className="bg-white shadow rounded-lg p-6 my-4">
                            <h2 className="text-xl font-bold mb-4">Información de contacto</h2>
                            <section className="text-gray-600 body-font">
                                <div className="container px-5  mx-auto">
                                    <div className="flex flex-wrap -m-2">

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Persona de contacto</h2>
                                                    <p className="text-gray-500">{infoEmpresa.company_contact_name}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Correo electrónico</h2>
                                                    <p className="text-gray-500">{infoEmpresa.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Páis</h2>
                                                    <p className="text-gray-500">{infoEmpresa.country_id}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Teléfono principal</h2>
                                                    <p className="text-gray-500">{infoEmpresa.phone}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Teléfono secundario</h2>
                                                    <p className="text-gray-500">{infoEmpresa.secondary_phone}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                                                <div className="flex-grow">
                                                    <h2 className="text-gray-900 title-font font-medium">Dirección</h2>
                                                    <p className="text-gray-500">{infoEmpresa.direction}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="bg-white shadow rounded-lg p-6 my-4">
                            <h2 className="text-xl font-bold mb-4">Descripción de la empresa</h2>
                            <p className="text-gray-700">{infoEmpresa.company_description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformacionEmpresa