import { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram, faInstagramSquare, faTwitterSquare, faWhatsapp, faWhatsappSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import imgProfileDefecto from "./img/user.b9b76e787ce80b87c1ab.png"
import imgBannerDefecto from "./img/banner_a_medida.0f9f4898b8a57ee92338.jpg"
import Cookies from 'js-cookie';
import TabsInfo from '../../components/Tabs/Tabs';
import axios from 'axios';

const VerFreelancer = () => {

    const initialStateInfo = {
        about_me: "",
        age: "",
        birthDate: "",
        briefcase_link: "",
        city_id: "",
        complete_profile_status: "",
        country: {
            id: "",
            name: "",
            code: ""
        },
        cover_photo: "",
        created_at: "",
        direction: "",
        district: { id: "", cities_id: "", name: "" },
        district_id: "",
        dui: "",
        id: "",
        iva: "",
        iva_register: "",
        languages: "",
        latitude: "",
        longitude: "",
        nit: "",
        parent: "",
        phone: "",
        phone_alternative: "",
        photo: "",
        rating: "",
        schedules: "",
        sexo: "",
        social_media_links: "",
        state: { id: "", name: "" },
        status: "",
        updated_at: "",
        user: { id: "", names: "", lastnames: "", type_id: "", provider: "" },
        user_id: "",
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id } = useParams();
    
    const idFreelancer = id;

    const [InfoFreelancer, setInfoFreelancer] = useState(initialStateInfo);

    console.log("InfoFreelancer ==>", InfoFreelancer);

    var urlFotoPerfil = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-img/`;
    var urlFotoCover = `${process.env.REACT_APP_PHOTO_URI}freelancer/profile-cover/`;

    const [loaded, setLoaded] = useState(false);

    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    if (Cookies.get('Bearer') !== undefined) {
        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URI}admin-freelance/profile/${id}`, config)
            .then((res) => res.json())
            .then((info) =>{
                console.log(info);
                setInfoFreelancer(info)                
            })
            .catch((err) => console.log(err))
            .finally(() => setLoaded(true));
    }, []);

    const deleteItem = () => {
        Swal.fire({
            title: 'Rechazando Freelancer...',
            text: "¿Esta seguro de querer rechazarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.isConfirmed) {

                    fetch(`${process.env.REACT_APP_API_URI}auth/profile-deny/${id}`, {

                        method: 'GET',
                        mode: 'cors'

                    })

                    Swal.fire({
                        title: 'Rechazado exitosamente',
                        icon: 'success',
                        allowOutsideClick: false,
                        confirmButtonText: 'OK'
                    }).then(function () {
                        window.location.href = "/ListFreelancer";
                    });
                }
            });
    };

    const aceptarFreelancer = () => {
        fetch(`${process.env.REACT_APP_API_URI}auth/profile-active/${id}`, {

            method: 'GET',
            mode: 'cors'

        })

        Swal.fire({
            title: 'Aceptado exitosamente',
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'OK'
        }).then(function () {
            window.location.href = "/ListFreelancer";
        });
    };
    var sexo = "";

    var redesSociales = [];

    if (loaded && InfoFreelancer.sexo === 1) {
        sexo = "Hombre";
    } else if (loaded && InfoFreelancer.sexo === 2) {
        sexo = "Mujer";
    } else {
        sexo = "Prefiero no decirlo"
    }

    var secondCel = "";
    var billing = "";
    var iva = "";
    var iva_register = "";
    var imgCoverPhoto = "";
    var imgPhoto = "";

    if (loaded && InfoFreelancer.phone_alternative === null) {
        secondCel = "No ingresado";
    } else {
        secondCel = loaded && InfoFreelancer.phone_alternative;
    }

    if (loaded && InfoFreelancer.iva === 0) {
        iva = "No";
    } else {
        iva = "Si";
    }

    if (loaded && InfoFreelancer.iva_register === null) {
        iva_register = "No";
    } else {
        iva_register = loaded && InfoFreelancer.iva_register;
    }

    if (loaded && InfoFreelancer.user.billing === "CF") {
        billing = "Consumidor final";
    } else {
        billing = "Comprobante de crédito fiscal";
    }

    if (loaded && InfoFreelancer.photo === null) {
        imgPhoto = `${imgProfileDefecto}`;
    } else {
        imgPhoto = loaded && urlFotoPerfil + InfoFreelancer.photo;
    }

    if (loaded && InfoFreelancer.cover_photo === null) {
        imgCoverPhoto = `${imgBannerDefecto}`;
    } else {
        imgCoverPhoto = loaded && urlFotoCover + InfoFreelancer.cover_photo;
    }

    loaded && InfoFreelancer.social_media_links.map(elementos1 => (
        elementos1 === "" ? (
            redesSociales.push("")
        ) : (
            redesSociales.push(elementos1)
        )
    ))








    return (
        <>
            <h1 className="my-5 text-center font-bold text-4xl">Información de Freelancer</h1>
            <div className="flex items-center justify-center">
                <div className="relative w-full max-w-6xl border rounded-lg shadow-md bg-white">
                    <Link to="/ListFreelancer" className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white focus:ring-4 focus:outline-none rounded-lg absolute top-3 left-3" style={{ background: "rgb(147,67,156)" }}><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className="flex flex-wrap items-center pb-10 px-4 pt-8">
                        <div className="w-full">
                            <div className="text-center">
                                <div className="flex justify-center items-center p-5">
                                    <div className='flex items-center justify-center rounded-lg w-3/4 relative text-white bg-black'>
                                        <img className="w-full h-32 md:h-40 lg:h-48 rounded-lg" style={{ opacity: "0.2" }}
                                            src={imgCoverPhoto} alt="Imagen de fondo" />
                                        <img className="w-20 h-20 md:w-24 md:h-24 rounded-full shadow-lg absolute top-0 right-0 left-0 bottom-0 m-auto"
                                            src={imgPhoto} alt="Imagen de perfil" />
                                    </div>
                                </div>
                                <h5 className="mb-1 mt-5 text-xl font-medium text-black">
                                    {
                                        // loaded && InfoFreelancer.user.names + " " + InfoFreelancer.user.lastnames
                                        loaded && InfoFreelancer.user.names + " " + InfoFreelancer.user.lastnames

                                    }
                                </h5>
                                <span className="text-sm text-gray-500">
                                    {
                                        loaded && InfoFreelancer.user.names + " " + InfoFreelancer.user.lastnames
                                    }
                                </span>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Nombre de usuario</span><br />{loaded && InfoFreelancer.user.username}
                                </div>
                                <div className="w-full p-2 text-sm text-gray-500 text-center">
                                    <span className="font-bold text-black">Acerca de mi</span><br />{loaded && InfoFreelancer.about_me}
                                </div>
                                <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Dirección</span><br />{loaded && InfoFreelancer.direction}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Email</span><br />{loaded && InfoFreelancer.user.email}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Fecha de nacimiento</span><br />{loaded && InfoFreelancer.birthDate}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Edad</span><br />{loaded && InfoFreelancer.age} años
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Teléfono</span><br />{loaded && InfoFreelancer.phone}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Teléfono alternativo</span><br />{loaded && secondCel}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Genero</span><br />
                                    {
                                        sexo
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">País</span><br />
                                    {
                                        InfoFreelancer.country.name
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Departamento</span><br />
                                    {
                                        InfoFreelancer.state.name
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Distrito</span><br />
                                    {
                                        InfoFreelancer.district.name
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">DUI</span><br />{loaded && InfoFreelancer.dui}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">IVA</span><br />{loaded && iva}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Registro de IVA</span><br />{loaded && iva_register}
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Idiomas</span>
                                    <br />
                                    {
                                        loaded && InfoFreelancer.languages.map(elementos => (
                                            <>{loaded && elementos.name} </>
                                        ))
                                    }
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Redes sociales</span>
                                    <br />
                                    <div className="mt-3">

                                        {(loaded && redesSociales[0] !== '' &&
                                            <a href={redesSociales[0]} target="_black" className='m-3'>
                                                <FontAwesomeIcon className="text-black text-2xl sm:ml-0" icon={faFacebookSquare} />
                                            </a>
                                        )}

                                        {(loaded && redesSociales[1] !== '' &&
                                            <a href={redesSociales[1]} target="_black" className='m-3'>
                                                <FontAwesomeIcon className="text-black text-2xl" icon={faTwitterSquare} />
                                            </a>
                                        )}

                                        {(loaded && redesSociales[2] !== '' &&
                                            <a href={redesSociales[2]} target="_black" className='m-3'>
                                                <FontAwesomeIcon className="text-black text-2xl" icon={faLinkedin} />
                                            </a>
                                        )}

                                        {(loaded && redesSociales[3] !== '' &&
                                            <a href={redesSociales[3]} target="_black" className='m-3'>
                                                <FontAwesomeIcon className="text-black text-2xl" icon={faInstagramSquare} />
                                            </a>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="w-full sm:w-1/2 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Organización</span><br />{loaded && InfoFreelancer.user.provider}
                                </div> */}
                                {/* <div className="w-full sm:w-1/2 md:w-1/4 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Forma de facturación</span><br />{loaded && billing}
                                </div> */}

                                {/* <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Método de pago</span><br />{loaded && InfoFreelancer.payment_method.method}
                                </div> */}
                                {/* <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                    <span className="font-bold text-black">Detalles del método de pago</span><br />{loaded && InfoFreelancer.payment_method.method_details}
                                </div> */}


                                
                                <TabsInfo idFreelancer={idFreelancer} />
                            </div>
                            <div className="flex mt-4 space-x-3 md:mt-6 justify-center">
                                
                                <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-excel hover:bg-dark-excel focus:ring-4 focus:outline-none rounded-lg" onClick={aceptarFreelancer}>Aceptar</button>
                                
                                <button className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-pdf hover:bg-dark-pdf rounded-lg" onClick={deleteItem} >Rechazar</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerFreelancer;
