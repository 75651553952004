import FormLanguage from "../../components/Formularios/FormLanguage";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getLanguage } from "../../services/api/languages";
import { useNavigate } from "react-router-dom";

export default function Language() { 
  const { id } = useParams();
  const [language, setLanguage] = useState({});
  const navigate = useNavigate();
  // Use the useEffect hook to fetch the language data when the component is first rendered
  useEffect(() => {
    getLanguage(id)
      // If the request is successful, update the language state variable with the response data
      .then((response) => {
        setLanguage(response.data);
      })
      // If an error occurs or if the language with the given id is not found, navigate to the "not-found" page
      .catch(() => {
        navigate("/not-found");
      });
  }, [id, navigate]);
  // Render the FormLanguage component and pass it the language data from the state
  return (
    <>
      {language && (<FormLanguage language={language} />)}
    </>
  )
}
