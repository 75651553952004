/* 
    En este archivo manejo los servicios de la API de los planes.
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';
let config1 = {
  headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    //   'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
  }
}

let config2 = {
  headers: {
      'content-type': 'multipart/form-data',
    //   'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
  }
}

if (Cookies.get('Bearer') !== undefined) {
    config1.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
    config2.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const addBusinessPlan = async (businessPlan) => {
    const response = await axios.post(endPoints.businessPlans.createBusinessPlan, businessPlan,config2);
    return response;
}

const deleteBusinessPlan = async (id) => {
    const response = await axios.post(endPoints.businessPlans.deleteBusinessPlan(id),config1);
    return response;
}

const getBusinessPlans = async () => {
    const response = await axios.get(endPoints.businessPlans.getBusinessPlans,config1);
    return response.data;
}

const getBusinessPlan = async (id) => {
    const response = await axios.get(endPoints.businessPlans.getBusinessPlan(id),config1);
    return response;
}

const updateBusinessPlan = async (id, businessPlan) => {
    const response = await axios.post(endPoints.businessPlans.updateBusinessPlan(id), businessPlan,config2);
    return response;
}

export { addBusinessPlan, deleteBusinessPlan, getBusinessPlans, getBusinessPlan, updateBusinessPlan };