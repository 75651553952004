import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import Cookies from 'js-cookie';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

import exportFromJSON from 'export-from-json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const ReportsDeptoMoreFreelancers = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const [data2, setData2] = useState([]);

    const [loaded, setLoaded] = useState(false);

    const [search, setSearch] = useState([]);

    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');

    useEffect(() => {
        getDeptoMoreFreelancers();
        get5StateMoreFrelancers();
    }, []);

    const getDeptoMoreFreelancers = async () => {
        const URL = `${process.env.REACT_APP_API_URI}report/freelancers-states`;
        const response = await axios.get(URL);
        console.log(response.data.records);
        setDataArray(response.data.records);
        setSearch(response.data.records);
        setData(response.data.records);
    }

    const get5StateMoreFrelancers = async () => {
        const URL = `${process.env.REACT_APP_API_URI}report/freelancers-states?limit=5`;
        const response = await axios.get(URL);
        console.log(response.data.records);
        setDataArray(response.data.records);
        setData2(response.data.records);
    }

    ChartJS.register(ArcElement, Tooltip, Legend);
    const chart = {
        labels: data2.map(element =>(element.name)),
        datasets: [
            {
                label: 'Cantidad de freelancers',
                data: data2.map(element =>(element.quantity)),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const setDataArray = (arr) => {
        let ii = 1;
        for (var i = 0; i < arr.length; i++) {
            arr[i].id = ii;
            ii++;
        }
        return arr
    }

    var date = new Date();
    const generateFileExcel = () => {

        // console.log(date);
        if (Array.isArray(search)) {
            let info = [];

            for (let i = 0; i < search.length; i++) {
                info.push({
                    "No": search[i].id,
                    "Estado o Departamento": search[i].name,
                    "Cantidad de Freelancers": search[i].quantity
                });
            }

            console.log(info);

            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-estados-con-mas-freelancers`;
            const exportType = exportFromJSON.types.xls;

            exportFromJSON({ data: info, fileName: fileName, exportType: exportType });
        }
        else {
            console.log("No es array");
        }
    };

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) => {
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) => {
        console.log(termSearch);
        var srch = data.filter((item) => {
            if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.quantity.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                return item;
            }
            console.log(item);
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    const columns = [
        {
            name: '#',
            width: "10%",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Estado',
            width: "60%",
            cell: (row) => <div className="group">
                <span>{`${(row.name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Cantidad de freelancers',
            width: "30%",
            cell: (row) => <div className="group">
                <span>{`${(row.quantity)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center md:justify-start items-center">
                <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                    <Link to={'/reportes/general'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        </button>
                    </Link>
                    <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-3xl font-bold text-dark">Reportes estados con más freelancers</h3>
                    </div>
                </div>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='p-0 md:px-14 lg:px-28 xl:px-72'>
                        <Pie data={chart} />
                    </div>
                    <div className='flex flex-wrap items-end'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text"
                                    id="search"
                                    className="form-input py-1"
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <button onClick={generateFileExcel} className="flex items-center text-white bg-green-800 border-0 h-12 py-2 my-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                <FontAwesomeIcon icon="fa-solid fa-file-export" className='mr-2' />
                                <span className="text-xs text-white">Exportar Excel</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default ReportsDeptoMoreFreelancers;