/* 
    En este archivo manejo los servicios de la API de los idiomas.
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
    }
}

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const addLanguage = async (language) => {
    const response = await axios.post(endPoints.languages.createLanguage, language,config);
    return response;
};

const getLanguages = async () => {
    const response = await axios.get(endPoints.languages.getLanguages,config);
    return response.data;
};

const getLanguage = async (id) => {
    const response = await axios.get(endPoints.languages.getLanguage(id),config);
    return response;
}

const updateLanguage = async (id, language) => {
    const response = await axios.put(endPoints.languages.updateLanguage(id), language,config);
    return response;
}

export { addLanguage, getLanguages, getLanguage, updateLanguage };
