import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Forms = () => {
    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [isActive, setIsActive] = useState(false);

    const activeCheckbox = () => {
        setIsActive(!isActive);
    };

    const sendData = (data) => {
        console.log(data);
    };

    return (
        <div id="Forms">
            <div className="pt-5 flex items-center justify-center mb-6">
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Formulario</h3>
                </div>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <form id="basic-form" onSubmit={ handleSubmit(sendData) }>
                        <div className="w-full mb-4">
                            <h2 className="text-base font-normal">
                                Los campos con&nbsp;<span className="text-error font-bold">*</span>&nbsp;son requeridos.
                            </h2>
                        </div>
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="names" className="form-label">Nombres&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="names" className="form-input" autoComplete="nope"
                                        {...register("names", {
                                            required: "Los nombres son requeridos"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="names"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="lastnames" className="form-label">Apellidos&nbsp;<span className="text-error">*</span></label>
                                    <input type="text" id="lastnames" className="form-input" autoComplete="nope"
                                        {...register("lastnames", {
                                            required: "Los apellidos son requeridos"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="lastnames"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="email" className="form-label">Correo electrónico&nbsp;<span className="text-error">*</span></label>
                                    <input type="email" id="email" className="form-input" autoComplete="nope"
                                        {...register("email", {
                                            required: "El correo electrónico es requerido",
                                            pattern: {
                                                value: pattern,
                                                message: "El correo electrónico es inválido"
                                            }
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="email"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="password" className="form-label">Contraseña&nbsp;<span className="text-error">*</span></label>
                                    <input type="password" id="password" className="form-input" autoComplete="nope"
                                        {...register("password", {
                                            required: "Su contraseña es requerida"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="password"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="password_confirmation" className="form-label">Confirmar contraseña&nbsp;<span className="text-error">*</span></label>
                                    <input type="password" id="password_confirmation" className="form-input" autoComplete="nope"
                                        {...register("password_confirmation", {
                                            validate: (value) => {
                                                if (value !== getValues('password')) {
                                                    return "Las contraseñas no coinciden";
                                                }
                                            },
                                            required: "La confirmación de su contraseña es requerida"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="password_confirmation"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative mb-2 text-center">
                                    <label htmlFor="is_admin" className="leading-7 text-base text-gray-600 font-semibold block mb-1">Nivel de usuario&nbsp;<span className="text-error">*</span></label>
                                </div>
                                <div className="flex flex-wrap mt-4 max-w-md mx-auto">
                                    <div className="w-full sm:w-1/2">
                                        <div className="relative m-2">
                                            <input 
                                                className="sr-only peer" 
                                                type="radio" 
                                                id="is_admin" 
                                                value={ 1 } 
                                                {...register("is_admin", { 
                                                    required: "Seleccione una opción"
                                                })}
                                            />
                                            <label className="form-radio" htmlFor="is_admin">Administrador</label>
                                            <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2">
                                        <div className="relative m-2">
                                            <input 
                                                className="sr-only peer" 
                                                type="radio" 
                                                id="not_admin" 
                                                value={ 0 } 
                                                {...register("is_admin", { 
                                                    required: "Seleccione una opción"
                                                })}
                                            />
                                            <label className="form-radio" htmlFor="not_admin">Editor</label>
                                            <div className="absolute hidden w-5 h-5 peer-checked:block right-3 top-1/3">
                                                <FontAwesomeIcon className="text-white" icon="fa-solid fa-circle-check" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-3">
                                    <ErrorMessage
                                        errors={ errors }
                                        name="is_admin"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                            <div className="p-2 xl:w-1/3 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="type" className="form-label">Seleccion&nbsp;<span className="text-error">*</span></label>
                                    <select 
                                        id="type" 
                                        className="form-select"
                                        defaultValue={ "null" }
                                        {...register("type", {
                                            required: "Seleccione una opcion",
                                            validate: (value) => {
                                                if (value === "null") {
                                                    return "Seleccione una opcion";
                                                }
                                            },
                                        })}
                                    >
                                        <option hidden disabled selected value="null">[Seleccione una opción]</option>    
                                        <option value="1">Opcion 1</option>    
                                        <option value="1">Opcion 2</option>    
                                        <option value="1">Opcion 3</option>    
                                    </select>
                                    <ErrorMessage
                                        errors={ errors }
                                        name="type"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-2 w-full">
                            <div className="relative mb-2">
                                <div className="flex justify-center">
                                    <label htmlFor="coordinates_manual" className="flex items-center cursor-pointer relative">
                                        <input type="checkbox" id="coordinates_manual" 
                                            className="sr-only"
                                            {...register("coordinates_manual")}
                                            onChange={ activeCheckbox }
                                        />
                                        <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                                        <span className="ml-3 text-dark text-base font-medium">{ isActive === true ? "Activo" : "Inactivo" }</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8 flex justify-end">
                            <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Ingresar</button>
                        </div>
                    </form>          
                </div>
            </section>
        </div>
    );
};

export default Forms;
