import { Fragment } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faEye,
	faEyeSlash,
	faChartColumn,
	faFileExport,
	faUsers,
	faFile,
	faIdCard,
	faPowerOff,
	faCircleCheck,
	faPlus,
	faArrowLeft,
	faFilePdf,
	faFileExcel,
	faPen,
	faTrash,
	faEarthAmericas,
	faBusinessTime,
	faCity,
	faBuilding,
	faList,
	faTrophy,
	faMap,
	faLanguage,
	faMoneyBillTrendUp,
	faCrown,
	faRocket,
	faCreditCard,
	faCircleInfo,
	faBuildingCircleCheck,
} from '@fortawesome/free-solid-svg-icons';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Layout components
import Sidebar from './layout/Sidebar';
import Navbar from './layout/Navbar';

// Auth views
import Login from './views/auth/Login';

// Home views
import Dashboard from './views/home/Dashboard';

// Users
import Usuarios from './views/users/Usuarios';
import UpdateUser from "./views/users/UpdateUser";
import InsertUser   from "./views/users/InsertUser";

// Knowledges
import Knowledges from "./views/knowloedges/knowledges";
import InsertKnowledges from "./views/knowloedges/insertKnowledges";
import UpdateKnowledges from "./views/knowloedges/updateKnowledges";

// States
import States from "./views/states/states";
import InsertState from "./views/states/insertState";
import UpdateState from "./views/states/updateState";

// Projects
import Projects from "./views/projects/projects";
import DetailProject from "./views/projects/detailProject";

// Reports
import Reports from "./views/reports/reports";
import General from "./views/reports/general";
import ReportsFreelancers from "./views/reports/reportsFreelancers";
import ReportsCompanys from "./views/reports/reportsCompanys";
import ReportsFreelancersSpecialization from "./views/reports/reportsFreelancersSpecialization";
import ReportsCompanysActEco from "./views/reports/reportsCompanysActEco";
import ReportsProyectsCompanys from "./views/reports/reportsProyectsCompany";
import ReportsActivityMoreCompanys from "./views/reports/reportsActivityEconomicMoreCompanys";
import ReportsDeptoMoreFrelancers from "./views/reports/reportsDeptoMoreFreelancers";
import ReportsCountryMoreCompanys from "./views/reports/reportsCountryMoreCompanys";

// Error views
import NotFound from './views/errors/NotFound';

//Countries views
import Countries from './views/countries/Countries';
import Country from './views/countries/Country';
import NewCountry from './views/countries/NewCountry';

//Cities views
import Cities from './views/cities/Cities';
import City from './views/cities/City';

// Docs
import Forms from './views/docs/Forms';
import Buttons from './views/docs/Buttons';
import Tables from './views/docs/Tables';

// Lenguages
import Languages from './views/languages/Languages';
import Language from './views/languages/Language';
import NewLanguage from './views/languages/NewLanguage';

//Economic Activities
import EconomicActivities from './views/economicActivities/EconomicActivities';
import EconomicActivity from './views/economicActivities/EconomicActivity';
import NewActivity from './views/economicActivities/NewActivity';

//Business Plans
import BusinessPlans from './views/plans/Plans';
import BusinessPlan from './views/plans/Plan';
import NewPlan from './views/plans/NewPlan';
import PlanAssignment from './views/plans/planAssignment';
import PlanAssignmentCompany from './views/plans/planAssignmentCompany';

import './assets/css/App.css';
import Empresa from './views/empresa/Empresa';
import ListFreelancer from './views/freelancers/ListFreelancer';
import Specialties from './views/specialities/Specialties';
import VerFreelancer from './views/freelancers/VerFreelancer';

import AddSpecialties from './views/specialities/addSpecialties';
import EditSpecialties from './views/specialities/updatedSpeciality';

//payments
import Payments from './views/payments/Payments';
import Payment from './views/payments/Payment';
import InformacionEmpresa from './views/empresa/informacionEmpresa';

ChartJS.register(ArcElement, Tooltip, Legend);
library.add(faBuildingCircleCheck,faEyeSlash, faEye, faChartColumn, faFileExport, faUsers, faIdCard, faPowerOff, faCircleCheck, faPlus, faArrowLeft, faFileExcel, faFilePdf, faPen, faTrash,faEarthAmericas, faFile, faBusinessTime, faCity, faBuilding, faList, faTrophy, faMap,faLanguage,faMoneyBillTrendUp, faArrowLeft,faCrown,faRocket,faCreditCard,faCircleInfo);

const App = () => {
	const location = useLocation();

	return (
		<div id="App">
			<div className="flex">
				{ (location.pathname !== "/" && location.pathname !== '/not-found') && (
					<Sidebar />
				) }
				<div className={ `h-screen ${ location.pathname === "/" ? "flex-1" : "w-full min-w-full max-w-full" }` } >
					{ (location.pathname !== "/" && location.pathname !== '/not-found') && (
						<Navbar />
					)}
					<div className={ location.pathname !== "/" ? "sm:px-6 px-0 pb-6 pt-20 2xl:ml-72 2xl:mr-64 md:ml-30 md:mr-15 sm:ml-28 sm:mr-14 ml-9 mr-9" : "" } >
						<Fragment>
							<Routes>
								<Route path="/" element={ <Login /> } />
								<Route path="/dashboard" element={ <Dashboard /> } />
								<Route path="/forms" element={ <Forms /> } />
								<Route path="/buttons" element={ <Buttons /> } />
								<Route path="/tables" element={ <Tables /> } />
								<Route path="/tables2" element={ <Tables /> } />

								<Route path="/countries" element={ <Countries /> } />
								<Route path="/newCountry" element={ <NewCountry /> } />
								<Route path="/country/:id" element={ <Country /> } />

								<Route path="/Empresa" element={ <Empresa /> } />
								<Route path="/informacion-empresa/:id" element={ <InformacionEmpresa /> } />
								
								<Route path="/Especialidades" element={ <Specialties/> } />
								<Route path="/agregar-especialidad" element={ <AddSpecialties/> } />
								<Route path="/editar-especialidad/:id" element={ <EditSpecialties /> } />

								<Route path="/not-found" element={ <NotFound /> } />
								<Route path="*" element={ <Navigate to='/not-found' replace /> } />

								<Route path="/usuarios" element={ <Usuarios /> } />
								<Route path="/ingresar-usuario" element={ <InsertUser /> } />
								<Route path="/usuario/:id" element={ <UpdateUser /> } />

								<Route path="/conocimientos" element={ <Knowledges /> } />
								<Route path="/ingresar-conocimiento" element={ <InsertKnowledges /> } />
								<Route path="/actualizar-conocimiento/:id" element={ <UpdateKnowledges /> } />

								<Route path="/estados" element={ <States /> } />
								<Route path="/ingresar-estado" element={ <InsertState /> } />
								<Route path="/estado/:id" element={ <UpdateState /> } />

								<Route path="/reportes" element={ <Reports /> }>
									<Route path="general" element={ <General /> } />
									<Route path="reportes-freelancers" element={ <ReportsFreelancers /> } />
									<Route path="reportes-freelancers-por-especializacion" element={ <ReportsFreelancersSpecialization /> } />
									<Route path="reportes-empresas" element={ <ReportsCompanys /> } />
									<Route path="reportes-empresas-por-actividad-economica" element={ <ReportsCompanysActEco /> } />
									<Route path="reportes-proyectos-por-empresa" element={ <ReportsProyectsCompanys /> } />
									<Route path="reportes-actividad-economica-con-mas-empresas" element={ <ReportsActivityMoreCompanys /> } />
									<Route path="reportes-departamentos-con-mas-freelancers" element={ <ReportsDeptoMoreFrelancers /> } />
									<Route path="reportes-paises-con-mas-empresas" element={ <ReportsCountryMoreCompanys /> } />
								</Route>

								<Route path="/proyectos" element={ <Projects /> } />
								<Route path="/detalle-de-proyecto/:id" element={ <DetailProject /> } />

								<Route path="/cities" element={ <Cities /> } />
								<Route path="/city/:id" element={ <City /> } />

								<Route path="/ListFreelancer" element={ <ListFreelancer /> } />
								<Route path="/VerFreelancer/:id" element={ <VerFreelancer /> } />

								<Route path="/languages" element={ <Languages /> } />
								<Route path="/language/:id" element={ <Language /> } />
								<Route path="/newLanguage" element={ <NewLanguage /> } />

								<Route path="/economicActivities" element={ <EconomicActivities /> } />
								<Route path="/economicActivity/:id" element={ <EconomicActivity /> } />
								<Route path="/newActivity" element={ <NewActivity /> } />

								<Route path="/businessPlans" element={ <BusinessPlans /> } />
								<Route path="/businessPlan/:id" element={ <BusinessPlan /> } />
								<Route path="/newBusinessPlan" element={ <NewPlan /> } />
								<Route path="/asignacion-planes" element={ <PlanAssignment /> } />
								<Route path="/asignacion-planes/:id" element={ <PlanAssignmentCompany /> } />

								<Route path="/payments" element={ <Payments /> } />
								<Route path="/payment/:id" element={ <Payment /> } />
							</Routes>
						</Fragment>
					</div>	
				</div>
			</div>
		</div>
	);
}

export default App;
