/* 
    En este archivo manejo los servicios de la API de los pagos pendientes de los freelancers.
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

console.log(Cookies); 

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token
    }
}

// let configToken = {
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
//     }
// }

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const getPayments = async () => {
    const response = await axios.get(endPoints.payments.getPayments,config);
    return response.data;
}

const getPayment = async (id) => {
    const response = await axios.get(endPoints.payments.getPayment(id),config);
    return response;
}

const cancelPayment = async (id) => {
    const response = await axios.post(endPoints.payments.cancelPayment(id),{},config);
    return response;
}

export { getPayments, getPayment, cancelPayment };