import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BackIcon() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  
  return (
    <>
      <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto" onClick={goBack}>
        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
      </button>
    </>
  );
}
