import { useParams, Link } from 'react-router-dom';
import axios, { HttpStatusCode } from "axios";
import { useEffect, useState } from 'react';

import Swal from "sweetalert2";

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpdateState = () => {
    const { id } = useParams();

    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();


    const [countrys, setCountrys] = useState([]);

    const getCountrys = async () => {
        const URL = `${process.env.REACT_APP_API_URI}country`;
        console.log(URL);
        const response = await axios.get(URL);
        console.log(response.data);
        setCountrys(response.data);
    }


    const getState = async () => {

        const URL = `${process.env.REACT_APP_API_URI}state/get/${id}`;
        console.log(URL);
        const response = await axios.get(URL);
        console.log(response.data);

        let defaultValues = {
            id: response.data.id,
            name: response.data.name,
            country_id: response.data.country.id
        };
        reset(defaultValues);
    }

    useEffect(() => {
        getCountrys();
        getState();
    }, []);

    const sendData = (data) => {
        console.log(data);
        let id = data.id;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        let uri = `${process.env.REACT_APP_API_URI}state/${id}`;
        const response = axios.put(uri, data, config);

        response.then((res) => {
            console.log(res.data.message);
            Swal.fire(res.data.message, "", "success");
            // reset();
        })

        response.catch((error) => {
            Swal.fire(error.response.data.message, "", "error");
            console.log("ERROR RESPONSE ", error.response.data.message);
            console.log('ERROR REQUEST', error.request);
            console.log('ERROR: ', error.message);
        });

        console.log(response.data);
    }

    return (
        <>
            <div id="UpdateState">
                <div className="text-center pt-5">
                    <div id="Forms">
                        <div className="pt-5 flex items-center justify-center md:justify-start mb-6">
                            <Link to={'/estados'} className="">
                                <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                </button>
                            </Link>
                            <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-3xl font-bold text-dark">Estado</h3>
                            </div>
                        </div>
                        <section>
                            <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                                <form id="basic-form" onSubmit={handleSubmit(sendData)}>
                                    <div className="w-full mb-4">
                                        <h2 className="text-base font-normal">
                                            Los campos con (&nbsp;<span className="text-error font-bold">*</span>&nbsp;) son requeridos.
                                        </h2>
                                    </div>
                                    <div className="flex flex-wrap -m-2">
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="names" className="form-label">Nombre&nbsp;<span className="text-error">*</span></label>
                                                <input type="text"
                                                    id="names"
                                                    className="form-input"
                                                    autoComplete="nope"
                                                    {...register("name", {
                                                        required: "El nombre es requerido"
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="name"
                                                    render={({ message }) => <span className="error-message">{message}</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-2 w-full lg:w-1/2">
                                            <div className="relative mb-2">
                                                <label htmlFor="specialization_types_id" className="form-label">País&nbsp;<span className="text-error">*</span></label>
                                                <select
                                                    id="specialization_types_id"
                                                    className="form-select"
                                                    defaultValue={"null"}
                                                    {...register("country_id", {
                                                        required: "Seleccione una opcion",
                                                        validate: (value) => {
                                                            if (value === "null") {
                                                                return "Seleccione una opcion";
                                                            }
                                                        },
                                                    })}
                                                >
                                                    <option hidden disabled selected value="null">Seleccione un país</option>
                                                    {countrys.map((coun, i) => (
                                                        <option value={coun.id} key={i}>{coun.name}</option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="country_id"
                                                    render={({ message }) => <span className="error-message">{message}</span>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full pt-8 flex justify-end">
                                        <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Actualizar</button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateState;