import { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCurrentYear } from '../../utils/utils';

import WaitingResponse from '../../layout/WaitingResponse';

import Brand from '../../assets/images/brand.png';

import Cookies from 'js-cookie'
import axios from 'axios';
import Swal from 'sweetalert2';


 const generateAlert = (data) => {
    Swal.fire({
        position: 'center',
        icon: data.type,
        title: data.message,
        showConfirmButton: false,
        timer: 1000
      })
};

const Login = () => {
    const pattern = /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
    const { register, handleSubmit, formState: { errors }, reset, clearErrors } = useForm();

    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordCount, setPasswordCount] = useState(0);
    const [passwordValue, setPasswordValue] = useState(null);

    const [waitingLogin, setWaitingLogin] = useState(false);

    const blockActions = useCallback(() => {
        let block = document.getElementById("ShowPassword");
        
        block.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            return false;
        });

        block.addEventListener('mousedown', (e) => {
            e.preventDefault();
            return false;
        });

        block.addEventListener('selectstart', (e) => {
            e.preventDefault();
            return false;
        });
    }, []);

    const togglePassword = () => {
        setPasswordShow(!passwordShow);
    };

    const contentPassword = (e) => {
        let inputContent = e.target.value;
        let passwordContent = inputContent.replace(/\s+/g, '');

        setPasswordCount(passwordContent.length);
        setPasswordValue(passwordContent);     
    };

    const logIn = (data) => {
        // Activa icono de espera mientras espera la petición
        setWaitingLogin(true);
        
        const url = `${process.env.REACT_APP_API_URI}auth/login`;
        let config = {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        };

        axios.post(url, data ,config)
        .then(response =>{

            const token = response.data.access_token;
            const type = response.data.type;

            const expires = response.data.expires_at;
            const name = response.data.token_type;

            const result = {
                type: 'success',
                message: "Bienvenido"
            };
            generateAlert(result);

            setTimeout(() => {
                let userInformation = JSON.stringify({ token:token, type:type });

                Cookies.set(name, userInformation, {path: "/", expires: new Date(expires), secure:true});
                window.location.replace('/dashboard');

            }, 2000);



        }).catch(err => {

            console.log(err.response.data.message);

            const result = {
                type: 'error',
                message: err.response.data.message
            };
            generateAlert(result);

        }

        )
        // Desactiva icono de espera mientras espera la petición
        setWaitingLogin(false);
        
    };

    return (
        <div id="Login" className="login">
            <div className="relative flex flex-col flex-wrap justify-center items-center min-h-screen overflow-hidden md:p-2 sm:px-32 px-4">
                <div className="w-full p-6 mx-auto mt-auto bg-white border-t-4 border-orange rounded-md  border-top md:max-w-sm">
                    <div className="flex justify-center">
                        <img
                            src={ Brand }
                            className=""
                            width="70%"
                            alt=""
                        />
                    </div>
                    <h1 className="mt-5 text-center font-bold text-2xl text-dark">Iniciar sesión</h1>
                    <form onSubmit={ handleSubmit(logIn) } className="mt-6">
                        <div>
                            <label htmlFor="email" className="block text-base font-semibold text-dark">Correo electrónico</label>
                            <input type="email" id="email" className="login-input border-2 rounded-md" autoComplete="nope" placeholder="Correo electrónico"
                                {...register("email", {
                                    required: "El correo electrónico es requerido",
                                    pattern: {
                                        value: pattern,
                                        message: "El correo electrónico es inválido"
                                    }
                                })}
                            />
                            <ErrorMessage
                                errors={ errors }
                                name="email"
                                render={({ message }) => <span className="error-message">{ message }</span>}
                            />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="password" className="block text-base font-semibold text-dark">Contraseña</label>
                            <div className="flex justify-around">
                                <input type="password" id="password" onKeyUp={ contentPassword } className="login-input border-2 rounded-md mr-1" autoComplete="nope" placeholder="Contraseña"
                                    {...register("password", { 
                                        required: "La contraseña es requerida"
                                    })}
                                />
                                <button type="button" onClick={ togglePassword } className="min-w-1/6 max-w-1/6 w-1/6 transition-colors duration-200 ease-in-out px-4 py-2 mt-2 border-2 show-password bg-gray-500 border-gray-500 hover:bg-gray-800 hover:border-gray-800 inline-flex justify-center items-center">
                                    <FontAwesomeIcon size="1x" icon={ `fa-solid ${ !passwordShow ? 'fa-eye' : 'fa-eye-slash' }` } />
                                </button>
                            </div>
                            <ErrorMessage
                                errors={ errors }
                                name="password"
                                render={({ message }) => <span className="error-message">{ message }</span>}
                            />
                        </div>
                        <div id="ShowPassword" >
                            { (passwordShow === true && passwordCount > 0) && (
                                <div className="px-6 py-2 mt-2 bg-lightblue text-white rounded-full font-bold break-all" >
                                    { passwordValue }
                                </div>
                            ) }
                        </div>
                        {/* <a href="#" className="text-xs text-gray-600 hover:underline">Forget Password?</a> */}
                        <div className="mt-6 text-center">
                            <button type="submit" className="w-full inline-flex items-center justify-center px-4 py-2 tracking-wide text-white transition-colors duration-200 font-bold transform bg-orange-500 rounded-lg hover:bg-dark-orange focus:outline-none focus:bg-dark-orange disabled:cursor-not-allowed" disabled={ !waitingLogin ? false : true }>
                                { !waitingLogin ? 'Iniciar sesión' : <> <WaitingResponse/> Procesando...</> }
                            </button>
                        </div>
                    </form>
                </div>
                <div className="w-full p-4 mx-auto mb-auto mt-5 rounded-md text-sm text-center md:max-w-sm bg-theme text-white">
                    <span>&copy; { getCurrentYear() }</span>
                    <br />
                    <p>
                        Hecho en El Salvador por&nbsp;<a className="font-bold text-orange-500 transition-colors hover:text-dark-orange" href="https://conexion.sv" target="_blank" rel="noopener noreferrer">CONEXION</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
