import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faPenToSquare, faFolderPlus, faFilePen, faMinus } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

const initialStateWorkExperience = {
   cargo: "",
   created_at: "",
   descripcion_puesto: "",
   fecha_fin: "",
   fecha_inicio: "",
   id: 0,
   nombre_empresa: "",
   updated_at: "",
   user_id: 0,
   actual_work: 0
}

const WorkExperience = ({ idFreelancer }) => {

   const idFreelancerI = idFreelancer;

   const [workExperiences, setWorkExperience] = useState([initialStateWorkExperience]);

   const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

   function getDate(date) {

      const formatDate = date;
      var partesFecha = formatDate.split("-");
      // console.log(partesFecha);

      const monthR = (Number(partesFecha[1]) - 1);
      const dateWorkExperiences = monthNames[monthR] + ' ' + partesFecha[0];

      return dateWorkExperiences;

   };

   useEffect(() => {
      async function getWorkExperience() {


         let urlWorkExperience = `${process.env.REACT_APP_API_URI}freelancer/list/work-experience/${idFreelancerI}`;

         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };

         try {
            const resp = await axios.get(urlWorkExperience, config);

            const dataExperienceFormal = resp.data.data;

            console.log("dataExperienceFormal ==>", dataExperienceFormal);



            const experienceFormal = dataExperienceFormal.filter((element) => {
               return element.work_experience_type == 1;
            });

            setWorkExperience(experienceFormal);

         } catch (err) {
            // Handle Error Here
            console.error(err);
         };



      }
      getWorkExperience();
   }, [])


   return (
      <>
         <div className={`text-white`} id="contenedorExperienciaLaboral" role="tabpanel" aria-labelledby="settings-tab">
            <div className="flex flex-wrap">
               {
                  (workExperiences.length !== 0) ?
                     workExperiences.map((workExperience, key) => {
                        return (
                           (workExperience.nombre_empresa != "" &&
                              <>
                                 <div className="w-full sm:w-1/2 xl:w-1/3 p-5">
                                    <div className="bg-Light-purple p-6 rounded-md shadow-md h-full flex">
                                       <div className="flex flex-wrap justify-between items-center mb-4 sm:mb-0">
                                          <div className="flex flex-wrap justify-center md:justify-start items-center w-full md:w-1/1 order-2 md:order-1 mt-5 md:mt-0">
                                             <div className="text-lg font-semibold text-white w-full pr-4">
                                                {workExperience.nombre_empresa}
                                             </div>
                                             <div className="text-sm text-white w-full pr-4">
                                                {workExperience.cargo}
                                             </div>
                                             <div className="text-sm text-white w-full pr-4">
                                                {workExperience.descripcion_puesto.length > 100 ? workExperience.descripcion_puesto.substring(0, 100) + "..." : workExperience.descripcion_puesto}
                                             </div>
                                             <div className="text-xs text-orange-500 w-full pr-4 text-start my-2">
                                                {workExperience.actual_work === 2 ?
                                                   <>Desde: {getDate(workExperience.fecha_inicio)} / {getDate(workExperience.fecha_fin)} </>
                                                   : workExperience.actual_work === 1 &&
                                                   <div>
                                                      <h5 className="text-orange-500 text-start text-xs">{getDate(workExperience.fecha_inicio)} / actualidad </h5>
                                                   </div>
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )
                        )
                     })
                     :
                     <p className="text-center w-full">No se encontraron experiencias laborales registradas</p>
               }

            </div>
         </div>
      </>
   )
}

export default WorkExperience