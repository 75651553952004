import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackIcon from "../../components/BackIcon";

function EditSpecialties() {
  const pattern =
    /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [MostrarImg, setMostrarImg] = useState("second");

  const [data, setData] = useState({
    name: "...",
    icon: "...",
    id: "...",
  });

  const [isActive, setIsActive] = useState(false);
  const activeCheckbox = () => {
    setIsActive(!isActive);
  };

  const [InfoSpecialty, setInfoSpecialty] = useState({ name: "", id: "" });

  const params = useParams();

  const sendData = async (data) => {
    console.log(data);
    let newData;
    let config;
    let urlRegisterSpecialty;
    if (!isActive) {
      urlRegisterSpecialty = `${process.env.REACT_APP_API_URI}specialization-type/${data.id}`;

      config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      newData = { name: data.name, id: data.id };

      try {
        const resp = await axios.put(urlRegisterSpecialty, newData, config);
        console.log(resp);

        Swal.fire("Especialidad actualizada exitosamente", "", "success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        //  Swal.fire('as', '', 'error')
      }
    } else {
      urlRegisterSpecialty = `${process.env.REACT_APP_API_URI}specialization-type/${data.id}?_method=PUT`;

      config = {
        headers: {
          "Content-Type": "multipart/form-data",
          // 'Content-Type': 'application/json',
          Accept: "application/json",
        },
      };
      newData = { icon: data.icon[0], id: data.id, name: data.name };

      try {
        console.log(newData, config);
        const resp = await axios.post(urlRegisterSpecialty, newData, config);
        console.log(resp);

        Swal.fire("Especialidad actualizada exitosamente", "", "success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        //  Swal.fire('as', '', 'error')
      }
    }
  };

  useEffect(() => {
    async function getSpecialties() {
      const urlCountry = `${process.env.REACT_APP_API_URI}specialization-type/${params.id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const resp = await axios.get(urlCountry, config);
        console.log(resp.data);

        let imgCompany = "";

        if (resp.data.icon == null || resp.data.icon == "") {
          imgCompany = "";
        } else {
          imgCompany = `${process.env.REACT_APP_PHOTO_URI}specialization-icon/${resp.data.icon}`;
        }

        setMostrarImg(imgCompany);
        reset(resp.data);
        setData(resp.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
    getSpecialties();
  }, []);

  return (
    <div id="Forms">
      <div className="pt-5 flex items-center justify-center mb-6">
        <div>
          <BackIcon />
        </div>
        <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-3xl font-bold text-dark">Editar Especialidad </h3>
        </div>
      </div>
      <section>
        <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
          <form id="basic-form" onSubmit={handleSubmit(sendData)}>
            <div className="w-full mb-4">
              <h2 className="text-base font-normal">
                Los campos con&nbsp;
                <span className="text-error font-bold">*</span>&nbsp;son
                requeridos.
              </h2>
            </div>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <label htmlFor="name" className="form-label">
                    Nombre&nbsp;<span className="text-error">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-input"
                    autoComplete="nope"
                    {...register("name", {
                      required: "Nombre requerido",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="Name"
                    render={({ message }) => (
                      <span className="error-message">{message}</span>
                    )}
                  />
                </div>
              </div>

              <div className="p-2 xl:w-1/2 w-full">
                <div className="relative mb-2">
                  <div className="flex justify-center">
                    <label
                      htmlFor="coordinates_manual"
                      className="flex items-center cursor-pointer relative"
                    >
                      <input
                        type="checkbox"
                        id="coordinates_manual"
                        className="sr-only"
                        {...register("coordinates_manual")}
                        onChange={activeCheckbox}
                      />
                      <div className="toggle-bg bg-gray-400 border-2 border-gray-400 h-6 w-11 rounded-full"></div>
                      <span className="ml-3 text-dark text-base font-medium">
                        {isActive === true ? "Cambiar icono" : "Cambiar icono"}
                      </span>
                    </label>
                  </div>
                  <div className="mt-3">
                    {isActive ? (
                      <div className="relative flex justify-around mb-2">
                        <label htmlFor="icon" className="form-label">
                          Icono&nbsp;<span className="text-error">*</span>
                        </label>
                        <input
                          type="file"
                          id="icon"
                          className="form-label pt-1"
                          autoComplete="nope"
                          {...register("icon", {
                            required: "Icono requerido",
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="icon"
                          render={({ message }) => (
                            <span className="error-message">{message}</span>
                          )}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <img
                          className="w-48 h-48 border-8 rounded-full object-cover align-middle border-Yellow flex justify-center"
                          src={MostrarImg}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="p-2 xl:w-1/2 w-full">
                                <div className="relative mb-2">
                                    <label htmlFor="icono" className="form-label">Icono&nbsp;<span className="text-error">*</span></label>
                                    <input type="file" id="icono" className="form-label pt-1" autoComplete="nope"
                                        {...register("icono", {
                                            required: "Icono requerido"
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={ errors }
                                        name="icono"
                                        render={({ message }) => <span className="error-message">{ message }</span>}
                                    />
                                </div>
                            </div> */}
            </div>
            <div className="w-full pt-8 flex justify-end">
              <button
                type="submit"
                className="btn bg-lightblue hover:bg-dark-lightblue"
              >
                Actualizar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default EditSpecialties;
