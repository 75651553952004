import FormCountry from "../../components/Formularios/FormCountry"
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCountry } from "../../services/api/country";
import { useNavigate } from "react-router-dom";

export default function Country() {
  const { id } = useParams();
  const [country, setCountry] = useState([]);
  const navigate = useNavigate();
  // Use the useEffect hook to fetch the country data when the component is first rendered
  useEffect(() => {
    getCountry(id)
      // If the request is successful, update the country state variable with the response data
      .then((response) => {
        setCountry(response.data);
      })
      // If an error occurs or if the country with the given id is not found, navigate to the "not-found" page
      .catch(() => {
        navigate("/not-found");
      });
  }, [id, navigate]);
  return (
    <>
      {country && (<FormCountry country={country} />)}
    </>
  )
}
