import Swal from "sweetalert2";
import FormActivity from "../../components/Formularios/FormActivity";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getEconomicActivity } from "../../services/api/economicActivities";

export default function EconomicActivity() {
  const { id } = useParams();
  const [activity, setActivity] = useState({});

  const obtenerActividad = (id) => {
    getEconomicActivity(id)
      .then((response) => {
        setActivity(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: `${error.response.data.message}`,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    obtenerActividad(id);
  }, [id]);

  return <>{activity && <FormActivity activity={activity} />}</>;
}
