import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { paginationProps } from "../../utils/utils";

const States = () => {
  const pattern =
    /^(([^<>().,;:\s@”]+(\.[^<>().,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;

  const {
    register,
    formState: { errors },
    reset,
  } = useForm();

  const [data, setData] = useState([]);

  const [search, setSearch] = useState([]);

  const [countrys, setCountrys] = useState([]);

  const [messageDatatable, setMessagesDatatable] = useState("Cargando...");

  const getState = async () => {
    const URL = `${process.env.REACT_APP_API_URI}state`;
    const result = await axios.get(URL);
    let sizeData = result.data.length;
    changeDatatableMessages(sizeData);
    setSearch(result.data);
    setData(result.data);
  };

  const getCountrys = async () => {
    const URL = `${process.env.REACT_APP_API_URI}country`;
    const response = await axios.get(URL);
    setCountrys(response.data);
  };

  useEffect(() => {
    getState();
    getCountrys();
  }, []);

  const changeCountry = (e) => {
    let id = e.target.value;
    console.log(e.target.value);
    if (id == "alls") {
      getState();
    } else {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(Cookies.get("Bearer")).token}`,
        },
      };
      const URL = `${process.env.REACT_APP_API_URI}state/${id}`;
      const response = axios.get(URL, config);
      response.then((res) => {
        console.log(res.data);
        let sizeData = res.data.length;
        changeDatatableMessages(sizeData);
        setSearch(res.data);
        setData(res.data);
      });
      response.catch((error) => {
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });
        let defaultValue = {
          country_id: "alls",
        };
        reset(defaultValue);
        console.log("ERROR RESPONSE ", error.response.data);
        console.log("ERROR " + error.message);
        console.log("ERROR REQUEST " + error.request);
      });
      console.log(response.data);
    }
  };

  const changeDatatableMessages = (sizeData) => {
    if (sizeData < 1) {
      setMessagesDatatable("No se encontraron registros");
    }
  };

  const changeSearch = (e) => {
    filterData(e.target.value);
  };

  const filterData = async (termSearch) => {
    var srch = data.filter((item) => {
      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(termSearch.toString().toLowerCase())
      ) {
        return item;
      }
      console.log(item);
    });
    let sizeData = srch.length;
    changeDatatableMessages(sizeData);
    await setSearch(srch);
  };

  const deletState = (id) => {
    console.log(id);
    const action = 2;
    const URL = `${process.env.REACT_APP_API_URI}state/${id}`;
    console.log(URL);
    const response = axios.delete(URL);

    response.then((res) => {
      console.log(res.data);
    });
    response.catch((error) => {
      console.log("ERROR RESPONSE ", error.response.data);
      console.log("ERROR ", error.message);
      console.log("ERROR REQUEST ", error.request);
    });
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Borrando Conocimiento...",
      text: "¿Esta seguro de querer borrar este conocimiento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deletState(id);
        Swal.fire({
          title: "Borrado exitoso",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            window.location = "/estados";
          }
        });
      }
    });
  };

  const columns = [
    {
      name: "#",
      width: "15%",
      cell: (row) => <b>{row.id}</b>,
    },
    {
      name: "Estado",
      width: "25%",
      cell: (row) => (
        <div className="group">
          <span>{`${row.name}`}</span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.name}</span> */}
          </div>
        </div>
      ),
    },
    {
      name: "País",
      width: "20%",
      cell: (row) => (
        <div className="group">
          <span>{`${row.country.name}`}</span>
          <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
            {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.lastname}</span> */}
          </div>
        </div>
      ),
    },
    {
      name: "Ver / Editar",
      width: "20%",
      cell: (row) => (
        <div>
          <Link to={"/estado/" + row.id}>
            <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue">
              <FontAwesomeIcon icon="fa-solid fa-pen" />
            </button>
          </Link>
        </div>
      ),
    },
    {
      name: "Borrar",
      width: "20%",
      cell: (row) => (
        <div>
          <button
            className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700"
            onClick={() => deleteItem(row.id)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div id="AttentionSchedules">
      <div className="text-center pt-5 flex justify-center items-center">
        <h1 className="text-3xl sm:text-4xl font-bold mx-4">Estados</h1>
        <Link to={"/ingresar-estado"} className="">
          <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </button>
        </Link>
      </div>
      <section className="table-results">
        <div className="table-auto">
          <div className="flex flex-wrap">
            <div className="p-2 w-full lg:w-1/3">
              <div className="relative mb-2">
                <label htmlFor="search" className="form-label">
                  Búsqueda
                </label>
                <input
                  type="text"
                  id="search"
                  className="form-input py-1"
                  autoComplete="none"
                  placeholder="Buscar..."
                  onChange={changeSearch}
                />
              </div>
            </div>
            <div className="p-2 w-full lg:w-1/3">
              <div className="relative mb-2">
                <label htmlFor="country_id" className="form-label">
                  País
                </label>
                <select
                  id="country_id"
                  className="form-select py-2.5"
                  defaultValue={"null"}
                  {...register("country_id", {
                    required: "Seleccione una opcion",
                    validate: (value) => {
                      if (value === "null") {
                        return "Seleccione un opcion";
                      }
                    },
                  })}
                  onChange={changeCountry}
                >
                  <option selected value="alls">
                    Todos
                  </option>
                  {countrys.map((coun, i) => (
                    <option value={coun.id} key={i}>
                      {coun.name}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  errors={errors}
                  name="country_id"
                  render={({ message }) => (
                    <span className="error-message">{message}</span>
                  )}
                />
              </div>
            </div>
          </div>
          <DataTable
            pagination
            columns={columns}
            data={search}
            dense
            striped
            responsive
            paginationComponentOptions={paginationProps}
            noDataComponent={<span className="p-4">{messageDatatable}</span>}
          />
        </div>
      </section>
    </div>
  );
};

export default States;
