import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

import exportFromJSON from 'export-from-json';

const ReportsProyectsCompanies = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[nameFile, setNameFile] = useState('todos');

    const[exportState, setExportState] = useState(true);

    const [companys, setCompanys] = useState([]);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');
    
    useEffect(() => {
        getProyectsAlls();
        getCompanys();
    }, []);

    const getProyectsAlls = async () => {
        const URL = `${process.env.REACT_APP_API_URI}project`;
        const result = await axios.get(URL);

        console.log(result.data);
        let sizeData = result.data.length;
        changeDatatableMessages(sizeData);
        setDataArray(result.data);
        setSearch(result.data);
        setData(result.data);
    }

    const getProyectsCompany = async (id) => {
        const URL = `${process.env.REACT_APP_API_URI}report/projects-company/${id}`;
        const result = await axios.get(URL);
        console.log(result.data.records);
        let sizeData = result.data.records.length;
        changeDatatableMessages(sizeData);
        setDataArray(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
        setExportState(false);
        setColumnsDatatable(columns2);
    }

    const getCompanys = async () => {
        const URL = `${process.env.REACT_APP_API_URI}company`;
        console.log(URL);
        const response = await axios.get(URL);
        console.log(response.data);
        if (response.data.messages == 'No se encontraron empresas.'){
            setCompanys([]);
        }
        else
        {
            setCompanys(response.data);
        }
    }

    const setDataArray = (arr) =>{
        let ii = 1;
        for (var i = 0; i < arr.length; i++) {
            arr[i].id = ii;
            ii++;
        }
        return arr
    }

    const changeCompany = (e) => {
        let id = e.target.value;
        console.log(e.target.value);
        let defaultValue = {
            search: ''
        }
        reset(defaultValue);
        if(id == 'alls'){
            setNameFile('todos');
            setColumnsDatatable(columns);
            getProyectsAlls();
            setExportState(true);
        }
        else{
            let contentOption = e.target[e.target.selectedIndex].text;
            setNameFile(contentOption);
            getProyectsCompany(id);
        }
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        console.log(exportState);
        if(exportState){
            var srch = data.filter((item)=>{
                if(item.project_name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.knowledges_tags.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.publication_date.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.description.toString().toLowerCase().includes(termSearch.toLowerCase())){
                    return item;
                }
                console.log(item);
            });
        }
        else{
            var srch = data.filter((item)=>{
                if(item.name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.tags.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.publication.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.description.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.category.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                item.budget.toString().toLowerCase().includes(termSearch.toLowerCase())){
                    return item;
                }
                console.log(item);
            });
        }
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    var date = new Date();
    const generateFileExcel = () =>{
        
        if  (Array.isArray(search)){
            let info = [];

            if(exportState){
                for (let i = 0; i < search.length; i++) {
                    info.push({
                        "No": search[i].id,
                        "Nombre": search[i].project_name,
                        "Conocimientos": search[i].knowledges_tags,
                        "Fecha de publicación": search[i].publication_date,
                        "Descripción": search[i].description
                    });
                }
            }
            else{
                for (let i = 0; i < search.length; i++) {
                    info.push({
                        "No": search[i].id,
                        "Nombre": search[i].name,
                        "Conocimientos": search[i].tags,
                        "Fecha de publicación": search[i].publication,
                        "Descripción": search[i].description,
                        "Presupuesto": search[i].budget,
                        "Categoría": search[i].category
                    });
                }
            }

            console.log(info);

            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-proyectos-${nameFile}`;
            const exportType = exportFromJSON.types.xls;

            exportFromJSON({data: info, fileName: fileName, exportType: exportType});
        }
        else{
            console.log("No es array");
        }
    };

    var columns = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.project_name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Conocimientos',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.knowledges_tags)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Publicado',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.publication_date)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Descripción',
            width: "500px",
            cell: (row) => <div className="group">
                <span className='text-xs'>{`${(row.description)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        }
    ];

    var columns2 = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.name)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Conocimientos',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.tags)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Publicado',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.publication)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Descripción',
            width: "500px",
            cell: (row) => <div className="group">
                <span className='text-xs'>{`${(row.description)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Presupuesto',
            width: "500px",
            cell: (row) => <div className="group">
                <span>{`${(row.budget)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Categoría',
            width: "500px",
            cell: (row) => <div className="group">
                <span>{`${(row.category)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        }
    ];
    
    const[columnsDatatable, setColumnsDatatable] = useState(columns);

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center md:justify-start items-center">
                <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                    <Link to={'/reportes/general'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        </button>
                    </Link>
                    <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-3xl font-bold text-dark">Reportes proyectos por empresa</h3>
                    </div>
                </div>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap items-end'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    defaultValue={""}
                                    {...register("search")}
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="country_id" className="form-label">Empresa</label>
                                <select
                                    id="country_id"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("country_id", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione un opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeCompany}
                                >
                                    <option selected value="alls">Todas</option>
                                    {companys.map((comp, i) => (
                                        <option value={comp.user_id} key={i}>{comp.company_name}</option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="country_id"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <button onClick={generateFileExcel} className="flex items-center text-white bg-green-800 border-0 h-12 py-2 my-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                <FontAwesomeIcon icon="fa-solid fa-file-export" className='mr-2' />
                                <span className="text-xs text-white">Exportar Excel</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columnsDatatable}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default ReportsProyectsCompanies;