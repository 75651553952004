import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Cookies from 'js-cookie';
import { paginationProps } from '../../utils/utils';
import axios from 'axios';

const ListFreelancers = () => {
    const [data, setData] = useState();

    console.log("datadata==>", data);

    const [loaded, setLoaded] = useState(false);

    var estadoFreelancer = document.getElementById("estadoFreelancer");

    const [idStatusFilter, setidStatusFilter] = useState();

    const [text, setText] = useState();

    const urlData = `${process.env.REACT_APP_API_URI}admin-freelance/list`;

    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    if (Cookies.get('Bearer') !== undefined) {
        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
    }

    // const handleChange = (event) => {
    //     // Update the state with the new value
    //     setidStatusFilter(event.target.value);
    //   };

    const listarPorEstado = (event) => {
        console.log("event.target.value", event.target.value);
        listFreelancer(event.target.value);
    };

    const listFreelancer = (value) => {

        let urlDataPorEstado;

        if (value == undefined || value == "undefined") {
            urlDataPorEstado = `${process.env.REACT_APP_API_URI}admin-freelance/list?numberOfItems=1000`;
        } else {
            urlDataPorEstado = `${process.env.REACT_APP_API_URI}admin-freelance/list?status=${value}&filter=${0}&numberOfItems=100`;
        };


        axios.get(urlDataPorEstado, config)
            .then(response => {
                console.log('List freelancer', response);

                if (!response.data.message) {
                    setData(response.data);
                } else {
                    setData([]);
                };
            })
            .catch(err => {
                console.log(err.response);
            })
        console.log(urlDataPorEstado)
        // fetch(urlDataPorEstado,config)
        // .then((res) => res.json())
        // .then((res) => setData(res.data))
        // .catch((err) => console.log(err))
        // .finally(() => setLoaded(true)); 
    }

    const listEstadoProfileFrelancer = (value) => {

        let estadoProfile;

        if (value == 1) {

            estadoProfile = "En revisión";

        } else if (value == 2) {

            estadoProfile = "Incompleto";

        } else if (value == 3) {

            estadoProfile = "Verificado";

        } else if (value == 4) {

            estadoProfile = "Rechazado";
        }

        return estadoProfile;

    };

    const FormatiarFechaTalento = (fechaUsuario) =>{

        // Convertir la cadena a un objeto Date
        let fecha = new Date(fechaUsuario);

        // Obtener día, mes y año
        let dia = fecha.getDate();
        let mes = fecha.toLocaleString('default', { month: 'long' }); // Obtener nombre del mes
        let año = fecha.getFullYear();

        // Obtener hora, minutos y segundos
        let horas = fecha.getHours();
        let minutos = fecha.getMinutes();
        let segundos = fecha.getSeconds();

        // Formatear la fecha como día, mes y año
        return dia + " " + mes + " de " + año + " " + horas + ":" + minutos + ":" + segundos;  // Ejemplo: "27 de febrero de 2024 09:28:08"

    };

    useEffect(() => {

        listFreelancer();
        // axios.get(urlData, config)
        // .then(response => {
        //     console.log('List freelancer', response);
        // })
        // .catch(err => {
        //     console.log(err.response);
        // })


        // fetch(urlData,config)
        //     .then((res) => res.json())
        //     .then((info) => setData(info.data))
        //     .catch((err) => console.log(err))
        //     .finally(() => setLoaded(true));
    }, []);

    const columns = [
        {
            name: '#',
            width: "65px",
            cell: (row) => <p>{row.user_id}</p>
        },
        {
            name: 'Nombre',
            width: "250px",
            cell: (row) => <p>{row.names}</p>
        },
        {
            name: 'Apellido',
            width: "250px",
            cell: (row) => <p>{row.lastnames}</p>
        },
        {
            name: 'Email',
            width: "250px",
            cell: (row) => <p>{row.email}</p>
        },
        {
            name: 'Ingreso',
            width: "250px",
            cell: (row) => <p> {FormatiarFechaTalento(row.profile_updated_at)}</p>
        },
        {
            name: 'Estado',
            width: "250px",
            cell: (row) => <p>{listEstadoProfileFrelancer(row.complete_profile_status)}</p>
        },
        {
            name: "Ver perfil",
            cell: (row) =>
                <div>
                    <Link to={"/VerFreelancer/" + row.user_id} className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                        <FontAwesomeIcon icon="fa-solid fa-eye" />
                    </Link>
                    {/* {
                        loaded && row.complete_profile_status === 1 
                        &&
                        <Link to={"/VerFreelancer/" + row.user_id} className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                            <FontAwesomeIcon icon="fa-solid fa-eye" />
                        </Link>
                    }{
                        loaded && row.complete_profile_status === 1 
                        ?
                        setText("Ver")
                        :
                        setText("")
                    } */}
                </div>
        }
    ];



    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5">
                <h1 className="text-4xl font-bold">
                    Talentos
                </h1>
            </div>
            <section className="table-results">
                <label htmlFor="type" className="form-label">
                    Estado del perfil
                </label>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/4 flex">
                        <select
                            id="estadoFreelancer"
                            className="form-select"
                            onChange={listarPorEstado}
                        >
                            <option value="1">Perfiles en revisión</option>
                            <option value="2">Perfiles incompletos</option>
                            <option value="3">Perfiles Verificado</option>
                            <option value="4">Perfiles rechazados</option>
                        </select>
                    </div>
                </div>
                <br />
                <div className="table-auto">
                    <DataTableExtensions
                        columns={columns}
                        data={data}
                        filterPlaceholder="Buscar..."
                        print={false}
                        export={false}
                    >
                        <DataTable
                            pagination
                            columns={columns}
                            data={data}
                            dense
                            striped
                            responsive
                            paginationComponentOptions={paginationProps}
                        />
                    </DataTableExtensions>
                </div>
            </section>
        </div>
    );
};

export default ListFreelancers;

