/* 
    En este archivo se exportan todas las funciones que se van a utilizar para consumir los servicios de la API de la actividad económica.
*/

import axios from "axios";
import endPoints from "./index.js";
import Cookies from 'js-cookie';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
    }
}

if (Cookies.get('Bearer') !== undefined) {
    config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
}

const addEconomicActivity = async (economicActivity) => {
    const response = await axios.post(endPoints.economicActivities.createEconomicActivity, economicActivity,config);
    return response;
};

const deleteEconomicActivity = async (id) => {
    const response = await axios.delete(endPoints.economicActivities.deleteEconomicActivity(id),config);
    return response;
};

const getEconomicActivities = async () => {
    const response = await axios.get(endPoints.economicActivities.getEconomicActivities,config);
    return response.data;
};

const getEconomicActivity = async (id) => {
    const response = await axios.get(endPoints.economicActivities.getEconomicActivity(id),config);
    return response;
};

const updateEconomicActivity = async (id, economicActivity) => {
    const response = await axios.put(endPoints.economicActivities.updateEconomicActivity(id), economicActivity,config);
    return response;
};

export { addEconomicActivity, deleteEconomicActivity, getEconomicActivities, getEconomicActivity, updateEconomicActivity };