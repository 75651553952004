import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { cancelPayment } from "../../services/api/payment";
import Swal from 'sweetalert2';
import axios from "axios";
import Cookies from 'js-cookie';

export default function PaymentList({ payment }) {

  // console.log("AAAQUIII",payment);

  const markDone = () => {
    // cancelPayment(payment.id);
    const getSesion = Cookies.get('Bearer');
    const urlPayment = `${process.env.REACT_APP_API_URI}admin-payments/mark-as-done/${payment.id}`
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${getSesion.token}`
        }
    }
    const response = axios.post(urlPayment,{},config);
    Swal.fire({
      title: 'Aceptado exitosamente',
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonText: 'OK'
  }).then(function () {
      window.location.href = "/payments";
  });
  }

  return (
    <>
      <h1 className="my-5 text-center font-bold text-4xl">Detalles del pago</h1>
      <div className="flex items-center justify-center">
        <div className="relative w-full max-w-4xl border rounded-lg shadow-md bg-theme">
          <Link
            to="/payments"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white focus:ring-4 focus:outline-none rounded-lg absolute top-3 left-3"
            style={{ background: "rgb(147,67,156)" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <div className="flex flex-wrap items-center pb-10 px-4 pt-8">
            <div className="w-full">
              <h2 className="my-6 sm:my-10 text-center font-bold text-2xl sm:text-3xl text-white">
                Información de la empresa
              </h2>
              <div className="flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">
                    Nombre de Empresa
                  </span>
                  <br />
                  {payment.company_name}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">Teléfono</span>
                  <br />
                  {payment.company_phone}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">
                    Correo Electrónico
                  </span>
                  <br />
                  {payment.email}
                </div>
              </div>
              <h2 className="my-6 sm:my-10 text-center font-bold text-2xl sm:text-3xl text-white">
                Información del freelancer
              </h2>
              <div className="flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">Nombres</span>
                  <br />
                  {payment.names}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">Apellidos</span>
                  <br />
                  {payment.lastnames}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">Teléfono</span>
                  <br />
                  {payment.freelancer_phone}
                </div>
              </div>
              <h2 className="my-6 sm:my-10 text-center font-bold text-2xl sm:text-3xl text-white">
                Información de facturación
              </h2>
              <div className="flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">
                    Registro de IVA pago
                  </span>
                  <br />
                  {payment.freelancer_iva_register}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                  <span className="font-bold text-white">Pago pendiente</span>
                  <br />
                  {"$ " + payment.pago}
                </div>
              </div>
              <div className="flex mt-4 space-x-3 md:mt-6 justify-center">
                <button
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-excel hover:bg-dark-excel focus:ring-4 focus:outline-none rounded-lg"
                  onClick={markDone}
                >
                  Marcar como pagado
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
