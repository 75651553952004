import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faPenToSquare, faFolderPlus, faX, faTrash, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const initialStateknowledge = {
   category_id: 0,
   id: 0,
   name: "",
   nivel_conocimiento: 0,
   specialization_category: "",
   id_registro: 0
}

const initialStateListSpecialization = {
   created_at: "",
   id: 0,
   name: "",
   updated_at: ""
}


const Specialization = ({idFreelancer}) =>{

   const idFreelancerI = idFreelancer;

   const [specializations, setSpecializations] = useState([initialStateListSpecialization]);
   const [knowledge, setknowledge] = useState([initialStateknowledge]);

  
   
   let ListSpecializationsFreelancer = [{
      name: "",
      list: [initialStateknowledge]
   }];

   specializations.map((specialization) => {

      if (knowledge[0].name != "") {

         const listNew = knowledge.filter((knowledgeFree) => knowledgeFree.category_id === specialization.id);

         let data = {
            name: specialization.name,
            list: listNew
         };

         ListSpecializationsFreelancer.push(data);

      };

   });

   const dataOfknowledgeFreelancer = ListSpecializationsFreelancer.filter((item) => item.name != "");
   console.log("dataOfknowledgeFreelancer==>", dataOfknowledgeFreelancer);

   useEffect(() => {
      async function getSpecialties() {

         const urlCountry = `${process.env.REACT_APP_API_URI}Knowledge-specialization/list/types`;
   
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         };
         try {
   
            const resp = await axios.get(urlCountry, config);
            setSpecializations(resp.data.specializationTypes);
   
            // setIsLoading(false);
   
         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      getSpecialties();

      async function fetchData() {

         let urlCountry = `${process.env.REACT_APP_API_URI}freelancer/list/knowledge-specializations/${idFreelancerI}`;
   
   
         let config = {
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json'
            }
         };
   
         try {
   
            const resp = await axios.get(urlCountry, config);
            // console.log("resp ==>", resp);
            const specializationFreelancer = resp.data.data;
   
            setknowledge(specializationFreelancer);
   
   
         } catch (err) {
            // Handle Error Here
            console.error(err);
         }
      };
      fetchData();

   }, [])
   


   return (
      <>
         <div className={`text-black w-full p-3`} id="contenedorEspecializacionProfesional" role="tabpanel" aria-labelledby="profile-tab">
         <>
                  {(dataOfknowledgeFreelancer.length !== 0 ?
                        dataOfknowledgeFreelancer.filter(data => data.list.length != 0).map((item, key) => {
                           return (
                              <div key={key} className="border border-Light-Blue p-5 mb-5 rounded-lg">
                                 <h2 className="text-start">{item.name}</h2>
                                 <div className="flex flex-wrap">
                                    {
                                       item.list.map((knowledgeFreelancer, key) => {
                                          return (
                                             <div key={key} className="mt-4 w-full sm:w-1/2 p-6 relative">
                                                <div className="text-start float-left">{knowledgeFreelancer.name}</div>
                                                <div className="text-end">{knowledgeFreelancer.nivel_conocimiento}%</div>
                                                <div className="shadow w-full bg-white mt-2 rounded-lg">
                                                <div className="bg-lightblue text-xs py-1 text-center text-white rounded-lg" style={{ width: `${knowledgeFreelancer.nivel_conocimiento}%`, lineHeight: "0" }}></div>
                                                </div>
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                              </div>
                           )}) : (
                              <p className="text-center mt-9">No se encontraron especializaciones</p>
                           )
                  )}
               </>
         </div>
      </>
   )
}

export default Specialization;