import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale,
} from "chart.js";
import { Pie, Bar, PolarArea } from "react-chartjs-2";
import axios from "axios";
import Cookies from "js-cookie";
import { redirect, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faBuildingCircleCheck, faBuildingCircleExclamation, faMedal, faUser, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { Badge, BadgeDelta, BarChart, Card, DonutChart, Flex, List, ListItem, Metric, ProgressBar, Subtitle, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text } from "@tremor/react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { paginationProps } from '../../utils/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale
);

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const Dashboard = () => {

  const initialStateDashboard = {
    SubscriptionCount: 0,
    activeCompanieProfiles: 0,
    activeFreelancersProfles: 0,
    completed_projects: [],
    incompleteCompanieProfiles: 0,
    incompleteFreelancerProfiles: 0,
    lastPublishProjects: [],
    last_freelancers: [],
    rejected_projects: [],
    top_knowledge: [],
    total_company: 0

 }

  const [infoDashboard, setInfoDashboard] = useState(initialStateDashboard);

  const [loaded, setLoaded] = useState(false);

  const getSesion = Cookies.get('Bearer');

  const navigate = useNavigate();

  useEffect(() => {


    async function getInformacionDashboard() {

      const urlInfoDashboard = `${process.env.REACT_APP_API_URI}dashboard`;

      let config = {
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`
         }
      };

      try {

         const resp = await axios.get(urlInfoDashboard, config);

         setInfoDashboard(resp.data)

         setLoaded(true)

         console.log(resp.data)

      } catch (err) {
         console.error(err);
         if (err.response.status == 401) {
          navigate("/");
         }
      }
   };

   getInformacionDashboard();

  }, []);

  const dataLastFreelancer = {
    labels: loaded && infoDashboard.last_freelancers.map(
      (elementos1) =>
        (elementos1.month === 1 && "Enero " + elementos1.year) ||
        (elementos1.month === 2 && "Febrero " + elementos1.year) ||
        (elementos1.month === 3 && "Marzo " + elementos1.year) ||
        (elementos1.month === 4 && "Abril " + elementos1.year) ||
        (elementos1.month === 5 && "Mayo " + elementos1.year) ||
        (elementos1.month === 6 && "Junio " + elementos1.year) ||
        (elementos1.month === 7 && "Julio " + elementos1.year) ||
        (elementos1.month === 8 && "Agosto " + elementos1.year) ||
        (elementos1.month === 9 && "Septiembre " + elementos1.year) ||
        (elementos1.month === 10 && "Octubre " + elementos1.year) ||
        (elementos1.month === 11 && "Noviembre " + elementos1.year) ||
        (elementos1.month === 12 && "Diciembre " + elementos1.year)
    ),
    datasets: [
      {
        data: loaded && infoDashboard.last_freelancers.map((elementos) => elementos.quantity),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataTopSpecialization = {
    labels:
      loaded &&
      infoDashboard.top_knowledge.map((elementos) => elementos.specialization),
    datasets: [
      {
        data:
          loaded &&
          infoDashboard.top_knowledge.map((elementos) => elementos.quantity),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataGraficoProyectos = {
    labels: ["Completados", "Rechazados"],
    datasets: [
      {
        data: [
          loaded && infoDashboard.completed_projects.length,
          loaded && infoDashboard.rejected_projects.length,
        ],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };


  const chartdata = [];
  const categories = [];

  loaded && infoDashboard.top_knowledge.map(function(elemento) {
        let nombreEspecializacion = (elemento.specialization).toString();
        let numeroEspecializacion = elemento.quantity;

        chartdata.push(
          {
            name: nombreEspecializacion,
            "Especializacion": numeroEspecializacion,
          }
        )

        function compararObjetos(a, b) {
          // Primero, comparamos por la Especializacion
          if (a.Especializacion < b.Especializacion) {
            return 1; // Si a.Especializacion es menor, a debe ir después de b
          } else if (a.Especializacion > b.Especializacion) {
            return -1; // Si a.Especializacion es mayor, a debe ir antes de b
          }
        }

        chartdata.sort(compararObjetos)

        categories.push(
            nombreEspecializacion
        )
    })

    const proyects = [];

    if(loaded && infoDashboard.completed_projects.length > 0 || loaded && infoDashboard.rejected_projects.length > 0 || loaded && infoDashboard.projectsWithoutRevision > 0){
      proyects.push(
        {
          name: "Completados",
          proyectos: loaded && infoDashboard.completed_projects.length,
        },
        {
          name: "Pendientes de revision",
          proyectos: loaded && infoDashboard.projectsWithoutRevision,
        },
        {
          name: "Rechazados",
          proyectos: loaded && infoDashboard.rejected_projects,
        },
      )
    }

    const ultimosFreelance = [];

    loaded && infoDashboard.last_freelancers.map((graficLastFreelance) => {
      if(graficLastFreelance.month === 1){
        ultimosFreelance.push({name: "Enero " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 2){
        ultimosFreelance.push({name: "Febrero " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 3){
        ultimosFreelance.push({name: "Marzo " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 4){
        ultimosFreelance.push({name: "Abril " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 5){
        ultimosFreelance.push({name: "Mayo " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 6){
        ultimosFreelance.push({name: "Junio " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 7){
        ultimosFreelance.push({name: "Julio " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 8){
        ultimosFreelance.push({name: "Agosto " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 9){
        ultimosFreelance.push({name: "Septiembre " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 10){
        ultimosFreelance.push({name: "Octubre " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 11){
        ultimosFreelance.push({name: "Noviembre " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }else if(graficLastFreelance.month === 12){
        ultimosFreelance.push({name: "Diciembre " + graficLastFreelance.year, cantidad: graficLastFreelance.quantity})
      }
  })

  const columns = [
    {
        name: 'Nombre del proyecto',
        width: "450px",
        cell: (row) => <div className="flex justify-start">
            {row.project_name}
        </div>
    },
    {
        name: 'Descripcion',
        width: "450px",
        cell: (row) => <div className="group">
            <span>{loaded && `${(row.description).slice(0, 60)}`}{row.description.length > 60 ? "..." : ""}</span>
        </div>
    },
    {
        name: 'Estado',
        width: "150px",
        cell: (row) => <span>{loaded && row.assigned_status == 1 ? <>Asignado</> : row.assigned_status == 2 ? <>Por asignar</> : row.assigned_status == 3 && <>Finalizado</>}</span>
    },
    // {
    //     name: text,
    //     cell: (row) => 
    //         <div>
    //             {/* {
    //                 loaded && row.complete_profile_status === 1 
    //                 &&
    //                 <Link to={"/VerFreelancer/" + row.user_id} className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
    //                     <FontAwesomeIcon icon="fa-solid fa-eye" />
    //                 </Link>
    //             }{
    //                 loaded && row.complete_profile_status === 1 
    //                 ?
    //                 setText("Ver")
    //                 :
    //                 setText("")
    //             } */}
    //         </div>
    // }
];
  
  
  // const valueFormatter = (number) => `$ ${Intl.NumberFormat("us").format(number).toString()}`;

  return (
    <>
    <div className="flex flex-wrap flex-row">
						<div className="flex-shrink max-w-full px-4 w-full text-center">   
					    <h1 className="text-center text-2xl my-10 font-bold">Dashboard</h1>
					  </div>
						<div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Empresas activas
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
                    <FontAwesomeIcon icon={faBuildingCircleCheck} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.activeCompanieProfiles}</h2>
							  </div>
							</div>
						</div>
						<div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Empresas incompletas
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
                    <FontAwesomeIcon icon={faBuildingCircleExclamation} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.incompleteCompanieProfiles}</h2>
							  </div>
							</div>
						</div>
						<div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Freelancers activos
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
                    <FontAwesomeIcon icon={faUser} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.activeFreelancersProfles}</h2>
							  </div>
							</div>
						</div>
						<div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Freelancers incompletos <span className="ltr:float-right rtl:float-left w-2 h-2 rounded-full bg-green-500 mt-1 animate-pulse"></span>
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
                    <FontAwesomeIcon icon={faUserSlash} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.incompleteFreelancerProfiles}</h2>
							  </div>
							</div>
						</div>   
            <div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Suscripciones
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
								  	<FontAwesomeIcon icon={faMedal} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.SubscriptionCount}</h2>
							  </div>
							</div>
						</div>                                                         
						<div className="flex-shrink max-w-full px-4 w-full sm:w-1/2 lg:w-1/3 mb-6">
          		<div className="bg-white rounded-lg shadow-lg h-full">
							  <div className="pt-6 px-6 relative text-sm font-semibold">
							  	Total de empresas
							  </div>
							  <div className="flex flex-row justify-between px-6 py-4">
							  	<div className="self-center w-14 h-14 rounded-full text-blue-500 bg-blue-100 relative text-center">
                    <FontAwesomeIcon icon={faBuilding} className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-8 h-8 bi bi-cart3" />
									</div>
							    <h2 className="self-center text-3xl">{loaded && infoDashboard.total_company}</h2>
							  </div>
							</div>
						</div>  
            <div className="w-full p-4">
              <Card>
                <Text>Top de especializaciones</Text>
                <BarChart
                  className="mt-6"
                  data={chartdata}
                  index="name"
                  colors={["blue"]}
                  categories={["Especializacion"]}
                  // valueFormatter={dataFormatter}
                  yAxisWidth={48}
                  allowDecimals={false}
                  noDataText="No hay datos por mostrar"
                  showLegend={false}
                  showXAxis={false}
                />
            </Card>  
            </div>  
            <div className="w-full md:w-1/2 p-4">
              <Card className="h-full">
                <Text>Proyectos</Text>
                <DonutChart
                  className="mt-6"
                  data={proyects}
                  category="proyectos"
                  index="name"
                  variant="pie"
                  showLabel="false"
                  noDataText="No hay datos por mostrar"
                  // valueFormatter={valueFormatter}
                  colors={["blue", "red"]}
                />
                <List className="mt-6">
                {proyects.map((proyects) => (
                  <ListItem key={proyects.name}>
                    {proyects.name}
                    <div size="xs">
                      {loaded && proyects.proyectos == 0 ? <>0</> : <>{(proyects.proyectos).toString()}</>}
                    </div>
                  </ListItem>
                ))}
              </List>
              </Card>
            </div>  
            <div className="w-full md:w-1/2 p-4">
              <Card className="h-full mx-auto">
                <Flex className="space-x-8" justifyContent="center" alignItems="center">
                  <Text>Freelancers registrados en los ultimos 3 meses</Text>
                </Flex>
                <DonutChart
                  data={ultimosFreelance}
                  category="cantidad"
                  index="name"
                  className="mt-6"
                  noDataText="No hay datos por mostrar"
                  showLabel="false"
                />
                <List className="mt-6">
                  {
                    loaded && infoDashboard.last_freelancers.map(
                      (elementos1) =>
                        <ListItem>
                          {
                            (elementos1.month === 1 && "Enero " + elementos1.year) ||
                            (elementos1.month === 2 && "Febrero " + elementos1.year) ||
                            (elementos1.month === 3 && "Marzo " + elementos1.year) ||
                            (elementos1.month === 4 && "Abril " + elementos1.year) ||
                            (elementos1.month === 5 && "Mayo " + elementos1.year) ||
                            (elementos1.month === 6 && "Junio " + elementos1.year) ||
                            (elementos1.month === 7 && "Julio " + elementos1.year) ||
                            (elementos1.month === 8 && "Agosto " + elementos1.year) ||
                            (elementos1.month === 9 && "Septiembre " + elementos1.year) ||
                            (elementos1.month === 10 && "Octubre " + elementos1.year) ||
                            (elementos1.month === 11 && "Noviembre " + elementos1.year) ||
                            (elementos1.month === 12 && "Diciembre " + elementos1.year)
                          }
                          <div size="xs">
                            {elementos1.quantity == 0 ? <h1>0</h1> : <h1>{elementos1.quantity}</h1>}
                          </div>
                        </ListItem>
                    )
                  }
                  {/* {ultimosFreelance.map((city) => (
                    <ListItem key={city.name}>
                      {city.name}
                    </ListItem>
                  ))} */}
                </List>
              </Card>
              </div>
              <div className="w-full p-4">
              <Card>
              <Text>Últimos proyectos publicados</Text>
              <DataTableExtensions
                  columns={columns}
                  data={infoDashboard.lastPublishProjects}
                  filterPlaceholder="Buscar..."
                  print={false}
                  export={false}
              >
                  <DataTable
                      pagination
                      columns={columns}
                      data={infoDashboard.lastPublishProjects}
                      dense
                      striped
                      responsive
                      paginationComponentOptions={paginationProps}
                  />
              </DataTableExtensions>
              </Card>
              </div>                                                      
					</div>
    </>
  );
};

export default Dashboard;
