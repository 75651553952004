import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useState, useEffect } from "react";
import { getStates } from "../../services/api/state";
import { useNavigate } from "react-router-dom";
import { updateCity } from "../../services/api/cities";
import Swal from "sweetalert2";
import BackIcon from "../BackIcon";

export default function FormCity({ city }) {
  const [estados, setEstados] = useState(null);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }, reset, getValues, } = useForm();

  const actualizarCity = (id, data) => {
    updateCity(id, data)
      .then((response) => {
        if (response.status === 200 && response.data.message === "Ciudad actualizada con éxito") {
          Swal.fire({
            title: "Actualizado exitoso",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
          navigate(-1);
        } else {
          Swal.fire({
            title: `${response.data.message}`,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: `${error.response.data.message}`,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      });
  };

  const sendData = () => {
    const data = getValues();
    actualizarCity(city.id, data);
  };

  useEffect(() => {
    getStates().then((datos) => {
      setEstados(datos);
    });

    if (city) {
      const initialCity = {
        name: city.name,
        state_id: city.state.id,
      };
      reset(initialCity);
    }
  }, [city, reset]);

  return (
    <>
      {estados && (
        <div id="Forms">
          <div className="pt-5 mb-6">
            <div>
              <BackIcon />
            </div>
            <div className="sm:mt-0 ml-4">
              <h3 className="text-center text-3xl font-bold text-dark">Cuidad</h3>
            </div>
          </div>
          <section>
            <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
              <form id="basic-form" onSubmit={handleSubmit(sendData)}>
                <div className="w-full mb-4">
                  <h2 className="text-base font-normal">
                    Los campos con&nbsp;
                    <span className="text-error font-bold">(*)</span>&nbsp;son
                    requeridos.
                  </h2>
                </div>
                <div className="flex flex-wrap -m-2">
                  <div className="p-2 xl:w-1/2 w-full">
                    <div className="relative mb-2">
                      <label htmlFor="name" className="form-label">
                        Nombre&nbsp;<span className="text-error">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-input"
                        autoComplete="nope"
                        {...register("name", {
                          required: "El nombre es requeridos",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ message }) => (
                          <span className="error-message">{message}</span>
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-2 xl:w-1/2 w-full">
                    <div className="relative mb-2">
                      <label htmlFor="state_id" className="form-label">
                        Departamento&nbsp;<span className="text-error">*</span>
                      </label>
                      <select
                        id="state_id"
                        className="form-select"
                        {...register("state_id", {
                          required: "Seleccione una opcion",
                          validate: (value) => {
                            if (value === "null") {
                              return "Seleccione una opcion";
                            }
                          },
                        })}
                      >
                        {estados?.map((estado) => (
                          <option key={estado.id} value={estado.id}>
                            {estado.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="state_id"
                        render={({ message }) => (
                          <span className="error-message">{message}</span>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full pt-8 flex justify-end">
                  <button
                    type="submit"
                    className="btn bg-lightblue hover:bg-dark-lightblue"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
