import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { paginationProps } from '../../utils/utils';

const Usuarios = () => {

    const[data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');
        

    const obtenerUsuarios = async () =>{
        const URL = `${process.env.REACT_APP_API_URI}user`;
        const result = await axios.get(URL);
        console.log(result.data);
        let sizeData = result.data.length;
        changeDatatableMessages(sizeData);
        setData(result.data);
        setSearch(result.data);
    }

    useEffect(()=>{
        obtenerUsuarios();
    },[]);

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.names.toString().toLowerCase().includes(termSearch.toLowerCase()) || 
            item.lastnames.toString().toLowerCase().includes(termSearch.toLowerCase()) || 
            item.email.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }
    
        const deleteItem = (id) => {
            Swal.fire({
                title: 'Borrando Usuario',
                text: "¿Esta seguro de querer borrar este usuario?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, estoy seguro',
                cancelButtonText: 'Cancelar'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    deletUser(id);
                    Swal.fire({
                        title: 'Borrado exitoso',
                        icon: 'success',
                        allowOutsideClick: false,
                        confirmButtonText: 'OK'
                    })
                    .then((res)=>{
                        if (res.isConfirmed) {
                            window.location='/usuarios';
                        }
                    });
                }
            });
        };

        const deletUser = (id) =>{
            console.log(id);
            const URL = `${process.env.REACT_APP_API_URI}user/${id}`;
            console.log(URL);
            const response = axios.delete(URL);
            
            response.then((res)=>{
                console.log(res.data);
            })
            response.catch((error)=>{
                console.log("ERROR RESPONSE ", error.response.data);
                console.log("ERROR ", error.message);
                console.log("ERROR REQUEST ", error.request);
            })
        } 
    
        const columns = [
            {
                name: '#',
                width: "55px",
                cell: (row) => <b>{ row.id }</b>
            },
            {
                name: 'Nombres',
                width: "200px",
                cell: (row) => <div className="group">
                    <span>{ `${(row.names)}` }</span>
                    <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                        {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.name }</span> */}
                    </div>
                </div>
            },
            {
                name: 'Apellidos',
                width: "200px",
                cell: (row) => <div className="group">
                    <span>{ `${(row.lastnames)}` }</span>
                    <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                        {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{ row.lastname }</span> */}
                    </div>
                </div>
            },
            {
                name: 'Email',
                width: "280px",
                cell: (row) => <span>{ row.email }</span>
            },
            {
                name: 'Estado',
                width: "120px",
                cell: (row) => <span>{ row.status == 1 ? 'Activo' : 'Inactivo' }</span>
            },
            {
                name: 'Tipo',
                width: "150px",
                cell: (row) => <span>{ row.type_id == 1 ? 'Admin' : 'Editor' }</span>
            },
            {
                name: 'Username',
                width: "200px",
                cell: (row) => <span>{ row.username }</span>
            },
            {
                name: 'Ver / Editar',
                width: '120px',
                cell: (row) => <div>
                     <Link to={'/usuario/'+row.username}>
                        <button className="bg-lightblue bg-body px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-dark-lightblue" >
                            <FontAwesomeIcon icon="fa-solid fa-pen" />
                        </button>
                    </Link>
                </div>
            },
            {
                name: 'Borrar',
                cell: (row) => <div>
                    <button className="bg-error px-4 py-3 rounded-md text-base text-white transition-colors hover:bg-red-700" 
                        onClick={ () => deleteItem(row.id) } 
                    >
                        <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </button>
                </div>
            }
        ];

        
       

    
        return (
            <div id="AttentionSchedules">
                <div className="text-center pt-5 flex justify-center items-center">
                    <h1 className="text-3xl sm:text-4xl font-bold mx-4">
                        Usuarios
                    </h1>
                    <Link to={'/ingresar-usuario'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </button>
                    </Link>
                </div>
                <section className="table-results">
                    <div className="table-auto">
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        {/* <DataTableExtensions
                            columns={ columns }
                            data={ data }
                            filterPlaceholder="Buscar..."
                            print={ false }
                            export={ false }
                        > */}
                            <DataTable
                                pagination
                                columns={ columns }
                                data={ search }
                                dense
                                striped
                                responsive
                                paginationComponentOptions={ paginationProps }
                                noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                            />
                        {/* </DataTableExtensions> */}
                    </div>
                </section>
            </div>
        );
}

export default Usuarios;