import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Buttons = () => {
    return (
        <div id="Forms">
            <div className="pt-5 flex items-center justify-center mb-6">
                <div className="text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-3xl font-bold text-dark">Botones</h3>
                </div>
            </div>
            <section>
                <div className="bg-white border border-gray-300 shadow-lg py-6 px-8 rounded-lg">
                    <div className="flex flex-wrap">
                        <div className="w-1/5 pt-8 flex justify-center">
                            <button type="submit" className="btn bg-lightblue hover:bg-dark-lightblue">Submit</button>
                        </div>
                        <div className="w-1/5 pt-8 flex justify-center">
                            <button className="bg-lightblue px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold shadow-md text-base text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                <FontAwesomeIcon className="mr-3" icon="fa-solid fa-plus" />Other page
                            </button>
                        </div>
                        <div className="w-1/5 pt-8 flex justify-center">
                            <button className="bg-white px-4 py-3 rounded-lg text-base text-lightblue border-2 font-bold border-lightblue transition-colors hover:bg-lightblue hover:text-white disabled:bg-opacity-80 disabled:cursor-not-allowed sm:w-auto w-full">
                                <FontAwesomeIcon className="mr-3" icon="fa-solid fa-arrow-left" />Return page
                            </button>
                        </div>
                        <div className="w-1/5 pt-8 flex justify-center">
                            <button className="bg-pdf px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold text-base text-white transition-colors hover:bg-dark-pdf disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo PDF
                            </button>
                        </div>
                        
                        <div className="w-1/5 pt-8 flex justify-center">
                            <button className="bg-excel px-4 py-3 md:mr-4 md:mb-0 mb-3 rounded-lg font-bold text-base text-white transition-colors hover:bg-dark-excel disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                                <FontAwesomeIcon className="mr-3" icon="fa-solid fa-file-pdf" />Generar archivo Excel
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Buttons;
