import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { paginationProps } from '../../utils/utils';

import exportFromJSON from 'export-from-json';

const ReportsFreelancers = () => {

    const { register, handleSubmit, formState: { errors }, reset, clearErrors, getValues } = useForm();

    const [data, setData] = useState([]);

    const[search, setSearch] = useState([]);

    const[nameFile, setNameFile] = useState('ultimo-mes');

    const[messageDatatable, setMessagesDatatable] = useState('Cargando...');


    const getFreelancersMounthLast = async () => {
        let persona = 'freelancer';
        const URL = `${process.env.REACT_APP_API_URI}report/last-months/1?filter=${persona}`;
        const result = await axios.get(URL);
        console.log(result.data.records);
        // let sizeData = result.data.length;
        // changeDatatableMessages(sizeData);
        setId(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    const setId = (arr) =>{
        let ii = 1;
        for (var i = 0; i < arr.length; i++) {
            arr[i].id = ii;
            ii++;
        }
        return arr
    }

    const getFreelancers3MounthLast = async () =>{
        let persona = 'freelancer';
        const URL = `${process.env.REACT_APP_API_URI}report/last-months/3?filter=${persona}`;
        console.log(URL);
        const result = await axios.get(URL);
        console.log(result.data.records);
        setId(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    const getFreelancers6MounthLast = async () =>{
        let persona = 'freelancer';
        const URL = `${process.env.REACT_APP_API_URI}report/last-months/6?filter=${persona}`;
        console.log(URL);
        const result = await axios.get(URL);
        console.log(result.data.records);
        setId(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    const getFreelancersYearLast = async () =>{
        let persona = 'freelancer';
        const URL = `${process.env.REACT_APP_API_URI}report/last-months/12?filter=${persona}`;
        console.log(URL);
        const result = await axios.get(URL);
        console.log(result.data.records);
        setId(result.data.records);
        setSearch(result.data.records);
        setData(result.data.records);
    }

    useEffect(() => {
        getFreelancersMounthLast();
    }, []);

    const changeMounth = (e) =>{
        let id = e.target.value;
        console.log(e.target.value);
        switch (id) {
            case 'mes':
                getFreelancersMounthLast();
                setNameFile('ultimo-mes');
                break;
            case '3meses':
                getFreelancers3MounthLast();
                setNameFile('ultimo-3-mes');
                break;
            case '6meses':
                getFreelancers6MounthLast();
                setNameFile('ultimo-6-mes');
                break;
            case 'year':
                getFreelancersYearLast();
                setNameFile('ultimo-año');
                break;
            default:
                getFreelancersMounthLast();
                break;
        }
    }

    const changeDatatableMessages = (sizeData) =>{
        if(sizeData < 1){
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeSearch = (e) =>{
        console.log(e.target.value);
        filterData(e.target.value);
    }

    const filterData = async (termSearch) =>{
        console.log(termSearch);
        var srch = data.filter((item)=>{
            if(item.names.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.lastnames.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.email.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.city.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
            item.state.toString().toLowerCase().includes(termSearch.toLowerCase())){
                return item;
            }
    
        });
        let sizeData = srch.length;
        changeDatatableMessages(sizeData);
        await setSearch(srch);
    }
    var date = new Date();
    const generateFileExcel = () =>{
        
        // console.log(date);
        if  (Array.isArray(search)){
            let info = [];

            for (let i = 0; i < search.length; i++) {
                info.push({
                    "No": search[i].id,
                    "Nombre": search[i].names,
                    "Apellido": search[i].lastnames,
                    "Email": search[i].email,
                    "Ciudad": search[i].city,
                    "Estado": search[i].state
                });
            }

            console.log(info);

            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_informe-freelancers-${nameFile}`;
            const exportType = exportFromJSON.types.xls;

            exportFromJSON({data: info, fileName: fileName, exportType: exportType});
        }
        else{
            console.log("No es array");
        }
    };

    // const stateProject = (ste) =>{
    //     switch (ste) {
    //         case 1:
    //             return 'Publicado'
    //             break;
    //         case 2:
    //             return 'Por publicar'
    //             break;
    //         case 3:
    //             return 'Bloqueado'
    //             break;
    //         default:
    //             break;
    //     }
    // }

    const columns = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>{row.id}</b>
        },
        {
            name: 'Nombre',
            width: "340px",
            cell: (row) => <div className="group">
                <span>{`${(row.names)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.project_name}</span> */}
                </div>
            </div>
        },
        {
            name: 'Apellido',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.lastnames)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.knowledges_tags}</span> */}
                </div>
            </div>
        },
        {
            name: 'Email',
            width: "200px",
            cell: (row) => <div className="group">
                <span>{`${(row.email)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Ciudad',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.city)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        },
        {
            name: 'Estado',
            width: "150px",
            cell: (row) => <div className="group">
                <span>{`${(row.state)}`}</span>
                <div className="absolute bottom-0 flex-col items-center hidden mb-10 group-hover:flex">
                    {/* <span className="relative z-10 p-4 text-base leading-none text-white text-center whitespace-no-wrap bg-dark shadow-lg">{row.publication_date}</span> */}
                </div>
            </div>
        }
    ];

    return (
        <div id="AttentionSchedules">
            <div className="text-center pt-5 flex justify-center md:justify-start items-center">
                <div className="pt-5 flex justify-center md:justify-start items-center mb-6">
                    <Link to={'/reportes/general'} className="">
                        <button className="bg-theme px-3 py-2 md:mr-4 md:mb-0 rounded-lg font-bold shadow-md text-xs text-white transition-colors hover:bg-dark-lightblue disabled:bg-opacity-80 disabled:cursor-not-allowed md:w-auto w-full">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                        </button>
                    </Link>
                    <div className="text-center px-2 sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-3xl font-bold text-dark">Reportes freelancers</h3>
                    </div>
                </div>
            </div>
            <section className="table-results">
                <div className="table-auto">
                    <div className='flex flex-wrap items-end'>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="search" className="form-label">Búsqueda</label>
                                <input type="text" 
                                    id="search" 
                                    className="form-input py-1" 
                                    autoComplete="none"
                                    placeholder='Buscar...'
                                    onChange={changeSearch}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <div className="relative mb-2">
                                <label htmlFor="type" className="form-label">Especialización</label>
                                <select
                                    id="type"
                                    className="form-select py-2.5"
                                    defaultValue={"null"}
                                    {...register("type", {
                                        required: "Seleccione una opcion",
                                        validate: (value) => {
                                            if (value === "null") {
                                                return "Seleccione una opcion";
                                            }
                                        },
                                    })}
                                    onChange={changeMounth}
                                >
                                    <option selected value="mes">Último mes</option>
                                    <option value="3meses">Último 3 meses</option>
                                    <option value="6meses">Último 6 meses</option>
                                    <option value="year">Último año</option>
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="type"
                                    render={({ message }) => <span className="error-message">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full lg:w-1/3">
                            <button onClick={generateFileExcel} className="flex items-center text-white bg-green-800 border-0 h-12 py-2 my-2 px-8 focus:outline-none hover:bg-green-700 rounded text-lg">
                                <FontAwesomeIcon icon="fa-solid fa-file-export" className='mr-2' />
                                <span className="text-xs text-white">Exportar Excel</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        pagination
                        columns={columns}
                        data={search}
                        dense
                        striped
                        responsive
                        paginationComponentOptions={paginationProps}
                        noDataComponent={<span className='p-4'>{messageDatatable}</span>}
                    />
                </div>
            </section>
        </div>
    );
}

export default ReportsFreelancers;