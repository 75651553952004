import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPayment } from "../../services/api/payment";
import PaymentList from "../../components/Listas/PaymentList";

export default function Payment() {
  const { id } = useParams();
  const [payment, setPayment] = useState();
  const navigate = useNavigate();
  // Use the useEffect hook to fetch the country data when the component is first rendered
  useEffect(() => {
    getPayment(id)
      // If the request is successful, update the country state variable with the response data
      .then((response) => {
        setPayment(response.data);
      })
      // If an error occurs or if the country with the given id is not found, navigate to the "not-found" page
      .catch(() => {
        navigate("/not-found");
      });
  }, [id, navigate]);

  return (
    <>
      {payment && (<PaymentList payment={payment} />)}
    </>
  );
}
