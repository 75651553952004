import { Outlet } from 'react-router-dom';

const Reports = () => {

    return (
        <div id="AttentionSchedules">
            <Outlet/>
        </div>
    );
}

export default Reports;